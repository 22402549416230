@charset "UTF-8";
@import url(base.css);
@import url(common.css);
@import url(blog.css);
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
/*
トップページ
-------------------------------------*/
#Page.pageIndex #MainImg {
  overflow: visible;
  border-bottom: 1px solid #ff65a6; }
  #Page.pageIndex #MainImg .innerBasic {
    z-index: 2; }
  #Page.pageIndex #MainImg video {
    position: relative;
    z-index: 0;
    width: 100%;
    height: auto; }
  #Page.pageIndex #MainImg .titleTypeBasic {
    text-shadow: none; }
    #Page.pageIndex #MainImg .titleTypeBasic::before {
      display: none; }
    #Page.pageIndex #MainImg .titleTypeBasic::after {
      color: rgba(255, 255, 255, 0.7); }
    #Page.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon {
      margin: 0 auto;
      font-weight: 400; }
      #Page.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon span {
        text-align: center; }
    #Page.pageIndex #MainImg .titleTypeBasic em {
      display: block;
      text-shadow: 10px 0 15px white, 0 10px 15px white, -10px 0 15px white, 0 -10px 15px white, 10px 0 15px white, 0 10px 15px white, -10px 0 15px white, 0 -10px 15px white;
      font-weight: 700; }
      #Page.pageIndex #MainImg .titleTypeBasic em::before, #Page.pageIndex #MainImg .titleTypeBasic em:after {
        content: "";
        position: relative;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center; }
      #Page.pageIndex #MainImg .titleTypeBasic em::before {
        left: auto;
        transform: translate(0, 0);
        background-image: url("../img/contents/top/parts_01_l.png"); }
      #Page.pageIndex #MainImg .titleTypeBasic em::after {
        top: auto;
        left: auto;
        transform: translate(0, 0);
        background-image: url("../img/contents/top/parts_01_r.png"); }
      #Page.pageIndex #MainImg .titleTypeBasic em span {
        letter-spacing: 0; }
    #Page.pageIndex #MainImg .titleTypeBasic b {
      font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
      font-size: 0;
      font-weight: 500;
      color: #fff; }
      #Page.pageIndex #MainImg .titleTypeBasic b span {
        letter-spacing: .1em; }
        #Page.pageIndex #MainImg .titleTypeBasic b span:first-of-type {
          background-color: rgba(251, 192, 45, 0.8); }
        #Page.pageIndex #MainImg .titleTypeBasic b span:nth-of-type(2) {
          background-color: rgba(57, 186, 142, 0.8); }
  #Page.pageIndex #MainImg a {
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url("../img/contents/top/banner_01.png");
    text-align: left;
    text-indent: -9999px; }
#Page.pageIndex #Main .contBox .buttonTypeBasic {
  margin-right: auto;
  margin-left: auto; }
#Page.pageIndex #Main #ContBox02 .titleTypeBasic, #Page.pageIndex #Main #ContBox04 .titleTypeBasic {
  position: relative;
  z-index: 1; }
  #Page.pageIndex #Main #ContBox02 .titleTypeBasic::before, #Page.pageIndex #Main #ContBox04 .titleTypeBasic::before {
    display: none; }
  #Page.pageIndex #Main #ContBox02 .titleTypeBasic::after, #Page.pageIndex #Main #ContBox04 .titleTypeBasic::after {
    content: attr(data-subtitle);
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    text-align: center;
    font-family: 'Dancing Script', cursive;
    font-style: italic;
    font-weight: 400; }
#Page.pageIndex #Main #ContBox01 {
  background-color: #f6f6f6; }
  #Page.pageIndex #Main #ContBox01 .titleTypeBasic {
    text-align: left; }
  #Page.pageIndex #Main #ContBox01 .wrapBox {
    margin-bottom: 0;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: #fff; }
    #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog {
      border-top: none; }
      #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li {
        border: none; }
        #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .title {
          font-size: 16px; }
          #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .title a {
            color: #000; }
            #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .title a::after {
              display: none; }
    #Page.pageIndex #Main #ContBox01 .wrapBox .buttonTypeBasic {
      padding-top: 13px;
      padding-bottom: 0;
      width: 160px;
      height: 42px;
      min-height: auto; }
      #Page.pageIndex #Main #ContBox01 .wrapBox .buttonTypeBasic::after {
        width: 8px;
        height: 12px;
        background-size: 8px auto; }
  #Page.pageIndex #Main #ContBox01 .textBox {
    background-color: #ff65a6;
    font-size: 0;
    color: #fff; }
    #Page.pageIndex #Main #ContBox01 .textBox .titleTypeLabel {
      background-color: #fff;
      line-height: 26px;
      letter-spacing: .15em;
      font-size: 14px;
      color: #ff65a6; }
      #Page.pageIndex #Main #ContBox01 .textBox .titleTypeLabel::before, #Page.pageIndex #Main #ContBox01 .textBox .titleTypeLabel::after {
        right: -9px; }
      #Page.pageIndex #Main #ContBox01 .textBox .titleTypeLabel::before {
        top: auto;
        border-width: 13px 9px 0 0;
        border-color: #fff transparent transparent #fff; }
      #Page.pageIndex #Main #ContBox01 .textBox .titleTypeLabel::after {
        border-width: 0 9px 13px 0;
        border-color: #fff transparent #fff transparent; }
    #Page.pageIndex #Main #ContBox01 .textBox a {
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      color: #fff; }
      #Page.pageIndex #Main #ContBox01 .textBox a::before {
        background-image: url("../img/contents/icon_09_w.png"); }
    #Page.pageIndex #Main #ContBox01 .textBox b {
      vertical-align: baseline; }
#Page.pageIndex #Main #ContBox02 {
  background-repeat: repeat-x, repeat-x;
  background-position: left top, left bottom;
  background-image: url("../img/contents/wall_05_pink.gif"), url("../img/contents/wall_05_pink.gif");
  background-color: #ffd1da; }
  #Page.pageIndex #Main #ContBox02 .titleTypeBasic::after {
    color: #ffe1e7; }
  #Page.pageIndex #Main #ContBox02 .innerBasic {
    background-color: #fff; }
  #Page.pageIndex #Main #ContBox02 .wrapBox {
    position: relative;
    margin: 0 auto; }
    #Page.pageIndex #Main #ContBox02 .wrapBox::before, #Page.pageIndex #Main #ContBox02 .wrapBox::after {
      content: "";
      position: absolute;
      z-index: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: center top;
      /* ↓アニメーション用のスタイル */
      opacity: 0;
      transition-delay: 6s; }
    #Page.pageIndex #Main #ContBox02 .wrapBox::before {
      background-image: url("../img/contents/top/photo_09.png");
      /* ↓アニメーション用のスタイル */
      transform: translateX(-40px); }
    #Page.pageIndex #Main #ContBox02 .wrapBox::after {
      background-image: url("../img/contents/top/photo_10.png");
      /* ↓アニメーション用のスタイル */
      transform: translateX(40px); }
    #Page.pageIndex #Main #ContBox02 .wrapBox > p {
      text-align: center; }
    #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span {
      padding: 0;
      justify-content: center; }
      #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span .titleTypeBasic {
        font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
        font-weight: 500; }
      #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span .titleTypeBasic, #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span p {
        margin-bottom: 0;
        text-align: center;
        padding: 0; }
      #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span p {
        color: #888; }
    #Page.pageIndex #Main #ContBox02 .wrapBox.fadeIn::before, #Page.pageIndex #Main #ContBox02 .wrapBox.fadeIn::after {
      transition: 0.5s ease-in;
      transform: translate(0, 0);
      opacity: 1 !important; }
#Page.pageIndex #Main #ContBox03 .listTypeButton li {
  margin-top: 0;
  border-bottom-style: solid;
  border-bottom-color: #f6f6f6; }
  #Page.pageIndex #Main #ContBox03 .listTypeButton li a::after {
    display: none; }
  #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox {
    position: relative; }
    #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox::after {
      content: "";
      position: absolute;
      top: 50%;
      display: block;
      transform: translateY(-50%);
      width: 10px;
      height: 17px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/contents/arrow_01.png");
      background-size: 10px auto; }
    #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox span {
      padding: 0;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox h4, #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox p {
      padding: 0;
      text-align: center; }
    #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox h4 {
      color: #f54992; }
    #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox p {
      margin-bottom: 0;
      font-size: 14px;
      color: #888; }
  #Page.pageIndex #Main #ContBox03 .listTypeButton li a[href*=youtube] .textBox::after {
    border-radius: 50%;
    border: 1px solid #ff65a6;
    width: 30px;
    height: 30px;
    background-image: url("../img/contents/arrow_07.png");
    background-size: 9px auto; }
#Page.pageIndex #Main #ContBox04 .titleTypeBasic::after {
  color: #f1f1f1; }
#Page.pageIndex #Main #ContBox04 .titleTypeBasic + p {
  text-align: center; }
#Page.pageIndex #Main #ContBox04 ul li {
  width: 312px; }
  #Page.pageIndex #Main #ContBox04 ul li .boxTypeBalloon {
    width: 308px;
    height: 103px;
    background-size: 308px; }
    #Page.pageIndex #Main #ContBox04 ul li .boxTypeBalloon span {
      min-height: 76px;
      line-height: 28px;
      text-align: center;
      letter-spacing: .08em;
      font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
      font-size: 16px;
      font-weight: 500; }
  #Page.pageIndex #Main #ContBox04 ul li .wrapBox {
    position: relative;
    border-radius: 50%;
    overflow: hidden; }
    #Page.pageIndex #Main #ContBox04 ul li .wrapBox .imgBox {
      position: relative;
      z-index: 0;
      width: 100%; }
    #Page.pageIndex #Main #ContBox04 ul li .wrapBox .textBox {
      position: absolute;
      top: 234px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      border-radius: 50%;
      padding-top: 14px;
      width: 402px;
      height: 402px;
      background-repeat: repeat;
      background-position: left top;
      background-image: url("../img/contents/wall_04_pink.gif");
      background-size: 16px auto;
      text-align: center;
      font-size: 20px;
      color: #fff; }
      #Page.pageIndex #Main #ContBox04 ul li .wrapBox .textBox span {
        display: block;
        font-size: 14px; }
      #Page.pageIndex #Main #ContBox04 ul li .wrapBox .textBox.yellow {
        background-image: url("../img/contents/wall_04_yellow.gif"); }
      #Page.pageIndex #Main #ContBox04 ul li .wrapBox .textBox.green {
        background-image: url("../img/contents/wall_04_green.gif"); }
#Page.pageIndex #Main #ContBox04 .buttonTypeBasic {
  margin-top: 62px; }
#Page.pageIndex #Main #ContBox06 {
  background-color: #ffeff6; }
  #Page.pageIndex #Main #ContBox06 .txtTypeCenter:last-of-type {
    margin-bottom: 0; }
  #Page.pageIndex #Main #ContBox06 .listTypeButton li a span {
    min-height: 100px; }
    #Page.pageIndex #Main #ContBox06 .listTypeButton li a span b {
      font-size: 20px; }
      #Page.pageIndex #Main #ContBox06 .listTypeButton li a span b p {
        margin-bottom: 0;
        padding: 0;
        display: block;
        font-size: 14px;
        color: #888; }
#Page.pageIndex #Main #ContBox07 .buttonTypeBasic {
  margin-top: 45px; }
#Page.pageIndex #Main #ContBox07 .listTypeBlog li a dl dd {
  color: #000; }

@media print, screen and (min-width: 768px) {
  #Page.pageIndex #MainImg {
    height: auto; }
    #Page.pageIndex #MainImg .innerBasic {
      top: 62%; }
    #Page.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-50%, -25%) rotate(-10deg); }
    #Page.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon {
      width: 531px;
      height: 81px;
      background-image: url("../img/contents/balloon_05_pink.png");
      background-size: 531px auto; }
      #Page.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon span {
        padding-top: 6px;
        padding-left: 22px;
        min-height: 57px;
        font-size: 22px; }
    #Page.pageIndex #MainImg .titleTypeBasic em {
      letter-spacing: .12em;
      font-size: 44px; }
      #Page.pageIndex #MainImg .titleTypeBasic em::before, #Page.pageIndex #MainImg .titleTypeBasic em:after {
        width: 22px;
        height: 14px; }
      #Page.pageIndex #MainImg .titleTypeBasic em::before {
        top: -33px;
        margin-right: 18px; }
      #Page.pageIndex #MainImg .titleTypeBasic em::after {
        bottom: -14px;
        margin-left: 10px; }
    #Page.pageIndex #MainImg .titleTypeBasic b span {
      display: inline-block;
      padding: 0 15px;
      line-height: 30px;
      letter-spacing: .1em;
      font-size: 16px; }
      #Page.pageIndex #MainImg .titleTypeBasic b span:first-of-type {
        margin-right: 1px; }
    #Page.pageIndex #MainImg a {
      right: 30px;
      bottom: -32px;
      width: 220px;
      height: 225px;
      background-size: 220px auto; }
      #Page.pageIndex #MainImg a:hover {
        opacity: .7 !important;
        transition-delay: 0; }
  #Page.pageIndex #Main .contBox .innerBasic {
    padding-top: 80px;
    padding-bottom: 120px; }
  #Page.pageIndex #Main .contBox .titleTypeBasic {
    font-size: 28px; }
    #Page.pageIndex #Main .contBox .titleTypeBasic::before {
      top: -11px; }
  #Page.pageIndex #Main .contBox .buttonTypeBasic {
    width: 400px; }
  #Page.pageIndex #Main #ContBox02 .titleTypeBasic, #Page.pageIndex #Main #ContBox04 .titleTypeBasic {
    font-size: 36px; }
    #Page.pageIndex #Main #ContBox02 .titleTypeBasic::after, #Page.pageIndex #Main #ContBox04 .titleTypeBasic::after {
      font-size: 180px; }
  #Page.pageIndex #Main #ContBox01 .innerBasic {
    padding-top: 50px;
    padding-bottom: 80px; }
  #Page.pageIndex #Main #ContBox01 .titleTypeBasic {
    margin-bottom: 1px;
    font-size: 24px; }
  #Page.pageIndex #Main #ContBox01 .wrapBox {
    position: relative;
    margin-bottom: 0;
    padding: 23px 27px 10px;
    min-height: 236px;
    background-image: url("../img/contents/top/photo_05_pc.jpg");
    background-size: 275px auto; }
    #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li {
      margin-bottom: 4px; }
      #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .date, #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .title {
        margin-bottom: 0;
        display: inline-block;
        vertical-align: middle; }
      #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .date {
        width: 128px; }
      #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
        list-style: none;
        line-height: 1;
        font-family: helvetica,'arial black',arial,sans-serif;
        padding: 10px 0;
        width: 590px; }
        #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .title a {
          display: inline;
          padding: 0;
          font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
          font-weight: 500; }
          #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .title a:hover {
            text-decoration: underline; }
    #Page.pageIndex #Main #ContBox01 .wrapBox .buttonTypeBasic {
      position: absolute;
      right: 33px;
      bottom: 28px; }
  #Page.pageIndex #Main #ContBox01 .textBox {
    padding: 4px 30px;
    font-size: 0; }
    #Page.pageIndex #Main #ContBox01 .textBox .titleTypeLabel, #Page.pageIndex #Main #ContBox01 .textBox em, #Page.pageIndex #Main #ContBox01 .textBox b {
      display: inline-block; }
    #Page.pageIndex #Main #ContBox01 .textBox .titleTypeLabel {
      position: relative;
      top: -4px; }
    #Page.pageIndex #Main #ContBox01 .textBox a {
      margin-right: 4px;
      margin-left: 25px;
      letter-spacing: .05em;
      font-size: 28px; }
      #Page.pageIndex #Main #ContBox01 .textBox a::before {
        width: 41px;
        height: 23px;
        background-size: 41px auto; }
    #Page.pageIndex #Main #ContBox01 .textBox b {
      letter-spacing: .1em;
      font-size: 14px; }
  #Page.pageIndex #Main #ContBox02 {
    padding-top: 135px;
    padding-right: 30px;
    padding-bottom: 120px;
    padding-left: 30px;
    background-size: 8px auto, 8px 648px; }
    #Page.pageIndex #Main #ContBox02 .titleTypeBasic {
      margin-bottom: 83px; }
      #Page.pageIndex #Main #ContBox02 .titleTypeBasic::after {
        transform: translate(-51%, -69%) rotate(-10deg); }
    #Page.pageIndex #Main #ContBox02 .innerBasic {
      padding-top: 43px;
      padding-bottom: 60px;
      width: auto; }
    #Page.pageIndex #Main #ContBox02 .wrapBox {
      width: 1000px; }
      #Page.pageIndex #Main #ContBox02 .wrapBox::before {
        bottom: -222px;
        left: -116px;
        width: 267px;
        height: 870px;
        background-size: 267px; }
      #Page.pageIndex #Main #ContBox02 .wrapBox::after {
        bottom: -231px;
        right: -174px;
        width: 402px;
        height: 911px;
        background-size: 402px; }
      #Page.pageIndex #Main #ContBox02 .wrapBox > p {
        margin-bottom: 50px;
        line-height: 38px;
        letter-spacing: 0.15em;
        font-size: 20px; }
      #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton {
        position: relative;
        z-index: 2; }
        #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li {
          margin-top: 20px; }
          #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li:nth-of-type(1), #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li:nth-of-type(2) {
            margin-top: 0; }
          #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span {
            min-height: 108px; }
            #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span .titleTypeBasic {
              font-size: 24px;
              letter-spacing: 0.05em; }
            #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span p {
              margin-top: -6px;
              letter-spacing: 0.05em;
              font-size: 16px; }
    #Page.pageIndex #Main #ContBox02 .buttonTypeBasic {
      margin-top: 62px; }
  #Page.pageIndex #Main #ContBox03 {
    padding-top: 96px; }
    #Page.pageIndex #Main #ContBox03 .titleTypeBasic {
      margin-bottom: 29px; }
    #Page.pageIndex #Main #ContBox03 .listTypeButton li {
      border-bottom-width: 10px;
      width: 50%;
      margin-right: 0; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li:nth-of-type(2n+1) {
        border-right: 1px solid #f6f6f6; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li:nth-of-type(2n) {
        border-left: 1px solid #f6f6f6; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox::after {
        right: 22%; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox span {
        min-height: 139px; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox h4 {
        letter-spacing: .1em;
        font-size: 24px; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox p {
        margin-top: 5px;
        line-height: 24px; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li a[href*=youtube] .textBox::after {
        right: 20%; }
  #Page.pageIndex #Main #ContBox04 .innerBasic {
    padding-top: 112px;
    padding-bottom: 88px; }
  #Page.pageIndex #Main #ContBox04 .titleTypeBasic {
    margin-bottom: 83px; }
    #Page.pageIndex #Main #ContBox04 .titleTypeBasic::after {
      transform: translate(-52%, -50%) rotate(-10deg); }
    #Page.pageIndex #Main #ContBox04 .titleTypeBasic + p {
      margin-bottom: 58px;
      line-height: 38px;
      letter-spacing: 0.15em;
      font-size: 20px; }
  #Page.pageIndex #Main #ContBox04 ul::after {
    content: "";
    display: block;
    clear: both; }
  #Page.pageIndex #Main #ContBox04 ul li {
    float: left;
    margin-right: 32px; }
    #Page.pageIndex #Main #ContBox04 ul li:nth-of-type(3n) {
      margin-right: 0; }
    #Page.pageIndex #Main #ContBox04 ul li .boxTypeBalloon {
      margin-bottom: 10px; }
    #Page.pageIndex #Main #ContBox04 ul li:nth-of-type(2) {
      transition-delay: 300ms; }
    #Page.pageIndex #Main #ContBox04 ul li:nth-of-type(3) {
      transition-delay: 600ms; }
  #Page.pageIndex #Main #ContBox04 .buttonTypeBasic {
    margin-top: 60px; }
  #Page.pageIndex #Main #ContBox05 .innerBasic {
    position: relative;
    padding-bottom: 90px; }
    #Page.pageIndex #Main #ContBox05 .innerBasic::before, #Page.pageIndex #Main #ContBox05 .innerBasic::after {
      content: "";
      position: absolute;
      z-index: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: center top;
      /* ↓アニメーション用のスタイル */
      opacity: 0;
      transition-delay: 6s; }
    #Page.pageIndex #Main #ContBox05 .innerBasic::before {
      bottom: 24px;
      left: -91px;
      width: 136px;
      height: 534px;
      background-image: url("../img/contents/top/photo_11.png");
      background-size: 136px;
      /* ↓アニメーション用のスタイル */
      transform: translateX(-40px); }
    #Page.pageIndex #Main #ContBox05 .innerBasic::after {
      bottom: 29px;
      right: -112px;
      width: 272px;
      height: 591px;
      background-image: url("../img/contents/top/photo_08.png");
      background-size: 272px;
      /* ↓アニメーション用のスタイル */
      transform: translateX(40px); }
    #Page.pageIndex #Main #ContBox05 .innerBasic.fadeIn::before, #Page.pageIndex #Main #ContBox05 .innerBasic.fadeIn::after {
      transition: 0.5s ease-in;
      transform: translate(0, 0);
      opacity: 1 !important; }
  #Page.pageIndex #Main #ContBox05 .titleTypeBasic {
    margin-bottom: 44px; }
  #Page.pageIndex #Main #ContBox05 .listTypeButton {
    position: relative;
    z-index: 1;
    padding: 0; }
    #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01 li {
      margin-top: 0;
      margin-right: 20px;
      width: 320px; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01 li:nth-of-type(2n) {
        margin-right: 20px; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01 li:nth-of-type(2n+1) {
        clear: none; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01 li:nth-of-type(3n) {
        margin-right: 0; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01 li:nth-of-type(3n+1) {
        clear: both; }
    #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li {
      margin-top: 0;
      margin-right: 20px;
      width: 235px; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li:nth-of-type(2n) {
        margin-right: 20px; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li:nth-of-type(2n+1) {
        clear: none; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li:nth-of-type(4n) {
        margin-right: 0; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li:nth-of-type(4n+1) {
        clear: both; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li a {
        font-size: 16px; }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li a span {
          padding-left: 60px;
          min-height: 60px; }
          #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li a span b {
            font-size: 14px; }
    #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li {
      margin-top: 0;
      margin-right: 20px;
      width: 235px; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li:nth-of-type(2n) {
        margin-right: 20px; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li:nth-of-type(2n+1) {
        clear: none; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li:nth-of-type(4n) {
        margin-right: 0; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li:nth-of-type(4n+1) {
        clear: both; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li a {
        font-size: 16px; }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li a span {
          padding-left: 20px;
          min-height: 60px; }
  #Page.pageIndex #Main #ContBox06 .innerBasic {
    position: relative;
    padding-bottom: 90px; }
    #Page.pageIndex #Main #ContBox06 .innerBasic::before, #Page.pageIndex #Main #ContBox06 .innerBasic::after {
      content: "";
      position: absolute;
      bottom: 0;
      z-index: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: center top;
      /* ↓アニメーション用のスタイル */
      opacity: 0;
      transition-delay: 6s; }
    #Page.pageIndex #Main #ContBox06 .innerBasic::before {
      left: -175px;
      width: 351px;
      height: 360px;
      background-image: url("../img/contents/top/photo_07.png");
      background-size: 351px;
      /* ↓アニメーション用のスタイル */
      transform: translateX(-40px); }
    #Page.pageIndex #Main #ContBox06 .innerBasic::after {
      right: -146px;
      width: 392px;
      height: 345px;
      background-image: url("../img/contents/top/photo_06.png");
      background-size: 392px;
      /* ↓アニメーション用のスタイル */
      transform: translateX(40px); }
    #Page.pageIndex #Main #ContBox06 .innerBasic.fadeIn::before, #Page.pageIndex #Main #ContBox06 .innerBasic.fadeIn::after {
      transition: 0.5s ease-in;
      transform: translate(0, 0);
      opacity: 1 !important; }
  #Page.pageIndex #Main #ContBox06 .titleTypeBasic {
    margin-bottom: 11px; }
    #Page.pageIndex #Main #ContBox06 .titleTypeBasic + p {
      margin-bottom: 22px; }
  #Page.pageIndex #Main #ContBox06 .txtTypeCenter:last-of-type {
    margin-top: 22px; }
  #Page.pageIndex #Main #ContBox06 .listTypeButton, #Page.pageIndex #Main #ContBox06 .buttonTypeBasic {
    position: relative;
    z-index: 1; }
  #Page.pageIndex #Main #ContBox06 .listTypeButton {
    margin-right: auto;
    margin-left: auto;
    width: 740px; }
    #Page.pageIndex #Main #ContBox06 .listTypeButton li {
      width: 360px; }
      #Page.pageIndex #Main #ContBox06 .listTypeButton li a span b p {
        margin-top: 2px; }
      #Page.pageIndex #Main #ContBox06 .listTypeButton li a::after {
        right: 15px; }
  #Page.pageIndex #Main #ContBox06 .buttonTypeBasic {
    margin-top: 58px; }
  #Page.pageIndex #Main #ContBox07 .titleTypeBasic {
    margin-bottom: 34px; }
  #Page.pageIndex #Main #ContBox07 .listTypeBlog li a {
    padding: 20px 0; }
    #Page.pageIndex #Main #ContBox07 .listTypeBlog li a dl {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-text-overflow: ellipsis;
      list-style: none;
      line-height: 1;
      font-family: helvetica,'arial black',arial,sans-serif;
      font-size: 0; }
      #Page.pageIndex #Main #ContBox07 .listTypeBlog li a dl dt, #Page.pageIndex #Main #ContBox07 .listTypeBlog li a dl dd {
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        display: inline-block;
        vertical-align: middle; }
      #Page.pageIndex #Main #ContBox07 .listTypeBlog li a dl dt {
        padding-left: 15px;
        width: 140px;
        font-size: 14px; }
      #Page.pageIndex #Main #ContBox07 .listTypeBlog li a dl dd {
        width: 859px;
        font-size: 16px;
        font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
        font-weight: 500; }
  #Page.pageIndex #Main #ContBox07 .buttonTypeBasic {
    margin-top: 45px; } }
@media print, screen and (min-width: 768px) and (max-width: 1300px) {
  #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox::after {
    right: 12%; }
  #Page.pageIndex #Main #ContBox03 .listTypeButton li a[href*=youtube] .textBox::after {
    right: 10%; } }
@media print, screen and (max-width: 767px) {
  #Page.pageIndex #MainImg {
    height: auto; }
    #Page.pageIndex #MainImg .innerBasic {
      top: 43%; }
    #Page.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-50%, 47%) rotate(-10deg);
      font-size: 76px; }
    #Page.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon {
      width: 239px;
      height: 87px;
      background-image: url("../img/contents/balloon_06_pink.png");
      background-size: 239px auto; }
      #Page.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon span {
        padding-left: 10px;
        min-height: 71px;
        line-height: 22px;
        font-size: 16px; }
    #Page.pageIndex #MainImg .titleTypeBasic em {
      position: relative;
      display: inline-block;
      margin-bottom: 14px;
      line-height: 40px;
      letter-spacing: .12em;
      font-size: 32px; }
      #Page.pageIndex #MainImg .titleTypeBasic em::before, #Page.pageIndex #MainImg .titleTypeBasic em:after {
        position: absolute;
        width: 16px;
        height: 11px;
        background-size: 16px auto; }
      #Page.pageIndex #MainImg .titleTypeBasic em::before {
        top: -10px;
        left: -14px; }
      #Page.pageIndex #MainImg .titleTypeBasic em::after {
        bottom: -1px;
        right: -14px; }
    #Page.pageIndex #MainImg .titleTypeBasic b {
      display: block;
      margin-right: auto;
      margin-left: auto;
      max-width: 325px; }
      #Page.pageIndex #MainImg .titleTypeBasic b span {
        display: inline-block;
        width: 100%;
        line-height: 28px;
        letter-spacing: .1em;
        font-size: 13px; }
        #Page.pageIndex #MainImg .titleTypeBasic b span:first-of-type {
          margin-bottom: 2px;
          width: 140px; }
    #Page.pageIndex #MainImg a {
      right: 15px;
      bottom: -32px;
      width: 182px;
      height: 182px;
      background-size: 182px auto; }
  #Page.pageIndex #Main .contBox .innerBasic {
    padding-top: 73px;
    padding-bottom: 75px; }
  #Page.pageIndex #Main .contBox .titleTypeBasic {
    line-height: 30px;
    font-size: 22px; }
    #Page.pageIndex #Main .contBox .titleTypeBasic::before {
      top: -36px; }
    #Page.pageIndex #Main .contBox .titleTypeBasic + p {
      text-align: justify; }
  #Page.pageIndex #Main .contBox .buttonTypeBasic {
    max-width: 290px; }
  #Page.pageIndex #Main #ContBox02 .titleTypeBasic, #Page.pageIndex #Main #ContBox04 .titleTypeBasic {
    font-size: 28px; }
    #Page.pageIndex #Main #ContBox02 .titleTypeBasic::after, #Page.pageIndex #Main #ContBox04 .titleTypeBasic::after {
      font-size: 90px; }
  #Page.pageIndex #Main #ContBox01 .innerBasic {
    padding-top: 55px;
    padding-bottom: 60px; }
  #Page.pageIndex #Main #ContBox01 .titleTypeBasic {
    margin-bottom: 10px;
    font-size: 24px; }
  #Page.pageIndex #Main #ContBox01 .wrapBox {
    position: relative;
    margin-bottom: 0;
    padding: 23px 15px 28px;
    background-image: url("../img/contents/top/photo_05.jpg");
    background-size: 100% auto; }
    #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li {
      margin-bottom: 13px; }
      #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .date {
        margin-bottom: 3px; }
      #Page.pageIndex #Main #ContBox01 .wrapBox .listTypeBlog li dl .title a {
        display: inline;
        padding: 0;
        text-decoration: underline; }
    #Page.pageIndex #Main #ContBox01 .wrapBox .buttonTypeBasic {
      margin-top: 188px;
      margin-right: 0;
      margin-left: auto; }
  #Page.pageIndex #Main #ContBox01 .textBox {
    padding: 15px 15px 6px;
    font-size: 0; }
    #Page.pageIndex #Main #ContBox01 .textBox .titleTypeLabel {
      margin-bottom: -2px; }
      #Page.pageIndex #Main #ContBox01 .textBox .titleTypeLabel::before {
        right: -8.5px;
        border-width: 13px 0 13px 9px;
        border-color: transparent transparent transparent #fff; }
      #Page.pageIndex #Main #ContBox01 .textBox .titleTypeLabel::after {
        display: none; }
    #Page.pageIndex #Main #ContBox01 .textBox a, #Page.pageIndex #Main #ContBox01 .textBox b {
      display: inline-block; }
    #Page.pageIndex #Main #ContBox01 .textBox a {
      margin-right: 1px;
      letter-spacing: .05em;
      font-size: 22px; }
      #Page.pageIndex #Main #ContBox01 .textBox a::before {
        width: 35px;
        height: 20px;
        background-size: 35px auto; }
    #Page.pageIndex #Main #ContBox01 .textBox b {
      letter-spacing: .1em;
      font-size: 12px; }
  #Page.pageIndex #Main #ContBox02 {
    padding-top: 100px;
    padding-bottom: 67px;
    background-size: 8px auto, 8px 67px; }
    #Page.pageIndex #Main #ContBox02 .titleTypeBasic {
      margin-bottom: 57px; }
      #Page.pageIndex #Main #ContBox02 .titleTypeBasic::after {
        transform: translate(-50%, -81%) rotate(-10deg); }
    #Page.pageIndex #Main #ContBox02 .innerBasic {
      padding-top: 40px;
      padding-bottom: 45px;
      width: auto; }
    #Page.pageIndex #Main #ContBox02 .wrapBox::before {
      top: -78px;
      left: -2px;
      width: 67px;
      height: 220px;
      background-size: 67px; }
    #Page.pageIndex #Main #ContBox02 .wrapBox::after {
      top: -90px;
      right: -15px;
      width: 102px;
      height: 230px;
      background-size: 102px; }
    #Page.pageIndex #Main #ContBox02 .wrapBox > p {
      margin-bottom: 37px;
      line-height: 30px;
      letter-spacing: 0.15em;
      font-size: 16px; }
    #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton {
      position: relative;
      z-index: 2; }
      #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li {
        margin-top: 20px; }
        #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a::after {
          right: 10px; }
        #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span {
          min-height: 108px; }
          #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span .titleTypeBasic {
            margin-top: 0;
            font-size: 20px;
            letter-spacing: 0.05em; }
          #Page.pageIndex #Main #ContBox02 .wrapBox .listTypeButton li a span p {
            margin-top: 0;
            line-height: 22px;
            letter-spacing: 0.05em;
            font-size: 14px; }
    #Page.pageIndex #Main #ContBox02 .buttonTypeBasic {
      margin-top: 44px; }
  #Page.pageIndex #Main #ContBox03 {
    padding-top: 95px; }
    #Page.pageIndex #Main #ContBox03 .titleTypeBasic {
      margin-bottom: 24px; }
    #Page.pageIndex #Main #ContBox03 .listTypeButton li {
      border-bottom-width: 7.5px; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox::after {
        right: 30px; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox span {
        min-height: 102px; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox h4 {
        letter-spacing: .1em;
        font-size: 20px; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li a .textBox p {
        margin-top: -3px;
        line-height: 22px; }
      #Page.pageIndex #Main #ContBox03 .listTypeButton li a[href*=youtube] .textBox::after {
        right: 18px; }
  #Page.pageIndex #Main #ContBox04 .innerBasic {
    padding-top: 87px;
    padding-bottom: 76px; }
  #Page.pageIndex #Main #ContBox04 .titleTypeBasic {
    margin-bottom: 34px; }
    #Page.pageIndex #Main #ContBox04 .titleTypeBasic::after {
      transform: translate(-51%, -77%) rotate(-10deg); }
    #Page.pageIndex #Main #ContBox04 .titleTypeBasic + p {
      margin-bottom: 29px;
      line-height: 30px;
      letter-spacing: 0.15em;
      font-size: 16px; }
  #Page.pageIndex #Main #ContBox04 ul li {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 32px; }
    #Page.pageIndex #Main #ContBox04 ul li:nth-of-type(3n) {
      margin-bottom: 0; }
    #Page.pageIndex #Main #ContBox04 ul li .boxTypeBalloon {
      margin-bottom: 8px; }
    #Page.pageIndex #Main #ContBox04 ul li .wrapBox .textBox {
      top: 234px;
      padding-top: 8px; }
      #Page.pageIndex #Main #ContBox04 ul li .wrapBox .textBox span {
        margin-top: -7px; }
  #Page.pageIndex #Main #ContBox04 .buttonTypeBasic {
    margin-top: 44px; }
  #Page.pageIndex #Main #ContBox05 .innerBasic {
    padding-top: 77px;
    padding-bottom: 40px; }
  #Page.pageIndex #Main #ContBox05 .titleTypeBasic {
    margin-bottom: 36px; }
  #Page.pageIndex #Main #ContBox05 .listTypeButton {
    padding: 0; }
    #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01 {
      position: relative;
      margin-bottom: 15px; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01::before, #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01::after {
        content: "";
        position: absolute;
        z-index: 0;
        display: block;
        background-repeat: no-repeat;
        background-position: center top;
        /* ↓アニメーション用のスタイル */
        opacity: 0;
        transition-delay: 6s; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01::before {
        top: -179px;
        left: 10px;
        width: 47px;
        height: 179px;
        background-image: url("../img/contents/top/photo_11.png");
        background-size: 47px;
        /* ↓アニメーション用のスタイル */
        transform: translate(-40px, 40px); }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01::after {
        top: -203px;
        right: 0;
        width: 94px;
        height: 203px;
        background-image: url("../img/contents/top/photo_08.png");
        background-size: 94px;
        /* ↓アニメーション用のスタイル */
        transform: translate(40px, 40px); }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01.fadeIn::before, #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01.fadeIn::after {
        transition: 0.5s ease-in;
        transform: translate(0, 0);
        opacity: 1 !important; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01 li a span {
        justify-content: center;
        text-align: center;
        font-size: 20px; }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01 li a span b {
          position: relative;
          display: inline-block; }
          #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01 li a span b::before {
            content: "";
            position: absolute;
            top: 50%;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            transform: translateY(-50%); }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton01 li.icon_02 a::before {
        margin-left: -54px; }
    #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 {
      display: flex;
      flex-wrap: wrap; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02::after {
        content: "";
        display: block;
        clear: both; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li {
        margin-top: 0;
        margin-bottom: 15px;
        width: calc((100% - 15px)/2); }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li:nth-of-type(2n+1) {
          margin-right: 15px; }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li:nth-of-type(4n) {
          margin-right: 0; }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li a {
          padding: 10px 0 10px;
          min-height: 95px;
          font-size: 16px; }
          #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li a::before {
            position: static;
            display: block;
            transform: translateY(0);
            margin: 0 auto 6px; }
          #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li a span {
            display: block;
            padding-left: 0;
            min-height: auto;
            line-height: 20px;
            text-align: center; }
            #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li a span > b {
              display: block;
              min-height: auto;
              font-size: 14px; }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li.icon_05 a {
          padding-top: 27px; }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li.icon_06 a {
          padding-top: 14px; }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li.icon_07 a {
          padding-top: 27px; }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton02 li.icon_08 a {
          padding-top: 14px; }
    #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li {
      float: left;
      margin-top: 0;
      margin-bottom: 15px;
      width: calc((100% - 15px)/2); }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li:nth-of-type(2n+1) {
        margin-right: 15px; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li:nth-of-type(4n) {
        margin-right: 0; }
      #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li a {
        font-size: 16px; }
        #Page.pageIndex #Main #ContBox05 .listTypeButton.listTypeButton03 li a span {
          justify-content: center;
          padding-right: 0;
          padding-left: 0;
          text-align: center;
          min-height: 70px; }
  #Page.pageIndex #Main #ContBox06 .innerBasic {
    padding-bottom: 60px; }
  #Page.pageIndex #Main #ContBox06 .titleTypeBasic {
    margin-bottom: 18px; }
    #Page.pageIndex #Main #ContBox06 .titleTypeBasic + p {
      margin-bottom: 113px; }
  #Page.pageIndex #Main #ContBox06 .txtTypeCenter:last-of-type {
    margin-top: 22px; }
  #Page.pageIndex #Main #ContBox06 .listTypeButton {
    position: relative; }
    #Page.pageIndex #Main #ContBox06 .listTypeButton::before, #Page.pageIndex #Main #ContBox06 .listTypeButton::after {
      content: "";
      position: absolute;
      z-index: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: center top;
      /* ↓アニメーション用のスタイル */
      opacity: 0;
      transition-delay: 6s; }
    #Page.pageIndex #Main #ContBox06 .listTypeButton::before {
      top: -108px;
      left: 56px;
      width: 108px;
      height: 108px;
      background-image: url("../img/contents/top/photo_07.png");
      background-size: 108px;
      /* ↓アニメーション用のスタイル */
      transform: translateX(-40px); }
    #Page.pageIndex #Main #ContBox06 .listTypeButton::after {
      top: -103px;
      right: 47px;
      width: 120px;
      height: 103px;
      background-image: url("../img/contents/top/photo_06.png");
      background-size: 120px;
      /* ↓アニメーション用のスタイル */
      transform: translateX(40px); }
    #Page.pageIndex #Main #ContBox06 .listTypeButton.fadeIn::before, #Page.pageIndex #Main #ContBox06 .listTypeButton.fadeIn::after {
      transition: 0.5s ease-in;
      transform: translate(0, 0);
      opacity: 1 !important; }
    #Page.pageIndex #Main #ContBox06 .listTypeButton li a span b p {
      margin-top: -4px; }
  #Page.pageIndex #Main #ContBox06 .buttonTypeBasic {
    margin-top: 30px; }
  #Page.pageIndex #Main #ContBox07 .innerBasic {
    padding-top: 73px;
    padding-bottom: 75px; }
  #Page.pageIndex #Main #ContBox07 .titleTypeBasic {
    margin-bottom: 25px; }
  #Page.pageIndex #Main #ContBox07 .listTypeBlog li a {
    padding-bottom: 17px; }
    #Page.pageIndex #Main #ContBox07 .listTypeBlog li a dl {
      margin-bottom: 0; }
      #Page.pageIndex #Main #ContBox07 .listTypeBlog li a dl dt {
        margin-bottom: 2px; }
      #Page.pageIndex #Main #ContBox07 .listTypeBlog li a dl dd {
        text-decoration: underline;
        line-height: 28px;
        font-size: 16px; }
  #Page.pageIndex #Main #ContBox07 .buttonTypeBasic {
    margin-top: 30px; } }
/*
私たちの約束
-------------------------------------*/
#PageGeeting.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon {
  font-weight: 400; }
#PageGeeting.pageIndex #MainImg .titleTypeBasic::before {
  background-image: url("../img/contents/greeting/mainimage_wall.png"); }
#PageGeeting.pageIndex #Main, #PageGeeting.pageIndex #TopicPath {
  background-color: #ffeff6; }
#PageGeeting.pageIndex #Main #ContBox01 {
  background-repeat: repeat-x, repeat;
  background-position: left top, left top;
  background-image: url("../img/contents/wall_pink.gif"), url("../img/contents/wall_01_pink.gif"); }
  #PageGeeting.pageIndex #Main #ContBox01 ol {
    counter-reset: numberGreeting;
    list-style: none; }
    #PageGeeting.pageIndex #Main #ContBox01 ol > li {
      position: relative;
      background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-x, repeat-y, repeat-x, repeat-y;
      background-position: left top, right top, right bottom, left bottom, left top, right top, left bottom, left top;
      background-image: url("../img/contents/box_corner_a_01.gif"), url("../img/contents/box_corner_b_01.gif"), url("../img/contents/box_corner_c_01.gif"), url("../img/contents/box_corner_d_01.gif"), url("../img/contents/box_border_a_01.gif"), url("../img/contents/box_border_b_01.gif"), url("../img/contents/box_border_c_01.gif"), url("../img/contents/box_border_d_01.gif");
      background-size: 15px auto, 15px auto, 15px auto, 15px auto, 1px auto, auto 1px, 1px auto, auto 1px;
      background-color: #fff;
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 400; }
      #PageGeeting.pageIndex #Main #ContBox01 ol > li::before {
        counter-increment: numberGreeting;
        content: "0" counter(numberGreeting) ". ";
        position: absolute;
        font-family: 'Dancing Script', cursive;
        font-style: italic;
        font-weight: 400;
        color: #ff65a6; }
#PageGeeting.pageIndex #Main #ContBox02 {
  background-color: #fff; }
  #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li {
    background-color: #f6f6f6; }
    #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a::after {
      display: none; }
    #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox {
      position: relative; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox::after {
        content: "";
        position: absolute;
        top: 50%;
        display: block;
        transform: translateY(-50%);
        width: 10px;
        height: 17px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("../img/contents/arrow_01.png");
        background-size: 10px auto; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox span {
        padding: 0;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox h4, #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox p {
        padding: 0;
        text-align: center; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox h4 {
        color: #f54992; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox p {
        margin-bottom: 0;
        font-size: 14px;
        color: #888; }
    #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dd ul li {
      border-bottom-width: 2px;
      border-bottom-color: #f6f6f6;
      width: 100%; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dd ul li:last-of-type {
        border-bottom: none; }
#PageGeeting.pageIndex #Main #ContBox03 {
  background-color: #fff; }
  #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li a {
    color: #f54992; }
  #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.green a {
    color: #16b982; }
  #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.yellow a {
    color: #f49400; }
  #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.blue a {
    color: #00b6dd; }

@media print, screen and (min-width: 768px) {
  #PageGeeting.pageIndex #MainImg {
    background-image: url("../img/contents/greeting/mainimage.jpg"); }
    #PageGeeting.pageIndex #MainImg .innerBasic {
      transform: translate(-50%, -16%); }
    #PageGeeting.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon {
      margin: 0 auto -3px;
      width: 430px;
      height: 82px;
      background-image: url("../img/contents/balloon_08_pink_pc.png");
      background-size: 430px;
      font-size: 22px; }
      #PageGeeting.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon span {
        padding-left: 18px;
        min-height: 60px;
        letter-spacing: .12em; }
    #PageGeeting.pageIndex #MainImg .titleTypeBasic::before {
      width: 638px;
      height: 323px;
      background-size: 638px auto; }
  #PageGeeting.pageIndex #Main #ContBox01 {
    padding-top: 21px;
    padding-bottom: 90px;
    background-size: 2px 300px, 8px auto; }
    #PageGeeting.pageIndex #Main #ContBox01 .titleTypeBasic {
      margin-bottom: 51px; }
    #PageGeeting.pageIndex #Main #ContBox01 ol::after {
      content: "";
      display: block;
      clear: both; }
    #PageGeeting.pageIndex #Main #ContBox01 ol > li {
      float: left;
      line-height: 38px;
      letter-spacing: .15em;
      font-size: 20px; }
      #PageGeeting.pageIndex #Main #ContBox01 ol > li:nth-of-type(1) {
        margin-right: 30px;
        padding: 103px 23px 0 38px;
        width: 425px;
        height: 468px; }
      #PageGeeting.pageIndex #Main #ContBox01 ol > li:nth-of-type(2) {
        margin-bottom: 30px;
        padding: 62px 23px 0 128px;
        width: 545px;
        height: 238px; }
      #PageGeeting.pageIndex #Main #ContBox01 ol > li:nth-of-type(3) {
        padding: 62px 23px 0 128px;
        width: 545px;
        height: 200px; }
      #PageGeeting.pageIndex #Main #ContBox01 ol > li::before {
        top: 37px;
        left: 34px;
        font-size: 44px; }
  #PageGeeting.pageIndex #Main #ContBox02 {
    padding-top: 110px; }
    #PageGeeting.pageIndex #Main #ContBox02 .titleTypeBasic {
      margin-bottom: 30px; }
    #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li {
      float: left;
      clear: none;
      border-right: 1px solid #fff;
      margin-top: 0;
      margin-right: 0;
      width: 33.3333333333%; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li:last-of-type {
        border-right: none;
        margin-right: 0; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt {
        margin-right: -1px;
        border-right: 1px solid #f6f6f6;
        width: calc(100% + 1px); }
        #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox::after {
          right: 25%; }
        #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox span {
          min-height: 88px; }
        #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox h4 {
          letter-spacing: .1em;
          font-size: 24px; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dd {
        padding: 30px; }
        #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dd ul li {
          float: none; }
          #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dd ul li a::after {
            right: 14px; }
  #PageGeeting.pageIndex #Main #ContBox03 {
    padding-top: 110px;
    padding-bottom: 150px; }
    #PageGeeting.pageIndex #Main #ContBox03 .titleTypeBasic {
      margin-bottom: 31px; }
    #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li {
      margin-top: 0;
      margin-right: 20px;
      width: 235px;
      clear: none; }
      #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li:nth-of-type(4n) {
        margin-right: 0; }
      #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li a {
        padding: 96px 5px 0;
        height: 235px;
        text-align: center;
        font-size: 22px; }
        #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li a::before, #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li a::after {
          left: 50%;
          transform: translateX(-50%); }
        #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li a::after {
          top: auto;
          bottom: 45px; }
        #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li a span {
          display: block;
          padding: 0; }
      #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.icon_12 a::before {
        top: 39px; }
      #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.icon_13 a::before {
        top: 39px; }
      #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.icon_14 a::before {
        top: 45px; }
      #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.icon_15 a::before {
        top: 44px; } }
@media print, screen and (max-width: 767px) {
  #PageGeeting.pageIndex #MainImg {
    background-image: url("../img/contents/greeting/mainimage_sp.jpg"); }
    #PageGeeting.pageIndex #MainImg .innerBasic {
      transform: translate(-50%, -62%); }
    #PageGeeting.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon {
      margin: 0 auto -8px;
      width: 299px;
      height: 65px;
      background-image: url("../img/contents/balloon_08_pink.png");
      background-size: 299px;
      font-size: 16px; }
      #PageGeeting.pageIndex #MainImg .titleTypeBasic .boxTypeBalloon span {
        padding-left: 18px;
        min-height: 52px;
        letter-spacing: .12em; }
    #PageGeeting.pageIndex #MainImg .titleTypeBasic::before {
      width: 251px;
      height: 117px;
      background-size: 251px auto;
      transform: translate(-50%, -15%); }
    #PageGeeting.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-50%, -34%) rotate(-10deg); }
  #PageGeeting.pageIndex #Main .contBox .titleTypeBasic {
    font-size: 24px; }
    #PageGeeting.pageIndex #Main .contBox .titleTypeBasic::before {
      top: -38px; }
  #PageGeeting.pageIndex #Main #ContBox01 {
    padding-top: 42px;
    padding-bottom: 59px;
    background-size: 2px 300px, 8px auto; }
    #PageGeeting.pageIndex #Main #ContBox01 .titleTypeBasic {
      margin-bottom: 24px; }
    #PageGeeting.pageIndex #Main #ContBox01 ol > li {
      margin-bottom: 15px;
      padding: 62px 23px 53px 70px;
      line-height: 30px;
      letter-spacing: .15em;
      font-size: 16px; }
      #PageGeeting.pageIndex #Main #ContBox01 ol > li::before {
        top: 28px;
        left: 31px;
        font-size: 30px; }
      #PageGeeting.pageIndex #Main #ContBox01 ol > li:nth-of-type(1) {
        padding: 78px 23px 53px 35px; }
      #PageGeeting.pageIndex #Main #ContBox01 ol > li:last-of-type {
        margin-bottom: 0; }
  #PageGeeting.pageIndex #Main #ContBox02 {
    padding-top: 97px; }
    #PageGeeting.pageIndex #Main #ContBox02 .titleTypeBasic {
      margin-bottom: 23px; }
    #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li {
      margin-top: 0; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox::after {
        right: 20%; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox span {
        min-height: 70px; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dt a .textBox h4 {
        letter-spacing: .1em;
        font-size: 20px; }
      #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dd {
        padding: 15px 15px 45px; }
        #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dd ul li a {
          padding: 17px 14px 17px;
          font-size: 16px; }
          #PageGeeting.pageIndex #Main #ContBox02 .listTypeButton li dl dd ul li a::after {
            right: 15px; }
  #PageGeeting.pageIndex #Main #ContBox03 {
    padding-top: 97px;
    padding-bottom: 105px; }
    #PageGeeting.pageIndex #Main #ContBox03 .titleTypeBasic {
      margin-bottom: 24px; }
    #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li a {
      text-align: center;
      font-size: 18px; }
      #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li a::after {
        right: 40px; }
      #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li a span {
        padding-right: 59px;
        justify-content: center;
        min-height: 90px; }
    #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.icon_12 a::before {
      left: 38px; }
    #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.icon_13 a::before {
      left: 41px; }
    #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.icon_14 a::before {
      left: 36px; }
    #PageGeeting.pageIndex #Main #ContBox03 .listTypeButton li.icon_15 a::before {
      left: 40px; } }
/*
看護局のご紹介（職員数・体制）
-------------------------------------*/
#PageAbout.pageIndex #MainImg .titleTypeBasic::before {
  background-image: url("../img/contents/greeting/mainimage_wall.png"); }
#PageAbout.pageIndex #TopicPath {
  background-color: #ffeff6; }
#PageAbout.pageIndex #Main #ContBox05 {
  background-repeat: repeat-x, repeat;
  background-position: left top, left top;
  background-image: url("../img/contents/wall_pink.gif"), url("../img/contents/wall_01_pink.gif"); }
  #PageAbout.pageIndex #Main #ContBox05 .titleTypeBasic .boxTypeBalloon {
    font-weight: 400; }
  #PageAbout.pageIndex #Main #ContBox05 ol {
    counter-reset: numberGreeting;
    list-style: none; }
    #PageAbout.pageIndex #Main #ContBox05 ol > li {
      position: relative;
      background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-x, repeat-y, repeat-x, repeat-y;
      background-position: left top, right top, right bottom, left bottom, left top, right top, left bottom, left top;
      background-image: url("../img/contents/box_corner_a_01.gif"), url("../img/contents/box_corner_b_01.gif"), url("../img/contents/box_corner_c_01.gif"), url("../img/contents/box_corner_d_01.gif"), url("../img/contents/box_border_a_01.gif"), url("../img/contents/box_border_b_01.gif"), url("../img/contents/box_border_c_01.gif"), url("../img/contents/box_border_d_01.gif");
      background-size: 15px auto, 15px auto, 15px auto, 15px auto, 1px auto, auto 1px, 1px auto, auto 1px;
      background-color: #fff;
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 400; }
      #PageAbout.pageIndex #Main #ContBox05 ol > li::before {
        counter-increment: numberGreeting;
        content: "0" counter(numberGreeting) ". ";
        position: absolute;
        font-family: 'Dancing Script', cursive;
        font-style: italic;
        font-weight: 400;
        color: #ff65a6; }
#PageAbout.pageIndex #Main #ContBox02 .wrapBox .imgBox p {
  margin-bottom: 0;
  text-align: center; }
#PageAbout.pageIndex #Main #ContBox02 .wrapBox .textBox p:last-of-type {
  margin-bottom: 0; }
#PageAbout.pageIndex #Main #ContBox06 {
  background-color: #fff; }
  #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li a {
    color: #f54992; }
  #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.green a {
    color: #16b982; }
  #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.yellow a {
    color: #f49400; }
  #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.blue a {
    color: #00b6dd; }
#PageAbout.pageIndex #Main #ContBox07 .listFigure {
  text-align: center;
  font-size: 0; }
  #PageAbout.pageIndex #Main #ContBox07 .listFigure li {
    display: inline-block; }
    #PageAbout.pageIndex #Main #ContBox07 .listFigure li img {
      height: auto; }
#PageAbout.pageIndex #Main #ContBox07 .listTypeButton li .borderBox {
  position: relative;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  color: #222; }
  #PageAbout.pageIndex #Main #ContBox07 .listTypeButton li .borderBox::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  #PageAbout.pageIndex #Main #ContBox07 .listTypeButton li .borderBox span {
    justify-content: space-evenly; }

@media print, screen and (min-width: 768px) {
  #PageAbout.pageIndex #MainImg {
    background-image: url("../img/contents/greeting/mainimage.jpg"); }
    #PageAbout.pageIndex #MainImg .innerBasic {
      transform: translate(-50%, 66%); }
    #PageAbout.pageIndex #MainImg .titleTypeBasic::before {
      width: 638px;
      height: 323px;
      background-size: 638px auto; }
    #PageAbout.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-51%, -56%) rotate(-10deg); }
  #PageAbout.pageIndex #Main #ContBox05 {
    margin-bottom: 40px;
    padding-top: 21px;
    padding-bottom: 90px;
    background-size: 2px 300px, 8px auto; }
    #PageAbout.pageIndex #Main #ContBox05 .titleTypeBasic {
      margin-bottom: 51px; }
      #PageAbout.pageIndex #Main #ContBox05 .titleTypeBasic .boxTypeBalloon {
        margin: 0 auto;
        width: 430px;
        height: 82px;
        background-image: url("../img/contents/balloon_08_pink_pc.png");
        background-size: 430px;
        font-size: 22px; }
        #PageAbout.pageIndex #Main #ContBox05 .titleTypeBasic .boxTypeBalloon span {
          padding-left: 18px;
          min-height: 60px;
          letter-spacing: .12em; }
    #PageAbout.pageIndex #Main #ContBox05 ol::after {
      content: "";
      display: block;
      clear: both; }
    #PageAbout.pageIndex #Main #ContBox05 ol > li {
      float: left;
      line-height: 38px;
      letter-spacing: .15em;
      font-size: 20px; }
      #PageAbout.pageIndex #Main #ContBox05 ol > li:nth-of-type(1) {
        margin-right: 30px;
        padding: 103px 23px 0 38px;
        width: 425px;
        height: 468px; }
      #PageAbout.pageIndex #Main #ContBox05 ol > li:nth-of-type(2) {
        margin-bottom: 30px;
        padding: 62px 23px 0 128px;
        width: 545px;
        height: 238px; }
      #PageAbout.pageIndex #Main #ContBox05 ol > li:nth-of-type(3) {
        padding: 62px 23px 0 128px;
        width: 545px;
        height: 200px; }
      #PageAbout.pageIndex #Main #ContBox05 ol > li::before {
        top: 37px;
        left: 34px;
        font-size: 44px; }
  #PageAbout.pageIndex #Main #ContBox02 .wrapBox .imgBox {
    float: left;
    width: 240px; }
    #PageAbout.pageIndex #Main #ContBox02 .wrapBox .imgBox p {
      margin-top: 21px;
      line-height: 28px; }
      #PageAbout.pageIndex #Main #ContBox02 .wrapBox .imgBox p .txtTypeGray {
        font-size: 14px; }
  #PageAbout.pageIndex #Main #ContBox02 .wrapBox .textBox {
    float: right;
    margin-top: -8px;
    width: 700px; }
  #PageAbout.pageIndex #Main #ContBox04 .tableTypeBasic tbody th {
    width: 360px; }
  #PageAbout.pageIndex #Main #ContBox06 .titleTypeBasic {
    margin-bottom: 31px; }
  #PageAbout.pageIndex #Main #ContBox06 .listTypeButton {
    margin: 0 auto;
    width: 745px; }
    #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li {
      margin-top: 0;
      margin-right: 20px;
      width: 235px;
      clear: none; }
      #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li:nth-of-type(3n) {
        margin-right: 0; }
      #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li a {
        padding: 96px 5px 0;
        height: 235px;
        text-align: center;
        font-size: 22px; }
        #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li a::before, #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li a::after {
          left: 50%;
          transform: translateX(-50%); }
        #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li a::after {
          top: auto;
          bottom: 45px; }
        #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li a span {
          display: block;
          padding: 0; }
      #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.icon_12 a::before {
        top: 39px; }
      #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.icon_13 a::before {
        top: 39px; }
      #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.icon_14 a::before {
        top: 45px; }
      #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.icon_15 a::before {
        top: 44px; }
  #PageAbout.pageIndex #Main #ContBox07 .titleTypePoint {
    margin-bottom: 52px; }
  #PageAbout.pageIndex #Main #ContBox07 .listFigure {
    margin-bottom: 52px; }
    #PageAbout.pageIndex #Main #ContBox07 .listFigure li {
      margin-right: 2px; }
      #PageAbout.pageIndex #Main #ContBox07 .listFigure li img {
        width: 330px; }
  #PageAbout.pageIndex #Main #ContBox07 .listTypeButton {
    margin-top: 50px; }
    #PageAbout.pageIndex #Main #ContBox07 .listTypeButton li {
      float: none;
      margin: 0 auto;
      width: 520px;
      font-size: 20px; }
      #PageAbout.pageIndex #Main #ContBox07 .listTypeButton li .borderBox {
        width: 520px; }
        #PageAbout.pageIndex #Main #ContBox07 .listTypeButton li .borderBox::after {
          right: 25px; } }
@media print, screen and (max-width: 767px) {
  #PageAbout.pageIndex #MainImg {
    background-image: url("../img/contents/greeting/mainimage_sp.jpg"); }
    #PageAbout.pageIndex #MainImg .innerBasic {
      transform: translate(-50%, -52%); }
    #PageAbout.pageIndex #MainImg .titleTypeBasic::before {
      width: 251px;
      height: 117px;
      background-size: 251px auto;
      transform: translate(-50%, -38%); }
    #PageAbout.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-50%, -55%) rotate(-10deg); }
  #PageAbout.pageIndex #Main #ContBox05 {
    margin-bottom: 40px;
    padding-top: 42px;
    padding-bottom: 59px;
    background-size: 2px 300px, 8px auto; }
    #PageAbout.pageIndex #Main #ContBox05 .titleTypeBasic {
      margin-bottom: 24px; }
      #PageAbout.pageIndex #Main #ContBox05 .titleTypeBasic .boxTypeBalloon {
        margin: 0 auto;
        width: 299px;
        height: 65px;
        background-image: url("../img/contents/balloon_08_pink.png");
        background-size: 299px;
        font-size: 16px; }
        #PageAbout.pageIndex #Main #ContBox05 .titleTypeBasic .boxTypeBalloon span {
          padding-left: 18px;
          min-height: 42px;
          letter-spacing: .12em; }
    #PageAbout.pageIndex #Main #ContBox05 ol > li {
      margin-bottom: 15px;
      padding: 62px 23px 53px 70px;
      line-height: 30px;
      letter-spacing: .15em;
      font-size: 16px; }
      #PageAbout.pageIndex #Main #ContBox05 ol > li::before {
        top: 28px;
        left: 31px;
        font-size: 30px; }
      #PageAbout.pageIndex #Main #ContBox05 ol > li:nth-of-type(1) {
        padding: 78px 23px 53px 35px; }
      #PageAbout.pageIndex #Main #ContBox05 ol > li:last-of-type {
        margin-bottom: 0; }
  #PageAbout.pageIndex #Main #ContBox02 .wrapBox .imgBox {
    margin-bottom: 16px;
    text-align: center; }
    #PageAbout.pageIndex #Main #ContBox02 .wrapBox .imgBox img {
      width: 180px; }
    #PageAbout.pageIndex #Main #ContBox02 .wrapBox .imgBox p {
      margin-top: 9px;
      line-height: 28px; }
      #PageAbout.pageIndex #Main #ContBox02 .wrapBox .imgBox p .txtTypeGray {
        font-size: 14px; }
  #PageAbout.pageIndex #Main #ContBox06 .titleTypeBasic {
    margin-bottom: 24px; }
  #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li a {
    text-align: center;
    font-size: 18px; }
    #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li a::after {
      right: 40px; }
    #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li a span {
      padding-right: 59px;
      justify-content: center;
      min-height: 90px; }
  #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.icon_12 a::before {
    left: 38px; }
  #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.icon_13 a::before {
    left: 41px; }
  #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.icon_14 a::before {
    left: 36px; }
  #PageAbout.pageIndex #Main #ContBox06 .listTypeButton li.icon_15 a::before {
    left: 40px; }
  #PageAbout.pageIndex #Main #ContBox07 .titleTypePoint {
    margin-bottom: 24px; }
  #PageAbout.pageIndex #Main #ContBox07 .listFigure {
    margin-bottom: 26px;
    text-align: center;
    font-size: 0; }
    #PageAbout.pageIndex #Main #ContBox07 .listFigure li {
      margin-right: 1px;
      margin-bottom: 0;
      width: calc((100% - 4px) / 3); }
      #PageAbout.pageIndex #Main #ContBox07 .listFigure li:last-of-type {
        margin-right: 0; }
  #PageAbout.pageIndex #Main #ContBox07 .listTypeButton {
    margin-top: -7px; }
    #PageAbout.pageIndex #Main #ContBox07 .listTypeButton li .borderBox {
      font-size: 18px; }
      #PageAbout.pageIndex #Main #ContBox07 .listTypeButton li .borderBox::after {
        right: 17px; } }
/*
先輩からのメッセージ
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageMessage #MainImg {
    background-image: url("../img/contents/message/mainimage.jpg"); }
    #PageMessage #MainImg .innerBasic {
      transform: translate(-50%, 66%); }
    #PageMessage #MainImg .titleTypeBasic::before {
      width: 636px;
      height: 323px;
      background-image: url("../img/contents/message/mainimage_wall.png");
      background-size: 636px auto;
      transform: translate(-50%, -55%); }
    #PageMessage #MainImg .titleTypeBasic::after {
      transform: translate(-51%, -56%) rotate(-10deg); } }
@media print, screen and (max-width: 767px) {
  #PageMessage #MainImg {
    background-image: url("../img/contents/message/mainimage_sp.jpg"); }
    #PageMessage #MainImg .innerBasic {
      transform: translate(-50%, -52%); }
    #PageMessage #MainImg .titleTypeBasic::before {
      width: 318px;
      height: 176px;
      background-image: url("../img/contents/message/mainimage_wall_sp.png");
      background-size: 318px auto;
      transform: translate(-50%, -45%); }
    #PageMessage #MainImg .titleTypeBasic::after {
      transform: translate(-53%, -55%) rotate(-10deg); } }
/*
先輩からのメッセージ　トップページ
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageMessage.pageIndex #Main #ContBox01 {
    padding-top: 49px; }
    #PageMessage.pageIndex #Main #ContBox01::after {
      margin-left: -159px; }
  #PageMessage.pageIndex #Main #ContBox02 .innerBasic > .txtTypeCenter {
    margin-bottom: 82px; } }
@media print, screen and (max-width: 767px) {
  #PageMessage.pageIndex #Main #ContBox01 {
    padding-top: 40px; }
    #PageMessage.pageIndex #Main #ContBox01::after {
      margin-left: -83px; }
  #PageMessage.pageIndex #Main #ContBox02 .innerBasic > .txtTypeCenter {
    margin-bottom: 22px;
    padding-right: 15px;
    padding-left: 15px; } }
/*
先輩からのメッセージ　下層
-------------------------------------*/
#PageMessage.pageCase #Main #ContBox01 .innerBasic:not(:last-of-type) {
  padding-bottom: 0; }
#PageMessage.pageCase #Main #ContBox01 .titleTypeQuestion {
  padding-left: 0; }
#PageMessage.pageCase #Main #ContBox01 > img {
  display: block;
  width: 100%;
  height: auto; }

@media print, screen and (min-width: 768px) {
  #PageMessage.pageCase #SubBox .listTypeMenu li:last-of-type {
    margin-right: 0; }
  #PageMessage.pageCase #SubBoxBottom {
    display: none; }
  #PageMessage.pageCase #Main #ContBox01 {
    margin-top: 0;
    padding-top: 179px; }
    #PageMessage.pageCase #Main #ContBox01 .innerBasic {
      width: 760px; }
    #PageMessage.pageCase #Main #ContBox01 .overviewImg {
      margin-bottom: 95px; }
    #PageMessage.pageCase #Main #ContBox01 .titleTypeQuestion {
      margin-bottom: 39px; }
      #PageMessage.pageCase #Main #ContBox01 .titleTypeQuestion[data-subtitle]::before {
        margin-left: -31px;
        margin-bottom: 65px; }
    #PageMessage.pageCase #Main #ContBox01 > img {
      margin: 53px auto 126px;
      width: 760px; } }
@media print, screen and (max-width: 767px) {
  #PageMessage.pageCase #SubBox {
    display: none; }
  #PageMessage.pageCase #Main #ContBox01 {
    padding-top: 112px; }
    #PageMessage.pageCase #Main #ContBox01 .innerBasic {
      padding-right: 30px;
      padding-bottom: 25px;
      padding-left: 30px; }
    #PageMessage.pageCase #Main #ContBox01 .overviewImg {
      margin-bottom: -3px; }
    #PageMessage.pageCase #Main #ContBox01 .titleTypeQuestion {
      padding-top: 30px;
      margin-bottom: 29px; }
      #PageMessage.pageCase #Main #ContBox01 .titleTypeQuestion[data-subtitle]::before {
        top: -5px;
        left: -1px;
        margin-left: -16px; }
    #PageMessage.pageCase #Main #ContBox01 > img {
      margin: 39px 0 53px; } }
/*
教育&キャリアアップ
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageEducation #MainImg {
    background-image: url("../img/contents/education/mainimage.jpg"); }
    #PageEducation #MainImg .innerBasic {
      transform: translate(-50%, 66%); }
    #PageEducation #MainImg .titleTypeBasic::before {
      width: 636px;
      height: 323px;
      background-image: url("../img/contents/education/mainimage_wall.png");
      background-size: 636px auto;
      transform: translate(-50%, -55%); }
    #PageEducation #MainImg .titleTypeBasic::after {
      transform: translate(-52.5%, -56%) rotate(-10deg); } }
@media print, screen and (max-width: 767px) {
  #PageEducation #MainImg {
    background-image: url("../img/contents/education/mainimage_sp.jpg"); }
    #PageEducation #MainImg .innerBasic {
      transform: translate(-50%, -52%); }
    #PageEducation #MainImg .titleTypeBasic::before {
      width: 318px;
      height: 162px;
      background-image: url("../img/contents/education/mainimage_wall_sp.png");
      background-size: 318px auto;
      transform: translate(-50%, -36%); }
    #PageEducation #MainImg .titleTypeBasic::after {
      transform: translate(-55%, -55%) rotate(-10deg); } }
/*
看護師教育（クリニカルラダー）とキャリアアップ
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageEducation.pageIndex #Main #ContBox01::after {
    margin-left: -326px; }
  #PageEducation.pageIndex #Main #ContBox02 .innerBasic {
    padding-bottom: 122px; }
  #PageEducation.pageIndex #Main #ContBox02 .borderBox {
    margin-bottom: 60px; }
    #PageEducation.pageIndex #Main #ContBox02 .borderBox:first-of-type {
      padding-top: 23px;
      padding-bottom: 23px; }
      #PageEducation.pageIndex #Main #ContBox02 .borderBox:first-of-type .titleTypeBasic {
        letter-spacing: .15em; }
  #PageEducation.pageIndex #Main #ContBox02 .listTypeNumber li {
    margin-bottom: 7px; }
  #PageEducation.pageIndex #Main #ContBox03 .innerBasic {
    padding-bottom: 170px; }
  #PageEducation.pageIndex #Main #ContBox03 .borderBox {
    margin-bottom: 60px;
    padding-top: 23px; }
    #PageEducation.pageIndex #Main #ContBox03 .borderBox .titleTypeBasic {
      letter-spacing: .1em; }
  #PageEducation.pageIndex #Main #ContBox03 .imgBox {
    margin-top: -23px; }
    #PageEducation.pageIndex #Main #ContBox03 .imgBox + .borderBox {
      margin-top: 32px; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageIndex #Main #ContBox02 .borderBox {
    margin-bottom: 50px;
    padding-top: 15px;
    padding-bottom: 15px; }
    #PageEducation.pageIndex #Main #ContBox02 .borderBox .titleTypeBasic {
      letter-spacing: 0; }
  #PageEducation.pageIndex #Main #ContBox02 .listTypeNumber li {
    margin-bottom: 17px; }
  #PageEducation.pageIndex #Main #ContBox03 .innerBasic {
    padding-bottom: 170px; }
  #PageEducation.pageIndex #Main #ContBox03 .borderBox {
    margin-bottom: 47px; }
    #PageEducation.pageIndex #Main #ContBox03 .borderBox:first-of-type {
      padding-top: 15px; }
      #PageEducation.pageIndex #Main #ContBox03 .borderBox:first-of-type .titleTypeBasic {
        letter-spacing: 0; }
      #PageEducation.pageIndex #Main #ContBox03 .borderBox:first-of-type p {
        text-align: center; }
  #PageEducation.pageIndex #Main #ContBox03 .imgBox {
    margin-top: 2px; }
    #PageEducation.pageIndex #Main #ContBox03 .imgBox + .borderBox {
      margin-top: 15px; } }
/*
ラダーレベル別研修
-------------------------------------*/
#PageEducation.pageTraining #Main #ContBox02 .wrapBox {
  background-color: #fff; }
  #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox .listTypeCircle {
    margin-bottom: 0; }
    #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox .listTypeCircle li {
      margin-bottom: 0; }
  #PageEducation.pageTraining #Main #ContBox02 .wrapBox .titleTypeBorder {
    margin-top: 0; }
  #PageEducation.pageTraining #Main #ContBox02 .wrapBox p:last-of-type {
    margin-bottom: 0; }

@media print, screen and (min-width: 768px) {
  #PageEducation.pageTraining #Main #ContBox01::after {
    margin-left: -97px; }
  #PageEducation.pageTraining #Main #ContBox02 {
    margin-bottom: 130px; }
    #PageEducation.pageTraining #Main #ContBox02 .innerBasic {
      padding-top: 76px;
      padding-bottom: 150px;
      width: 1000px; }
    #PageEducation.pageTraining #Main #ContBox02 .titleTypePoint {
      margin-bottom: 30px; }
    #PageEducation.pageTraining #Main #ContBox02 .wrapBox {
      margin-bottom: 48px; }
      #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox, #PageEducation.pageTraining #Main #ContBox02 .wrapBox .imgBox {
        width: 50%; }
      #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox.left {
        padding: 91px 30px 51px 91px; }
      #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox.right {
        padding: 91px 91px 51px 60px; }
      #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox .listTypeCircle li {
        padding-left: 20px; }
      #PageEducation.pageTraining #Main #ContBox02 .wrapBox .imgBox.left {
        padding: 30px 0 30px 30px; }
      #PageEducation.pageTraining #Main #ContBox02 .wrapBox .imgBox.right {
        padding: 30px 30px 30px 0; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageTraining #Main #ContBox01::after {
    margin-left: -65px; }
  #PageEducation.pageTraining #Main #ContBox02 {
    margin-bottom: 70px; }
    #PageEducation.pageTraining #Main #ContBox02 .innerBasic {
      padding-top: 50px;
      padding-bottom: 17px; }
    #PageEducation.pageTraining #Main #ContBox02 .titleTypePoint {
      margin-bottom: 20px; }
    #PageEducation.pageTraining #Main #ContBox02 .wrapBox {
      margin-bottom: 40px; }
      #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox {
        padding: 31px 25px 31px 25px; }
        #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox + .textBox {
          padding-top: 0; }
        #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox + .imgBox {
          margin-top: -5px; }
        #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox .titleTypeBorder {
          margin-bottom: 15px; }
        #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox .listTypeCircle li {
          padding-left: 14px; }
          #PageEducation.pageTraining #Main #ContBox02 .wrapBox .textBox .listTypeCircle li::before {
            top: 10px;
            width: 8px;
            height: 8px; }
      #PageEducation.pageTraining #Main #ContBox02 .wrapBox .imgBox {
        padding: 0 15px 15px 15px; } }
/*
コース研修・ゼミナール等 院内認定看護師育成研修/技能認定研修
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageEducation.pageSeminar #Main #ContBox02 {
    margin-top: -141px; }
    #PageEducation.pageSeminar #Main #ContBox02 .innerBasic {
      padding-bottom: 108px; }
    #PageEducation.pageSeminar #Main #ContBox02 .titleTypeBorder {
      margin-bottom: 28px; }
    #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic thead tr th:nth-of-type(1) {
      width: 190px; }
    #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic thead tr th:nth-of-type(2) {
      width: 83px; }
    #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic thead tr th:nth-of-type(4) {
      width: 232px; }
    #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic tbody tr td:nth-of-type(1) {
      text-align: center; }
    #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic + .txtTypeRight {
      margin-top: -8px; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageSeminar #Main #ContBox01 {
    padding-bottom: 118px; }
    #PageEducation.pageSeminar #Main #ContBox01::after {
      margin-left: -70px; }
  #PageEducation.pageSeminar #Main #ContBox02 {
    margin-top: -67px; }
    #PageEducation.pageSeminar #Main #ContBox02 .innerBasic {
      padding-bottom: 50px; }
    #PageEducation.pageSeminar #Main #ContBox02 .titleTypeBorder {
      margin-bottom: 22px; }
    #PageEducation.pageSeminar #Main #ContBox02 .tableTypeScroll {
      margin-bottom: 50px; }
    #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic {
      margin-bottom: 50px;
      width: 445px; }
      #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic thead tr th:nth-of-type(1) {
        width: 100px; }
      #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic thead tr th:nth-of-type(2) {
        width: 90px; }
      #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic thead tr th:nth-of-type(3) {
        width: 155px; }
      #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic thead tr th:nth-of-type(4) {
        width: 100px; }
      #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic + .txtTypeRight {
        margin-top: -29px;
        font-size: 12px; } }
@media print, screen and (max-width: 767px) and (min-width: 475px) {
  #PageEducation.pageSeminar #Main #ContBox02 .textTypeSwipe {
    display: none; }
  #PageEducation.pageSeminar #Main #ContBox02 .tableTypeBasic {
    width: 100%; } }
/*
地域医療支援センター/スキルセンター
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageEducation.pageSkills-center #Main #ContBox02 .innerBasic, #PageEducation.pageSkills-center #Main #ContBox03 .innerBasic {
    padding-bottom: 153px; }
  #PageEducation.pageSkills-center #Main #ContBox02 .titleTypeBorder, #PageEducation.pageSkills-center #Main #ContBox03 .titleTypeBorder {
    margin-top: 61px;
    margin-bottom: 22px; }
  #PageEducation.pageSkills-center #Main #ContBox02 .listFigure, #PageEducation.pageSkills-center #Main #ContBox03 .listFigure {
    font-size: 0; }
    #PageEducation.pageSkills-center #Main #ContBox02 .listFigure li, #PageEducation.pageSkills-center #Main #ContBox03 .listFigure li {
      margin-right: 2px; }
      #PageEducation.pageSkills-center #Main #ContBox02 .listFigure li:nth-of-type(2n), #PageEducation.pageSkills-center #Main #ContBox03 .listFigure li:nth-of-type(2n) {
        margin-right: 0; }
      #PageEducation.pageSkills-center #Main #ContBox02 .listFigure li img, #PageEducation.pageSkills-center #Main #ContBox03 .listFigure li img {
        height: 299px; }
  #PageEducation.pageSkills-center #Main #ContBox02 .wrapBox, #PageEducation.pageSkills-center #Main #ContBox03 .wrapBox {
    margin-bottom: 0; }
    #PageEducation.pageSkills-center #Main #ContBox02 .wrapBox .textBox, #PageEducation.pageSkills-center #Main #ContBox03 .wrapBox .textBox {
      width: 50%; }
      #PageEducation.pageSkills-center #Main #ContBox02 .wrapBox .textBox:nth-of-type(odd), #PageEducation.pageSkills-center #Main #ContBox03 .wrapBox .textBox:nth-of-type(odd) {
        padding-right: 30px; }
      #PageEducation.pageSkills-center #Main #ContBox02 .wrapBox .textBox:nth-of-type(even), #PageEducation.pageSkills-center #Main #ContBox03 .wrapBox .textBox:nth-of-type(even) {
        padding-left: 30px; }
    #PageEducation.pageSkills-center #Main #ContBox02 .wrapBox:first-child .titleTypeBorder, #PageEducation.pageSkills-center #Main #ContBox03 .wrapBox:first-child .titleTypeBorder {
      margin-top: 0; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageSkills-center #Main #ContBox01::after {
    margin-left: -73px; }
  #PageEducation.pageSkills-center #Main #ContBox02 .titleTypeBorder, #PageEducation.pageSkills-center #Main #ContBox03 .titleTypeBorder {
    margin-top: 33px;
    margin-bottom: 18px; }
  #PageEducation.pageSkills-center #Main #ContBox02 .wrapBox, #PageEducation.pageSkills-center #Main #ContBox03 .wrapBox {
    margin-bottom: 0; }
    #PageEducation.pageSkills-center #Main #ContBox02 .wrapBox:first-child .textBox:first-child .titleTypeBorder, #PageEducation.pageSkills-center #Main #ContBox03 .wrapBox:first-child .textBox:first-child .titleTypeBorder {
      margin-top: 0; }
  #PageEducation.pageSkills-center #Main #ContBox02 .innerBasic {
    padding-bottom: 76px; }
  #PageEducation.pageSkills-center #Main #ContBox03 .innerBasic {
    padding-bottom: 103px; } }
/*
新人看護師教育プログラム
-------------------------------------*/
#PageEducation.pageProgram #Main #ContBox02 .listTypeButton li.borderBox p:last-of-type {
  margin-bottom: 0; }
#PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel {
  font-family: 'M PLUS 1p', sans-serif;
  font-weight: bold; }
  #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel b span {
    font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
    font-weight: bold; }
#PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox {
  padding: 0; }
  #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox .textBox .listTypeCircle {
    margin-bottom: 0; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox .textBox .listTypeCircle li {
      margin-bottom: 0; }

@media print, screen and (min-width: 768px) {
  #PageEducation.pageProgram #Main #ContBox01::after {
    margin-left: -609px;
    width: 2000px; }
  #PageEducation.pageProgram #Main #ContBox01 .titleTypeBasic[data-subtitle] + .titleTypeBasic {
    margin-bottom: 13px;
    line-height: 40px;
    letter-spacing: 0.1em;
    font-size: 24px; }
  #PageEducation.pageProgram #Main #ContBox02 {
    margin-top: -120px; }
    #PageEducation.pageProgram #Main #ContBox02 .innerBasic {
      padding-bottom: 90px; }
    #PageEducation.pageProgram #Main #ContBox02 .listTypeButton li.borderBox {
      padding: 26px 0 29px; }
      #PageEducation.pageProgram #Main #ContBox02 .listTypeButton li.borderBox p:last-of-type {
        margin-bottom: 0; }
  #PageEducation.pageProgram #Main #ContBox03 .innerBasic {
    padding-bottom: 150px; }
  #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    margin-top: -8px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox::before {
      content: "";
      position: absolute;
      display: block;
      z-index: 1;
      bottom: -38px;
      left: 0;
      border: none;
      width: 120px;
      height: 50px;
      background-repeat: no-repeat, repeat-y;
      background-position: center bottom, left top;
      background-size: 120px auto, 120px auto; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: center;
      margin-right: 20px;
      padding-top: 27px;
      padding-left: 0;
      width: 120px;
      height: auto;
      font-size: 24px; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel::before, #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel::after {
        display: none; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel b {
        padding-top: 17px; }
        #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel b span {
          font-size: 14px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox {
      margin-top: 26px;
      width: 860px; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox::after, #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox::before {
        top: 50%;
        transform: translateY(-50%); }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox::after {
        left: -11px;
        border-color: transparent #eaeaea transparent transparent;
        border-width: 11px 11px 11px 0; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox::before {
        left: -9px;
        border-color: transparent #fff transparent transparent;
        border-width: 9px 9px 9px 0; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox .textBox {
        padding: 11px 0 10px 17px;
        width: 445px; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox .imgBox {
        position: absolute;
        z-index: 5; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox01 {
      z-index: 2;
      margin-top: 0; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox01::before {
        background-image: url("../img/contents/education/new/program/parts_02.jpg"), url("../img/contents/education/new/program/parts_01.jpg"); }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox01 .titleTypeLabel {
        padding-top: 10px; }
        #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox01 .titleTypeLabel::before {
          border-color: #fcd28d #fcd28d transparent transparent; }
        #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox01 .titleTypeLabel::after {
          border-color: #fcd28d transparent transparent #fcd28d; }
        #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox01 .titleTypeLabel b {
          padding-top: 0; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox01 .balloonBox {
        margin-top: 0; }
        #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox01 .balloonBox .imgBox {
          top: 22px;
          right: 97px;
          width: 330px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox02::before {
      background-image: url("../img/contents/education/new/program/parts_03.jpg"), url("../img/contents/education/new/program/parts_04.jpg"); }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox02 .titleTypeLabel::before {
      border-color: #ffcb93 #ffcb93 transparent transparent; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox02 .titleTypeLabel::after {
      border-color: #ffcb93 transparent transparent #ffcb93; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox02 .balloonBox .imgBox {
      top: 18px;
      right: 33px;
      width: 455px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox03::before {
      background-image: url("../img/contents/education/new/program/parts_05.jpg"), url("../img/contents/education/new/program/parts_07.jpg"); }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox03 .titleTypeLabel::before {
      border-color: #ffb798 #ffb798 transparent transparent; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox03 .titleTypeLabel::after {
      border-color: #ffb798 transparent transparent #ffb798; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox03 .balloonBox .imgBox {
      top: 34px;
      right: 61px;
      width: 148px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox04::before {
      background-image: url("../img/contents/education/new/program/parts_06.jpg"), url("../img/contents/education/new/program/parts_08.jpg"); }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox04 .titleTypeLabel::before {
      border-color: #ffaba0 #ffaba0 transparent transparent; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox04 .titleTypeLabel::after {
      border-color: #ffaba0 transparent transparent #ffaba0; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox04 .balloonBox .imgBox {
      top: 16px;
      right: 43px;
      width: 325px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox05::before {
      background-image: url("../img/contents/education/new/program/parts_10.jpg"), url("../img/contents/education/new/program/parts_09.jpg"); }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox05 .titleTypeLabel::before {
      border-color: #f9a1b2 #f9a1b2 transparent transparent; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox05 .titleTypeLabel::after {
      border-color: #f9a1b2 transparent transparent #f9a1b2; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox05 .balloonBox .imgBox {
      top: 49px;
      right: 93px;
      width: 128px; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageProgram #Main #ContBox01 {
    /*.titleTypeBasic{
    	margin-bottom: 0;
    	&+ .titleTypeBasic{
    		line-height: 32px;
    		font-size: 20px;
    	}
    }*/ }
    #PageEducation.pageProgram #Main #ContBox01::after {
      margin-left: -159px;
      width: 500px; }
  #PageEducation.pageProgram #Main #ContBox02 {
    margin-top: -64px; }
    #PageEducation.pageProgram #Main #ContBox02 .innerBasic {
      padding-bottom: 77px; }
    #PageEducation.pageProgram #Main #ContBox02 .listTypeButton li.borderBox {
      padding: 0 0 15px; }
      #PageEducation.pageProgram #Main #ContBox02 .listTypeButton li.borderBox .titleTypeBasic {
        margin-bottom: 10px; }
      #PageEducation.pageProgram #Main #ContBox02 .listTypeButton li.borderBox p {
        line-height: 28px;
        font-size: 16px; }
        #PageEducation.pageProgram #Main #ContBox02 .listTypeButton li.borderBox p:last-of-type {
          margin-bottom: 0; }
  #PageEducation.pageProgram #Main #ContBox03 .innerBasic {
    padding-bottom: 150px; }
  #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox {
    margin-top: 30px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox:first-of-type {
      margin-top: 0; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel {
      margin-bottom: 0;
      padding-left: 25px;
      width: calc(100% - 15px);
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 18px; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel::before, #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel::after {
        right: -15px; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel::before {
        border-width: 17.5px 15px 0 0; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel::after {
        border-width: 0 15px 17.5px 0; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .titleTypeLabel b span {
        font-size: 12px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox {
      margin-top: 16px; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox .textBox {
        padding: 13px 10px 10px 19px; }
        #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox .textBox .listTypeCircle li {
          padding-left: 19px;
          line-height: 28px;
          font-size: 16px; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox .balloonBox .imgBox {
        position: relative;
        z-index: 5; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox01 .balloonBox .imgBox {
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: -33px;
      width: 247px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox02 .balloonBox .imgBox {
      top: -6px;
      left: 47%;
      transform: translateX(-50%);
      margin-bottom: 6px;
      width: 342px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox03 .balloonBox {
      position: relative; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox03 .balloonBox .textBox {
        padding-bottom: 72px; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox03 .balloonBox .imgBox {
        position: absolute;
        bottom: -21px;
        right: 8px;
        width: 111px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox04 .balloonBox .imgBox {
      top: -6px;
      left: 47%;
      transform: translateX(-50%);
      margin-bottom: -21px;
      width: 243px; }
    #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox05 .balloonBox {
      position: relative; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox05 .balloonBox .textBox {
        padding-bottom: 3px; }
      #PageEducation.pageProgram #Main #ContBox03 .wrapBox .contSubBox.contSubBox05 .balloonBox .imgBox {
        position: absolute;
        bottom: -18px;
        right: 12px;
        width: 96px; } }
/*
新人看護師の1年
-------------------------------------*/
#PageEducation.pageSchedule #Main #ContBox01 {
  padding-bottom: 0; }
#PageEducation.pageSchedule #Main #ContBox02 {
  margin-top: 0; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel {
    display: block;
    background-repeat: no-repeat, repeat;
    background-position: left top;
    background-color: transparent; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel b {
      visibility: hidden; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel::before, #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel::after {
      display: block;
      z-index: 1; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .listTypeCircle {
    margin-bottom: 0; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .listTypeCircle li {
      margin-bottom: 0; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox .titleTypeBasic {
    margin-bottom: 10px;
    text-align: left;
    letter-spacing: 0; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox {
    background-size: auto 1px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox > .titleTypeCircle:first-of-type {
      margin-top: 0; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox .wrapBox {
      margin-bottom: 0; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox p {
      margin-bottom: 0; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .borderBox {
    margin-bottom: 0; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox01 > .wrapBox .stripeBox {
    background-image: url("../img/contents/wall_02_beige.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox01 > .wrapBox .stripeBox li {
      display: inline-block; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 > .wrapBox .stripeBox {
    background-image: url("../img/contents/wall_02_green.gif"); }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 .borderBox + .wrapBox .textBox p:last-of-type {
    margin-bottom: 0; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox .textBox .listTypecheck li {
    margin-bottom: 0; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox .textBox .blue {
    margin-bottom: 0;
    color: #3d95c6; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox .stripeBox {
    background-image: url("../img/contents/wall_02_blue.gif"); }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox04 > .wrapBox .stripeBox {
    background-image: url("../img/contents/wall_02_yellow.gif"); }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .textBox .listTypecheck li {
    margin-bottom: 0; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .stripeBox {
    background-image: url("../img/contents/wall_02_pink.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .stripeBox .wrapBox .box.right .listFigure li img {
      width: 100%;
      height: auto; }
#PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox:first-of-type img {
  width: 100%;
  height: auto; }

@media print, screen and (min-width: 768px) {
  #PageEducation.pageSchedule #Main #ContBox01 {
    padding-bottom: 0; }
    #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .textBox {
      padding-top: 15px; }
      #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .textBox .titleTypeBasic {
        margin-bottom: 17px;
        font-weight: 700; }
        #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .textBox .titleTypeBasic span {
          position: relative;
          display: inline-block;
          padding-right: 33px; }
          #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .textBox .titleTypeBasic span::before, #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .textBox .titleTypeBasic span:after {
            content: "";
            position: absolute;
            display: block;
            width: 15px;
            height: 10px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 15px auto; }
          #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .textBox .titleTypeBasic span::before {
            top: 1px;
            left: -28px;
            background-image: url("../img/contents/top/parts_01_l.png"); }
          #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .textBox .titleTypeBasic span::after {
            bottom: 2px;
            right: 9px;
            background-image: url("../img/contents/top/parts_01_r.png"); }
  #PageEducation.pageSchedule #Main #ContBox02 .innerBasic {
    padding-top: 40px;
    padding-bottom: 68px; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    margin-bottom: 60px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel {
      width: 50px;
      height: auto;
      background-size: 50px auto, auto 1px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel::before, #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel::after {
        top: auto;
        bottom: 0; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel::before {
        right: auto;
        left: 0;
        border-width: 0 25px 20px 0;
        border-color: transparent transparent #fff #fff; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel::after {
        right: 0;
        border-width: 0 0 20px 25px;
        border-color: transparent #fff #fff transparent; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox {
      width: 890px;
      margin-bottom: 60px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .imgBox, #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox {
        margin-bottom: 19px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .imgBox {
        width: 277px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox {
        margin-top: -8px;
        width: 587px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox .titleTypeBasic {
          margin-bottom: 10px;
          line-height: 44px;
          font-size: 28px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox + .titleTypeBorder {
          clear: both;
          margin-bottom: 20px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox + .stripeBox {
          clear: both; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox {
        padding: 63px 60px 60px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox .titleTypeCircle {
          margin-top: 37px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox .listFigure li {
          margin-right: 2px; }
          #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox .listFigure li:nth-of-type(2) {
            margin-right: 0; }
          #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox .listFigure li img {
            height: 229px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox .wrapBox .box {
          width: 370px; }
          #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox .wrapBox .box.left {
            float: left; }
          #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox .wrapBox .box.right {
            float: right; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .borderBox {
        margin-top: 30px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox:nth-of-type(odd) .titleTypeLabel {
      margin-right: 60px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox:nth-of-type(even) {
      flex-direction: row-reverse; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox:nth-of-type(even) .titleTypeLabel {
        margin-left: 60px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox01 .titleTypeLabel {
      background-image: url("../img/contents/education/new/schedule/parts_01.png"), url("../img/contents/wall_02_beige.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox01 > .wrapBox .stripeBox .listTypeCircle {
      margin-right: -6px;
      padding-left: 5px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox01 > .wrapBox .stripeBox .listTypeCircle li {
        margin-right: 9px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 .titleTypeLabel {
      background-image: url("../img/contents/education/new/schedule/parts_02.png"), url("../img/contents/wall_02_green.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 > .wrapBox {
      margin-bottom: 40px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 .borderBox {
      margin-bottom: 21px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 .borderBox + .wrapBox .imgBox {
        width: 194px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 .borderBox + .wrapBox .textBox {
        padding-top: 10px;
        width: 670px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 .titleTypeLabel {
      background-image: url("../img/contents/education/new/schedule/parts_03.png"), url("../img/contents/wall_02_blue.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox {
      margin-bottom: 18px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox .textBox .listTypecheck {
        margin-bottom: 30px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox .textBox .listTypecheck + img {
          width: 290px;
          height: auto; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox .textBox .blue {
        margin-top: 8px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox > .wrapBox .textBox {
        padding-top: 32px;
        width: 445px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox > .wrapBox .textBox.left {
          padding-left: 62px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox > .wrapBox .textBox.right {
          padding-left: 14px; }
          #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox > .wrapBox .textBox.right img {
            margin-top: 12px;
            width: 108px;
            height: auto; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox04 .titleTypeLabel {
      background-image: url("../img/contents/education/new/schedule/parts_04.png"), url("../img/contents/wall_02_yellow.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox04 > .wrapBox .stripeBox {
      padding-bottom: 41px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 .titleTypeLabel {
      background-image: url("../img/contents/education/new/schedule/parts_05.png"), url("../img/contents/wall_02_pink.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox {
      margin-bottom: 22px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .textBox .listTypecheck {
        margin-bottom: 21px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .stripeBox {
        padding-bottom: 35px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .stripeBox .wrapBox .box .titleTypeCircle {
          margin-top: -8px;
          letter-spacing: 0.02em; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .stripeBox .wrapBox .box.right {
          padding: 3px 0 0 10px; }
          #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .stripeBox .wrapBox .box.right .listFigure li {
            width: 355px; }
  #PageEducation.pageSchedule #Main #ContBox03 .innerBasic {
    padding-bottom: 120px; }
    #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox {
      margin-top: 57px; }
      #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox .textBox {
        width: 50%; }
        #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox .textBox.left {
          padding-right: 10px;
          padding-left: 116px; }
          #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox .textBox.left .tableTypeDecoration tbody tr th {
            width: 120px; }
        #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox .textBox.right {
          padding-right: 120px;
          padding-left: 15px; }
          #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox .textBox.right .tableTypeDecoration tbody tr th {
            width: 115px; }
      #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox + .titleTypeBorder {
        margin-top: 0;
        margin-bottom: 26px; }
  #PageEducation.pageSchedule #Main #ContBox03 .tableTypeStripe tbody tr th {
    width: 92px; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageSchedule #Main #ContBox01 .overviewImg {
    padding-bottom: 21px; }
    #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .wrapBox .imgBox .titleTypeBasic {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin-bottom: 0;
      padding-left: 28px;
      text-align: left; }
      #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .wrapBox .imgBox .titleTypeBasic span {
        position: relative;
        display: inline-block; }
        #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .wrapBox .imgBox .titleTypeBasic span::before, #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .wrapBox .imgBox .titleTypeBasic span:after {
          content: "";
          position: absolute;
          display: block;
          width: 10px;
          height: 7px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 10px auto; }
        #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .wrapBox .imgBox .titleTypeBasic span::before {
          top: 1px;
          left: -14px;
          background-image: url("../img/contents/top/parts_01_l.png"); }
        #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .wrapBox .imgBox .titleTypeBasic span::after {
          bottom: -2px;
          right: -14px;
          background-image: url("../img/contents/top/parts_01_r.png"); }
    #PageEducation.pageSchedule #Main #ContBox01 .overviewImg .wrapBox > p {
      margin-top: -25px;
      padding-left: 16px;
      text-align: left; }
  #PageEducation.pageSchedule #Main #ContBox02 .innerBasic {
    padding-top: 39px;
    padding-bottom: 13px; }
  #PageEducation.pageSchedule #Main #ContBox02 .contSubBox {
    position: relative;
    margin-bottom: 60px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel {
      margin-bottom: 29px;
      height: 35px;
      background-size: auto 35px, auto 1px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel::before, #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel::after {
        z-index: 1;
        right: 0; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel::before {
        border-width: 0 0 17.5px 14px;
        border-color: transparent #fff #fff transparent; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox .titleTypeLabel::after {
        border-width: 0 14px 17.5px 0;
        border-color: #fff #fff transparent transparent; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox {
      margin-bottom: 60px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox > .imgBox {
        float: left;
        width: 141px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox .titleTypeBasic {
        margin-bottom: 10px;
        padding-top: 12px;
        padding-left: 153px;
        line-height: 28px;
        font-size: 18px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox .titleTypeBasic + * {
          clear: both; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox .titleTypeBasic + .listTypeCircle {
          padding-top: 8px; }
          #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox .titleTypeBasic + .listTypeCircle li {
            padding-left: 20px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox + .titleTypeBorder {
        margin-top: 26px;
        margin-bottom: 20px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .textBox + .stripeBox {
        clear: both; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox {
        padding: 28px 15px 28px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .stripeBox .titleTypeCircle {
          margin-top: 24px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox > .wrapBox .borderBox {
        margin-top: 15px;
        padding: 21px 22px 16px;
        line-height: 28px;
        font-size: 16px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox01 .titleTypeLabel {
      background-image: url("../img/contents/education/new/schedule/parts_01_sp.png"), url("../img/contents/wall_02_beige.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox01 > .wrapBox .stripeBox {
      padding-bottom: 21px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox01 > .wrapBox .stripeBox .listTypeCircle {
        margin-right: -6px;
        padding-left: 5px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox01 > .wrapBox .stripeBox .listTypeCircle li {
          margin-right: 9px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 .titleTypeLabel {
      background-image: url("../img/contents/education/new/schedule/parts_02_sp.png"), url("../img/contents/wall_02_green.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 > .wrapBox {
      margin-bottom: 40px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 > .wrapBox .stripeBox {
        margin-top: 22px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 .borderBox {
      margin-bottom: 21px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox02 .borderBox + .wrapBox .imgBox {
        margin: 0 auto 7px;
        width: 194px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 .titleTypeLabel {
      background-image: url("../img/contents/education/new/schedule/parts_03_sp.png"), url("../img/contents/wall_02_blue.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox {
      margin-bottom: 18px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox .textBox .listTypecheck {
        padding-top: 10px;
        margin-bottom: 20px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox .textBox .listTypecheck + img {
          max-width: 290px;
          width: 100%;
          height: auto; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox .textBox .blue {
        margin-top: 12px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox .stripeBox {
        margin-top: 20px;
        padding-bottom: 21px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox > .wrapBox .textBox {
        padding-left: 14px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox > .wrapBox .textBox.left {
          margin-bottom: 10px;
          padding-top: 28px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox03 > .wrapBox > .wrapBox .textBox.right img {
          margin-top: 3px;
          width: 108px;
          height: auto; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox04 .titleTypeLabel {
      background-image: url("../img/contents/education/new/schedule/parts_04_sp.png"), url("../img/contents/wall_02_yellow.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox04 > .wrapBox > .textBox {
      margin-bottom: 19px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox04 > .wrapBox .stripeBox {
      padding-bottom: 41px; }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 .titleTypeLabel {
      background-image: url("../img/contents/education/new/schedule/parts_05_sp.png"), url("../img/contents/wall_02_pink.gif"); }
    #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox {
      margin-bottom: 22px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .textBox .titleTypeBasic {
        padding-top: 0; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .textBox .listTypecheck {
        margin-bottom: 21px; }
      #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .stripeBox {
        margin-top: 22px;
        padding-bottom: 6px; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .stripeBox .wrapBox .box .titleTypeCircle {
          margin-top: -8px;
          letter-spacing: 0.02em; }
        #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .stripeBox .wrapBox .box.right {
          padding: 17px 0 0 7px; }
          #PageEducation.pageSchedule #Main #ContBox02 .contSubBox.contSubBox05 > .wrapBox .stripeBox .wrapBox .box.right .listFigure li {
            max-width: 303px;
            width: 100%; }
  #PageEducation.pageSchedule #Main #ContBox03 .innerBasic {
    padding-bottom: 57px; }
    #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox:first-of-type {
      overflow-y: scroll; }
      #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox:first-of-type p {
        text-indent: -9999px;
        margin: 16px auto 27px;
        width: 168px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: left top;
        background-image: url("../img/contents/education/new/schedule/parts_06.png");
        background-size: 168px auto; }
      #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox:first-of-type img {
        width: 750px; }
    #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox {
      margin-top: 35px; }
      #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox .textBox .tableTypeDecoration tbody tr th {
        width: 100px; }
      #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox .textBox.left .tableTypeDecoration {
        margin-bottom: 25px; }
      #PageEducation.pageSchedule #Main #ContBox03 .innerBasic > .wrapBox + .wrapBox + .titleTypeBorder {
        margin-top: 50px;
        margin-bottom: 21px; }
  #PageEducation.pageSchedule #Main #ContBox03 .tableTypeStripe tbody tr th {
    width: 65px; } }
/*
新人看護師指導体制（マンツーマン教育）
-------------------------------------*/
#PageEducation.pagePreceptorship #Main #ContBox01 {
  padding-bottom: 223px; }
  #PageEducation.pagePreceptorship #Main #ContBox01::after {
    margin-left: -414px; }
  #PageEducation.pagePreceptorship #Main #ContBox01 .overviewImg .titleTypeBasic {
    text-shadow: 10px 0 15px white, 0 10px 15px white, -10px 0 15px white, 0 -10px 15px white, 10px 0 15px white, 0 10px 15px white, -10px 0 15px white, 0 -10px 15px white;
    font-weight: 700; }
#PageEducation.pagePreceptorship #Main #ContBox02 {
  counter-reset: number03; }
  #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox .imgBox img {
    border-radius: 50%; }
  #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox .textBox .titleTypeCircle {
    text-align: left; }
    #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox .textBox .titleTypeCircle::before {
      counter-increment: number03;
      content: counter(number03);
      border-color: #ffdfed;
      text-align: center;
      line-height: 1;
      font-family: 'Lato', sans-serif;
      font-weight: 700; }

@media print, screen and (min-width: 768px) {
  #PageEducation.pagePreceptorship #Main #ContBox01 .borderBox {
    margin-top: 51px; }
  #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox {
    margin-bottom: 34px; }
    #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox .imgBox {
      width: 303px; }
    #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox .textBox {
      padding-top: 4px;
      width: 644px; }
      #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox .textBox .titleTypeCircle {
        padding-left: 112px;
        line-height: 44px;
        letter-spacing: .05em;
        font-size: 28px; }
        #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox .textBox .titleTypeCircle::before {
          top: -1px;
          border-width: 12px;
          padding-top: 13px;
          width: 90px;
          height: 90px;
          font-size: 36px; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pagePreceptorship #Main #ContBox01 {
    padding-bottom: 120px; }
    #PageEducation.pagePreceptorship #Main #ContBox01::after {
      margin-left: -157px;
      width: 500px; }
    #PageEducation.pagePreceptorship #Main #ContBox01 .overviewImg .wrapBox .textBox .titleTypeBasic {
      font-size: 18px; }
    #PageEducation.pagePreceptorship #Main #ContBox01 .borderBox {
      margin-top: 23px; }
  #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox {
    margin-bottom: 34px; }
    #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox .imgBox {
      margin: 0 auto -8px;
      max-width: 303px;
      width: 100%; }
    #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox .textBox .titleTypeCircle {
      padding-left: 80px;
      line-height: 24px;
      letter-spacing: .05em;
      font-size: 18px; }
      #PageEducation.pagePreceptorship #Main #ContBox02 .wrapBox .textBox .titleTypeCircle::before {
        top: 3px;
        border-width: 9px;
        padding-top: 9px;
        width: 67px;
        height: 67px;
        font-size: 27px; } }
/*
経験看護師採用（共通）
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageEducation.pageMid-career #SubBox .listTypeMenu li,
  #PageEducation.pageEducation-halfway #SubBox .listTypeMenu li,
  #PageEducation.pageEmployment #SubBox .listTypeMenu li {
    width: calc((100% - 46px) / 4); } }
/*
経験看護師採用
-------------------------------------*/
#PageEducation.pageMid-career #Main #ContBox02 {
  background-repeat: repeat-x, repeat;
  background-position: left 30px, left top;
  background-image: url("../img/contents/wall_pink_02.gif"), url("../img/contents/wall_05_pink.gif");
  background-color: #ffd1da; }
  #PageEducation.pageMid-career #Main #ContBox02 .titleTypeBasic::after {
    color: #ffe1e7; }
  #PageEducation.pageMid-career #Main #ContBox02 .titleTypeBasic {
    position: relative;
    z-index: 1; }
    #PageEducation.pageMid-career #Main #ContBox02 .titleTypeBasic::before {
      display: none; }
    #PageEducation.pageMid-career #Main #ContBox02 .titleTypeBasic::after {
      content: attr(data-subtitle);
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      display: block;
      width: 100%;
      text-align: center;
      font-family: 'Dancing Script', cursive;
      font-style: italic;
      font-weight: 400; }
  #PageEducation.pageMid-career #Main #ContBox02 .innerBasic {
    position: relative;
    width: auto;
    background-color: #fff; }
    #PageEducation.pageMid-career #Main #ContBox02 .innerBasic::before, #PageEducation.pageMid-career #Main #ContBox02 .innerBasic::after {
      content: "";
      position: absolute;
      bottom: 0;
      z-index: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: center top;
      /* ↓アニメーション用のスタイル */
      opacity: 0;
      transition-delay: 6s; }
    #PageEducation.pageMid-career #Main #ContBox02 .innerBasic::before {
      left: 50%;
      background-image: url("../img/contents/education/mid-career/photo_04.png");
      /* ↓アニメーション用のスタイル */
      transform: translateX(-40px); }
    #PageEducation.pageMid-career #Main #ContBox02 .innerBasic::after {
      right: 50%;
      background-image: url("../img/contents/education/mid-career/photo_02.png");
      /* ↓アニメーション用のスタイル */
      transform: translateX(40px); }
    #PageEducation.pageMid-career #Main #ContBox02 .innerBasic.fadeIn::before, #PageEducation.pageMid-career #Main #ContBox02 .innerBasic.fadeIn::after {
      transition: 0.5s ease-in;
      transform: translate(0, 0);
      opacity: 1 !important; }
  #PageEducation.pageMid-career #Main #ContBox02 .wrapBox {
    margin: 0 auto; }
    #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint:first-of-type {
      margin-top: 0; }
    #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint + p {
      text-align: center; }
    #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p {
      position: relative; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p::before, #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p::after {
        content: "";
        position: absolute;
        z-index: 0;
        display: block;
        background-repeat: no-repeat;
        background-position: center top;
        /* ↓アニメーション用のスタイル */
        opacity: 0;
        transition-delay: 6s; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p::before {
        background-image: url("../img/contents/education/mid-career/photo_01.png");
        /* ↓アニメーション用のスタイル */
        transform: translateX(-40px); }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p::after {
        background-image: url("../img/contents/education/mid-career/photo_03.png");
        /* ↓アニメーション用のスタイル */
        transform: translateX(40px); }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p.fadeIn::before, #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p.fadeIn::after {
        transition: 0.5s ease-in;
        transform: translate(0, 0);
        opacity: 1 !important; }
    #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li a {
      color: #f54992; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li a.yellow {
        color: #f49400; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li a.green {
        color: #16b982; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li a.blue {
        color: #00b6dd; }
#PageEducation.pageMid-career #Main #ContBox03 .innerBasic p {
  text-align: justify;
  letter-spacing: .05em;
  font-size: 16px; }
#PageEducation.pageMid-career #Main #ContBox03 .innerBasic .titleTypeSimple {
  font-size: 16px;
  color: #f54992; }
#PageEducation.pageMid-career #Main #ContBox04 .wrapBox {
  background-color: #fff; }
  #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeBasic {
    text-align: left; }
  #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeSimple {
    color: #f54992; }
  #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeLabel {
    display: block;
    font-family: 'Lato', sans-serif;
    font-weight: 400; }
  #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .buttonTypeBasic::after {
    width: 16px;
    height: 16px;
    background-image: url("../img/contents/plus_01.png");
    background-size: 16px auto; }
#PageEducation.pageMid-career #Main #ContBox05 {
  padding-top: 120px;
  padding-bottom: 94px; }
  #PageEducation.pageMid-career #Main #ContBox05 .titleTypeBasic .titleTypeLabel {
    display: block;
    padding: 0;
    font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
    font-weight: 500; }
    #PageEducation.pageMid-career #Main #ContBox05 .titleTypeBasic .titleTypeLabel::before, #PageEducation.pageMid-career #Main #ContBox05 .titleTypeBasic .titleTypeLabel::after {
      display: none; }
  #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic01 {
    margin: 0 auto; }
    #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic01::after {
      content: "";
      display: block;
      width: 16px;
      height: 17px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/contents/arrow_05.png");
      background-size: 16px auto; }
  #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 iframe {
    display: block; }
  #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 .txtTypeRight {
    text-align: right;
    font-size: 14px; }
    #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 .txtTypeRight a {
      color: #888; }
  #PageEducation.pageMid-career #Main #ContBox05 > .titleTypeSimple {
    font-size: 16px;
    text-align: center;
    color: #f54992; }
    #PageEducation.pageMid-career #Main #ContBox05 > .titleTypeSimple + p {
      letter-spacing: .05em;
      font-size: 16px; }
  #PageEducation.pageMid-career #Main #ContBox05 .borderBox {
    position: relative; }
    #PageEducation.pageMid-career #Main #ContBox05 .borderBox::after {
      content: "";
      position: absolute;
      z-index: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: center top;
      /* ↓アニメーション用のスタイル */
      opacity: 0;
      transition-delay: 6s; }
    #PageEducation.pageMid-career #Main #ContBox05 .borderBox::after {
      background-image: url("../img/contents/education/mid-career/photo_05.png");
      /* ↓アニメーション用のスタイル */
      transform: translateX(40px); }
    #PageEducation.pageMid-career #Main #ContBox05 .borderBox.fadeIn::after {
      transition: 0.5s ease-in;
      transform: translate(0, 0);
      opacity: 1 !important; }
    #PageEducation.pageMid-career #Main #ContBox05 .borderBox p {
      letter-spacing: .05em;
      font-size: 16px; }
      #PageEducation.pageMid-career #Main #ContBox05 .borderBox p.titleTypeBasic {
        margin-bottom: 0; }
#PageEducation.pageMid-career .modaal-container {
  background-color: transparent; }
#PageEducation.pageMid-career .modaal-content-container .wrapBox {
  margin-bottom: 0; }
  #PageEducation.pageMid-career .modaal-content-container .wrapBox .imgBox img {
    width: 100%;
    height: auto; }
  #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox {
    position: relative;
    width: auto;
    background-color: #fff; }
    #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeSimple {
      color: #ff65a6; }
    #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel {
      position: relative;
      display: block;
      padding-left: 10px;
      width: 105px;
      background-color: #ff65a6;
      line-height: 26px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #fff; }
      #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel::after, #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel::before {
        content: "";
        position: absolute;
        right: -7.5px;
        margin: auto;
        border-style: solid;
        width: 0;
        height: 0; }
      #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel::after {
        top: 0;
        border-color: #ff65a6 transparent #ff65a6 transparent;
        border-width: 0 9px 13px 0; }
      #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel::before {
        bottom: 0;
        border-color: #ff65a6 transparent transparent #ff65a6;
        border-width: 13px 9px 0 0; }
    #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox p {
      margin-bottom: 20px; }
    #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .buttonTypeNext {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      border-bottom: 1px solid #ff65a6;
      text-decoration: none;
      color: #ff65a6; }
      #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .buttonTypeNext span {
        position: relative;
        display: block;
        text-align: left; }
        #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .buttonTypeNext span::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 7px;
          height: 11px;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("../img/contents/arrow_02.png");
          background-size: 7px auto; }
#PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox {
  position: relative;
  display: block;
  border-style: solid;
  border-color: #f6f6f6;
  background-color: #fff;
  text-decoration: none; }
  #PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox.pink {
    border-color: #ffe0ed; }
  #PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 26px;
    height: 26px;
    transform: translateY(-50%);
    display: block;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../img/contents/arrow_02_w.png");
    background-size: 7px auto;
    background-color: #ff65a6; }
  #PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox span {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center; }

@media print, screen and (min-width: 768px) {
  #PageEducation.pageMid-career #Main #ContBox01 {
    padding-top: 119px;
    padding-bottom: 116px; }
    #PageEducation.pageMid-career #Main #ContBox01 .boxTypeBalloon {
      margin: 0 auto 10px;
      width: 653px;
      height: 84px;
      background-image: url("../img/contents/balloon_03_pink.png");
      background-size: 653px auto; }
      #PageEducation.pageMid-career #Main #ContBox01 .boxTypeBalloon span {
        min-height: 54px;
        letter-spacing: 0;
        font-size: 22px;
        font-weight: 400; }
    #PageEducation.pageMid-career #Main #ContBox01 .titleTypeBasic {
      letter-spacing: .1em;
      font-size: 44px;
      font-weight: 700; }
  #PageEducation.pageMid-career #Main #ContBox02 {
    padding-top: 135px;
    padding-right: 30px;
    padding-bottom: 120px;
    padding-left: 30px;
    background-size: 1px 386px, 8px auto; }
    #PageEducation.pageMid-career #Main #ContBox02 .titleTypeBasic {
      margin-bottom: 83px;
      letter-spacing: .01em;
      font-size: 36px; }
      #PageEducation.pageMid-career #Main #ContBox02 .titleTypeBasic::after {
        transform: translate(-52%, -71%) rotate(-10deg);
        font-size: 180px; }
    #PageEducation.pageMid-career #Main #ContBox02 .innerBasic {
      padding-top: 82px;
      padding-bottom: 120px; }
      #PageEducation.pageMid-career #Main #ContBox02 .innerBasic::before {
        margin-left: -676px;
        width: 352px;
        height: 360px;
        background-size: 352px; }
      #PageEducation.pageMid-career #Main #ContBox02 .innerBasic::after {
        margin-right: -645px;
        width: 392px;
        height: 345px;
        background-size: 393px; }
    #PageEducation.pageMid-career #Main #ContBox02 .wrapBox {
      width: 1000px; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint {
        margin-top: 83px;
        margin-bottom: 19px; }
        #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint + p {
          margin-bottom: 51px;
          line-height: 38px;
          letter-spacing: .18em;
          font-size: 20px; }
          #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint + p + .titleTypePoint {
            margin-top: 71px; }
        #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p::before {
          top: -341px;
          left: -139px;
          width: 286px;
          height: 870px;
          background-size: 286px; }
        #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p::after {
          top: -372px;
          right: -197px;
          width: 380px;
          height: 931px;
          background-size: 380px; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton {
        text-align: center;
        font-size: 0; }
        #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li {
          float: none;
          display: inline-block;
          vertical-align: top;
          margin-right: 10px;
          margin-left: 10px;
          width: 319px; }
          #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li:nth-of-type(2n) {
            margin-right: 10px; }
          #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li:nth-of-type(2n+1) {
            clear: none; }
          #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li:nth-of-type(3n) {
            margin-right: 0; }
          #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li:nth-of-type(3n+1) {
            margin-left: 0; }
          #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li:nth-of-type(3) {
            margin-top: 0; }
        #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton.listTypeButton03 li:nth-of-type(1) {
          display: block;
          margin-right: auto;
          margin-left: auto; }
        #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton.listTypeButton03 li:nth-of-type(2) {
          margin-left: 0; }
        #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton.listTypeButton03 li:nth-of-type(3) {
          margin-right: 10px; }
        #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton.listTypeButton03 li:nth-of-type(2), #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton.listTypeButton03 li:nth-of-type(3) {
          margin-top: 20px; }
        #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton.listTypeButton03 li:nth-of-type(4) {
          margin-right: 0;
          margin-left: 10px; }
  #PageEducation.pageMid-career #Main #ContBox03 {
    padding-top: 96px;
    padding-bottom: 107px; }
    #PageEducation.pageMid-career #Main #ContBox03 .innerBasic {
      padding-top: 29px; }
      #PageEducation.pageMid-career #Main #ContBox03 .innerBasic p {
        line-height: 32px; }
        #PageEducation.pageMid-career #Main #ContBox03 .innerBasic p + .listTypeNumber {
          margin-top: 37px; }
      #PageEducation.pageMid-career #Main #ContBox03 .innerBasic .listTypeNumber li {
        margin-bottom: 7px; }
      #PageEducation.pageMid-career #Main #ContBox03 .innerBasic .listTypeNumber + .titleTypeSimple {
        margin-top: 48px; }
      #PageEducation.pageMid-career #Main #ContBox03 .innerBasic .titleTypeSimple {
        margin-bottom: 11px; }
  #PageEducation.pageMid-career #Main #ContBox04 {
    padding-top: 96px;
    padding-bottom: 123px; }
    #PageEducation.pageMid-career #Main #ContBox04 .titleTypeBasic {
      margin-bottom: 53px; }
    #PageEducation.pageMid-career #Main #ContBox04 .wrapBox {
      position: relative;
      margin-bottom: 0;
      width: 485px; }
      #PageEducation.pageMid-career #Main #ContBox04 .wrapBox.wrapBox01 {
        float: left; }
      #PageEducation.pageMid-career #Main #ContBox04 .wrapBox.wrapBox02 {
        float: right; }
      #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .imgBox {
        width: 485px; }
      #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox {
        padding: 35px 43px 120px;
        width: 485px; }
        #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeBasic {
          margin-bottom: 13px;
          line-height: 44px;
          font-size: 28px; }
          #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeBasic + .txtTypeRight {
            margin-bottom: 20px; }
        #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeSimple {
          margin-bottom: 14px;
          line-height: 32px;
          font-size: 20px; }
        #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeLabel {
          margin-bottom: 6px;
          width: 93px; }
          #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeLabel::after, #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeLabel::before {
            right: -8.25px; }
        #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .buttonTypeBasic {
          position: absolute;
          left: 40px;
          bottom: 59px;
          width: 400px; }
  #PageEducation.pageMid-career #Main #ContBox05 {
    padding-top: 120px;
    padding-bottom: 94px; }
    #PageEducation.pageMid-career #Main #ContBox05 .titleTypeBasic .titleTypeLabel {
      margin: 0 auto 8px;
      width: 204px;
      height: 30px;
      line-height: 30px;
      font-size: 16px; }
    #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic01 {
      padding-top: 5px;
      width: 460px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic01::after {
        margin: 10px auto 23px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic01 .listTypeCircle {
        margin-left: 27px; }
        #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic01 .listTypeCircle li {
          margin-bottom: 0; }
    #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 {
      padding-top: 10px;
      padding-bottom: 10px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 iframe {
        width: 1000px;
        height: 440px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 .txtTypeRight {
        margin-top: 18px;
        letter-spacing: 0.05em;
        font-size: 14px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 .titleTypeSimple {
        margin-top: -31px;
        margin-bottom: 5px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 .listTypeCircle li {
        margin-bottom: 0; }
    #PageEducation.pageMid-career #Main #ContBox05 > .titleTypeSimple {
      line-height: 32px; }
      #PageEducation.pageMid-career #Main #ContBox05 > .titleTypeSimple + p {
        line-height: 32px; }
    #PageEducation.pageMid-career #Main #ContBox05 .borderBox {
      margin: 50px 30px 50px; }
      #PageEducation.pageMid-career #Main #ContBox05 .borderBox::after {
        top: -386px;
        right: 50%;
        margin-right: -504px;
        width: 237px;
        height: 580px;
        background-size: 237px; }
      #PageEducation.pageMid-career #Main #ContBox05 .borderBox p {
        margin-bottom: 5px;
        line-height: 32px;
        letter-spacing: .05em; }
        #PageEducation.pageMid-career #Main #ContBox05 .borderBox p.titleTypeBasic {
          line-height: 38px;
          letter-spacing: .1em;
          font-size: 24px; }
  #PageEducation.pageMid-career .modaal-container {
    width: 740px; }
  #PageEducation.pageMid-career .modaal-content-container {
    width: 740px; }
    #PageEducation.pageMid-career .modaal-content-container .wrapBox {
      margin-bottom: 0;
      width: 740px; }
      #PageEducation.pageMid-career .modaal-content-container .wrapBox .imgBox {
        width: 740px; }
      #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox {
        padding: 42px 43px 17px; }
        #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeSimple {
          margin-bottom: 14px;
          line-height: 32px;
          font-size: 20px; }
        #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel {
          margin-bottom: 6px; }
          #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel::after, #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel::before {
            right: -8.5px; }
        #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .buttonTypeNext:hover {
          opacity: .7;
          transition: .5s; }
        #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .buttonTypeNext span {
          padding-left: 9px;
          width: 150px;
          height: 56px;
          line-height: 56px;
          font-size: 16px; }
          #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .buttonTypeNext span::after {
            right: 11px; }
    #PageEducation.pageMid-career .modaal-content-container .listTypeButton::after {
      content: "";
      display: block;
      clear: both; }
    #PageEducation.pageMid-career .modaal-content-container .listTypeButton li {
      float: left;
      margin-top: 20px;
      margin-right: 20px;
      width: 360px; }
      #PageEducation.pageMid-career .modaal-content-container .listTypeButton li:nth-of-type(2n) {
        margin-right: 0; }
      #PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox {
        border-width: 6px;
        line-height: 32px;
        font-size: 20px; }
        #PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox::after {
          right: 25px; }
        #PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox:hover {
          opacity: .7;
          transition: .5s; }
        #PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox span {
          padding: 0 50px 0 20px;
          min-height: 88px; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageMid-career #Main #ContBox01 {
    padding-top: 72px;
    padding-bottom: 43px; }
    #PageEducation.pageMid-career #Main #ContBox01 .boxTypeBalloon {
      margin: 0 auto 7px;
      width: 319px;
      height: 87px;
      background-image: url("../img/contents/balloon_07_pink.png");
      background-size: 319px auto; }
      #PageEducation.pageMid-career #Main #ContBox01 .boxTypeBalloon span {
        min-height: 67px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0;
        font-size: 16px;
        font-weight: 400; }
    #PageEducation.pageMid-career #Main #ContBox01 .titleTypeBasic {
      line-height: 36px;
      letter-spacing: .1em;
      font-size: 26px;
      font-weight: 700; }
  #PageEducation.pageMid-career #Main #ContBox02 {
    padding-top: 98px;
    padding-bottom: 50px;
    background-size: 1px 386px, 8px auto; }
    #PageEducation.pageMid-career #Main #ContBox02 .titleTypeBasic {
      margin-bottom: 33px;
      line-height: 36px;
      letter-spacing: .01em;
      font-size: 26px; }
      #PageEducation.pageMid-career #Main #ContBox02 .titleTypeBasic::after {
        transform: translate(-53%, -71%) rotate(-10deg);
        font-size: 90px; }
    #PageEducation.pageMid-career #Main #ContBox02 .innerBasic {
      padding-top: 55px;
      padding-bottom: 137px; }
      #PageEducation.pageMid-career #Main #ContBox02 .innerBasic::before {
        margin-left: -116px;
        width: 106px;
        height: 109px;
        background-size: 106px; }
      #PageEducation.pageMid-career #Main #ContBox02 .innerBasic::after {
        margin-right: -119px;
        width: 118px;
        height: 103px;
        background-size: 118px; }
    #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint {
      margin-top: 55px;
      margin-bottom: 14px;
      line-height: 30px; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint + p {
        margin-bottom: 21px;
        line-height: 30px;
        letter-spacing: .18em;
        font-size: 16px; }
        #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint + p + .titleTypePoint {
          margin-top: 71px; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p::before {
        top: -184px;
        left: -12px;
        width: 72px;
        height: 235px;
        background-size: 72px; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .titleTypePoint.titleTypePoint01 + p::after {
        top: -201px;
        right: -22px;
        width: 96px;
        height: 235px;
        background-size: 96px; }
    #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li a {
      line-height: 28px; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li a::after {
        right: 20px; }
      #PageEducation.pageMid-career #Main #ContBox02 .wrapBox .listTypeButton li a span {
        padding-left: 20px;
        min-height: 80px; }
  #PageEducation.pageMid-career #Main #ContBox03 {
    padding-top: 86px;
    padding-bottom: 50px; }
    #PageEducation.pageMid-career #Main #ContBox03 .innerBasic {
      padding-top: 0; }
      #PageEducation.pageMid-career #Main #ContBox03 .innerBasic p {
        line-height: 28px; }
        #PageEducation.pageMid-career #Main #ContBox03 .innerBasic p + .listTypeNumber {
          margin-top: 17px; }
      #PageEducation.pageMid-career #Main #ContBox03 .innerBasic .listTypeNumber li {
        margin-bottom: 16px; }
      #PageEducation.pageMid-career #Main #ContBox03 .innerBasic .listTypeNumber + .titleTypeSimple {
        margin-top: 48px; }
      #PageEducation.pageMid-career #Main #ContBox03 .innerBasic .titleTypeSimple {
        margin-bottom: 13px; }
  #PageEducation.pageMid-career #Main #ContBox04 {
    padding-top: 96px;
    padding-bottom: 75px; }
    #PageEducation.pageMid-career #Main #ContBox04 .titleTypeBasic {
      margin-bottom: 41px; }
    #PageEducation.pageMid-career #Main #ContBox04 .wrapBox.wrapBox01 {
      margin-bottom: 15px; }
    #PageEducation.pageMid-career #Main #ContBox04 .wrapBox.wrapBox02 {
      margin-bottom: 0; }
    #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox {
      padding: 24px 25px 45px; }
      #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeBasic {
        margin-bottom: 19px;
        line-height: 28px;
        letter-spacing: 0;
        font-size: 18px; }
        #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeBasic + .txtTypeRight {
          margin-bottom: 22px;
          line-height: 24px;
          font-size: 14px; }
      #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeSimple {
        margin-bottom: 14px;
        line-height: 28px;
        font-size: 18px; }
      #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeLabel {
        margin-bottom: 11px;
        width: 93px; }
        #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeLabel::before {
          right: -8.5px;
          border-width: 13px 0 13px 9px;
          border-color: transparent transparent transparent #ff65a6; }
        #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .titleTypeLabel::after {
          display: none; }
      #PageEducation.pageMid-career #Main #ContBox04 .wrapBox .textBox .buttonTypeBasic {
        margin: -4px auto 0;
        width: auto;
        max-width: 295px; }
  #PageEducation.pageMid-career #Main #ContBox05 {
    padding-top: 75px;
    padding-bottom: 83px; }
    #PageEducation.pageMid-career #Main #ContBox05 .titleTypeBasic .titleTypeLabel {
      margin: 0 auto 8px;
      width: 172px;
      height: 25px;
      line-height: 25px;
      font-size: 14px; }
    #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic01 {
      padding: 0 48px 0; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic01::after {
        margin: -12px auto 6px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic01 .listTypeCircle li {
        margin-bottom: 0;
        padding-left: 20px; }
    #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 {
      padding-top: 10px;
      padding-bottom: 10px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 iframe {
        width: 100%;
        height: 220px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 .txtTypeRight {
        margin-top: 9px;
        font-size: 14px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 .titleTypeSimple {
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 16px; }
      #PageEducation.pageMid-career #Main #ContBox05 .innerBasic.innerBasic02 .listTypeCircle li {
        margin-bottom: 0; }
    #PageEducation.pageMid-career #Main #ContBox05 > .titleTypeSimple {
      line-height: 32px;
      margin-bottom: -2px; }
      #PageEducation.pageMid-career #Main #ContBox05 > .titleTypeSimple + p {
        line-height: 28px; }
    #PageEducation.pageMid-career #Main #ContBox05 .borderBox {
      margin: 37px 0 34px;
      padding-right: 5px;
      padding-left: 5px; }
      #PageEducation.pageMid-career #Main #ContBox05 .borderBox::after {
        top: -37px;
        right: 50%;
        margin-right: -163px;
        width: 62px;
        height: 154px;
        background-size: 62px; }
      #PageEducation.pageMid-career #Main #ContBox05 .borderBox p {
        margin-bottom: 2px;
        line-height: 24px;
        letter-spacing: .05em;
        font-size: 14px; }
        #PageEducation.pageMid-career #Main #ContBox05 .borderBox p.titleTypeBasic {
          line-height: 28px;
          letter-spacing: .1em;
          font-size: 16px; }
  #PageEducation.pageMid-career .modaal-content-container .wrapBox {
    margin-bottom: 0; }
    #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox {
      padding: 24px 25px 8px; }
      #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeSimple {
        margin-bottom: 14px;
        line-height: 28px;
        font-size: 14px; }
      #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel {
        margin-bottom: 11px; }
        #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel::before {
          right: -7.5px;
          border-width: 13px 0 13px 9px;
          border-color: transparent transparent transparent #ff65a6; }
        #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .titleTypeLabel::after {
          display: none; }
      #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .buttonTypeNext span {
        padding-left: 0;
        width: 115px;
        height: 42px;
        line-height: 42px;
        font-size: 14px; }
        #PageEducation.pageMid-career .modaal-content-container .wrapBox .textBox .buttonTypeNext span::after {
          right: 11px; }
  #PageEducation.pageMid-career .modaal-content-container .listTypeButton li {
    margin-top: 15px; }
    #PageEducation.pageMid-career .modaal-content-container .listTypeButton li:last-of-type {
      margin-bottom: 0; }
    #PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox {
      border-width: 6px;
      line-height: 32px;
      font-size: 20px; }
      #PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox::after {
        right: 19px; }
      #PageEducation.pageMid-career .modaal-content-container .listTypeButton li .borderBox span {
        padding: 0 50px 0 20px;
        min-height: 80px; } }
/*
経験看護師フォローアップ体制
-------------------------------------*/
#PageEducation.pageEducation-halfway #Main #ContBox02 .listTypeButton li p {
  margin-bottom: 0; }
#PageEducation.pageEducation-halfway #Main #ContBox04 .titleTypePoint .titleTypeLabel {
  display: block;
  padding: 0;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  font-weight: 500; }
  #PageEducation.pageEducation-halfway #Main #ContBox04 .titleTypePoint .titleTypeLabel::before, #PageEducation.pageEducation-halfway #Main #ContBox04 .titleTypePoint .titleTypeLabel::after {
    display: none; }
#PageEducation.pageEducation-halfway #Main #ContBox04 .listTypeCircle li {
  margin-bottom: 0; }

@media print, screen and (min-width: 768px) {
  #PageEducation.pageEducation-halfway #Main #ContBox01::after {
    margin-left: -202px; }
  #PageEducation.pageEducation-halfway #Main #ContBox02 {
    margin-top: -121px; }
    #PageEducation.pageEducation-halfway #Main #ContBox02 .innerBasic {
      padding-bottom: 92px; }
    #PageEducation.pageEducation-halfway #Main #ContBox02 .listTypeButton li {
      margin-top: 0;
      padding: 30px 0 33px;
      width: 320px; }
      #PageEducation.pageEducation-halfway #Main #ContBox02 .listTypeButton li:nth-of-type(3n) {
        margin-right: 0; }
      #PageEducation.pageEducation-halfway #Main #ContBox02 .listTypeButton li:nth-of-type(2n+1) {
        clear: none; }
      #PageEducation.pageEducation-halfway #Main #ContBox02 .listTypeButton li:nth-of-type(2n) {
        margin-right: 20px; }
      #PageEducation.pageEducation-halfway #Main #ContBox02 .listTypeButton li .titleTypeBasic {
        line-height: 40px;
        font-size: 24px; }
  #PageEducation.pageEducation-halfway #Main #ContBox03 .innerBasic {
    padding-bottom: 92px; }
  #PageEducation.pageEducation-halfway #Main #ContBox03 .fillBox {
    margin-bottom: 90px;
    padding-top: 19px;
    padding-bottom: 19px; }
  #PageEducation.pageEducation-halfway #Main #ContBox03 .tableTypeBasic thead tr th:nth-of-type(1) {
    width: 272px; }
    #PageEducation.pageEducation-halfway #Main #ContBox03 .tableTypeBasic thead tr th:nth-of-type(1) + th + th {
      width: 230px; }
  #PageEducation.pageEducation-halfway #Main #ContBox04 .titleTypePoint .titleTypeLabel {
    margin: 0 auto 8px;
    width: 204px;
    height: 30px;
    line-height: 30px;
    font-size: 16px; }
  #PageEducation.pageEducation-halfway #Main #ContBox04 .fillBox {
    margin-bottom: 56px;
    padding-top: 19px;
    padding-bottom: 19px; }
  #PageEducation.pageEducation-halfway #Main #ContBox04 .innerBasic {
    padding-bottom: 114px; }
  #PageEducation.pageEducation-halfway #Main #ContBox04 .listTypeCircle {
    margin-bottom: 15px;
    padding-right: 297px;
    padding-left: 297px; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageEducation-halfway #Main #ContBox01 {
    padding-bottom: 105px; }
    #PageEducation.pageEducation-halfway #Main #ContBox01::after {
      margin-left: -93px; }
  #PageEducation.pageEducation-halfway #Main #ContBox02 {
    margin-top: -59px; }
    #PageEducation.pageEducation-halfway #Main #ContBox02 .innerBasic {
      padding-bottom: 76px; }
    #PageEducation.pageEducation-halfway #Main #ContBox02 .listTypeButton li {
      padding: 19px 0 35px; }
      #PageEducation.pageEducation-halfway #Main #ContBox02 .listTypeButton li .titleTypeBasic {
        margin-bottom: 17px;
        font-size: 20px; }
      #PageEducation.pageEducation-halfway #Main #ContBox02 .listTypeButton li p {
        line-height: 28px;
        font-size: 16px; }
  #PageEducation.pageEducation-halfway #Main #ContBox03 .fillBox {
    margin-top: -12px; }
  #PageEducation.pageEducation-halfway #Main #ContBox03 .innerBasic {
    padding-bottom: 35px; }
  #PageEducation.pageEducation-halfway #Main #ContBox03 .tableTypeBasic thead tr th:nth-of-type(1) {
    width: 91px; }
    #PageEducation.pageEducation-halfway #Main #ContBox03 .tableTypeBasic thead tr th:nth-of-type(1) + th + th {
      width: 91px; }
  #PageEducation.pageEducation-halfway #Main #ContBox04 .titleTypePoint .titleTypeLabel {
    margin: 0 auto 8px;
    width: 172px;
    height: 25px;
    line-height: 25px;
    font-size: 14px; }
  #PageEducation.pageEducation-halfway #Main #ContBox04 .fillBox {
    margin-top: -12px;
    margin-bottom: 41px; }
  #PageEducation.pageEducation-halfway #Main #ContBox04 .innerBasic {
    padding-bottom: 70px; }
  #PageEducation.pageEducation-halfway #Main #ContBox04 .listTypeCircle {
    margin-bottom: 15px;
    padding-right: 48px;
    padding-left: 48px; } }
/*
経験看護師再就業支援
-------------------------------------*/
#PageEducation.pageEmployment #Main #ContBox02 .listTypeCircle li {
  margin-bottom: 0; }

@media print, screen and (min-width: 768px) {
  #PageEducation.pageEmployment #Main #ContBox01::after {
    margin-left: -365px; }
  #PageEducation.pageEmployment #Main #ContBox02 .fillBox {
    margin-bottom: 57px;
    padding-top: 19px;
    padding-bottom: 19px; }
  #PageEducation.pageEmployment #Main #ContBox02 .innerBasic {
    padding-bottom: 115px; }
  #PageEducation.pageEmployment #Main #ContBox02 .listTypeCircle {
    margin-bottom: 32px; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageEmployment #Main #ContBox01::after {
    margin-left: -144px; }
  #PageEducation.pageEmployment #Main #ContBox02 .fillBox {
    margin-top: -12px;
    margin-bottom: 51px; }
  #PageEducation.pageEmployment #Main #ContBox02 .innerBasic {
    padding-bottom: 115px; }
  #PageEducation.pageEmployment #Main #ContBox02 .titleTypeBorder {
    margin-bottom: 16px; }
  #PageEducation.pageEmployment #Main #ContBox02 .listTypeCircle {
    margin-bottom: 16px; } }
/*
認定看護師サポート体制
-------------------------------------*/
#PageEducation.pageSupport #Main #ContBox02 .stripeBox {
  border-radius: 8px;
  padding: 20px; }
  #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox {
    position: relative;
    background-color: #fff; }
    #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox > p {
      position: absolute;
      padding-top: 13px;
      width: 71px;
      height: 78px;
      background-repeat: no-repeat;
      background-position: left top;
      background-image: url("../img/contents/balloon_04_pink.png");
      background-size: 71px auto;
      line-height: 20px;
      text-align: center;
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #fff; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox > p b {
        font-family: 'Lato', sans-serif;
        font-weight: 700px;
        font-size: 24px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox > p span {
        font-size: 10px; }
    #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic {
      margin-bottom: 0;
      border-top: none; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr th, #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td {
        font-size: 16px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr th {
        border-bottom-color: #fff;
        background-color: #effcf8;
        color: #37a983; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td {
        border-top: 1px solid #eaeaea;
        border-color: #eaeaea;
        color: #37a983; }
        #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(2) {
          padding: 0 0; }
          #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(2) img {
            margin-top: 2px;
            margin-bottom: 2px;
            height: auto; }
            #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(2) img:last-of-type {
              margin-right: 0; }
        #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(3) {
          padding: 0 0;
          text-align: center;
          color: #f54992; }
          #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(3) span {
            font-family: 'Lato', sans-serif;
            font-weight: 900; }
#PageEducation.pageSupport #Main #ContBox03 .wrapBox .textBox .titleTypeBorder {
  margin-top: 0; }

@media print, screen and (min-width: 768px) {
  #PageEducation.pageSupport #Main #ContBox01::after {
    margin-left: -139px; }
  #PageEducation.pageSupport #Main #ContBox02 .fillBox {
    margin-bottom: 60px;
    padding-top: 19px;
    padding-bottom: 19px; }
  #PageEducation.pageSupport #Main #ContBox02 .stripeBox {
    border-radius: 8px;
    padding: 20px; }
    #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox {
      border-radius: 8px;
      padding: 30px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox > p {
        top: -50px;
        right: 36px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox::before {
        content: "";
        position: absolute;
        top: 0;
        left: 225px;
        width: 20px;
        height: 100%;
        background-color: #fff; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr th, #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td {
        line-height: 28px;
        font-size: 16px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr th {
        padding: 10px 5px;
        width: 195px;
        text-align: center; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(1) {
        padding: 10.5px 15px 10.5px 22px;
        width: 476px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(2) img {
        margin-right: 10px;
        width: 20px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(3) {
        width: 80px;
        font-size: 14px; }
        #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(3) span {
          font-size: 24px; }
  #PageEducation.pageSupport #Main #ContBox03 {
    padding-bottom: 150px; }
    #PageEducation.pageSupport #Main #ContBox03 .wrapBox .textBox {
      padding-top: 121px; }
      #PageEducation.pageSupport #Main #ContBox03 .wrapBox .textBox .listTypeNumber {
        margin-bottom: 53px; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageSupport #Main #ContBox02 .fillBox {
    margin-bottom: 60px; }
  #PageEducation.pageSupport #Main #ContBox02 .stripeBox {
    border-radius: 6px;
    padding: 10px; }
    #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox {
      border-radius: 6px;
      padding: 15px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox > p {
        top: -18px;
        right: 5px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr th, #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td {
        line-height: 28px;
        font-size: 16px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr th {
        padding: 10px 10px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td {
        line-height: 24px; }
        #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(1) {
          padding: 13px 15px 13px 11px;
          width: 61%; }
        #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(2) img {
          margin-right: 4px;
          width: 16px; }
        #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(3) {
          width: 13%;
          font-size: 10px; }
          #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr td:nth-of-type(3) span {
            font-size: 22px; }
      #PageEducation.pageSupport #Main #ContBox02 .stripeBox .wrapBox .tableTypeBasic tbody tr.border-bottom_none td {
        border-bottom: none; }
  #PageEducation.pageSupport #Main #ContBox03 {
    padding-bottom: 150px; }
    #PageEducation.pageSupport #Main #ContBox03 .wrapBox {
      margin-top: -11px; }
      #PageEducation.pageSupport #Main #ContBox03 .wrapBox .textBox .listTypeNumber {
        margin-bottom: 23px; } }
/*
認定看護師会からメッセージ
-------------------------------------*/
#PageEducation.pageMessage #Main #ContBox01 {
  padding-bottom: 0; }
  #PageEducation.pageMessage #Main #ContBox01 .overviewImg {
    margin-bottom: 0; }
#PageEducation.pageMessage #Main #ContBox02 .wrapBox .textBox .titleTypeBorder {
  margin-top: 0; }

@media print, screen and (min-width: 768px) {
  #PageEducation.pageMessage #Main #ContBox02 .wrapBox .textBox .titleTypeBorder {
    margin-bottom: 20px; }
  #PageEducation.pageMessage #Main #ContBox02 .wrapBox .textBox p:last-of-type {
    margin-bottom: 0; } }
@media print, screen and (max-width: 767px) {
  #PageEducation.pageMessage #Main #ContBox02 .wrapBox .textBox .titleTypeBorder {
    margin-top: 30px;
    margin-bottom: 25px; } }
/*
病棟（部署）紹介　共通
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageIntroduction #MainImg {
    background-image: url("../img/contents/introduction/mainimage.jpg"); }
    #PageIntroduction #MainImg .innerBasic {
      transform: translate(-50%, 66%); }
    #PageIntroduction #MainImg .titleTypeBasic::before {
      width: 684px;
      height: 388px;
      background-image: url("../img/contents/introduction/mainimage_wall.png");
      background-size: 684px auto;
      transform: translate(-50%, -43%); }
    #PageIntroduction #MainImg .titleTypeBasic::after {
      transform: translate(-51%, -56%) rotate(-10deg); } }
@media print, screen and (max-width: 767px) {
  #PageIntroduction #MainImg {
    background-image: url("../img/contents/introduction/mainimage_sp.jpg"); }
    #PageIntroduction #MainImg .innerBasic {
      transform: translate(-50%, -52%); }
    #PageIntroduction #MainImg .titleTypeBasic::before {
      width: 342px;
      height: 194px;
      background-image: url("../img/contents/introduction/mainimage_wall_sp.png");
      background-size: 342px auto;
      transform: translate(-50%, -45%); }
    #PageIntroduction #MainImg .titleTypeBasic::after {
      transform: translate(-53%, -55%) rotate(-10deg); } }
/*
病棟（部署）紹介
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageIntroduction.pageIndex #Main #ContBox01 .innerBasic {
    padding-bottom: 291px; }
  #PageIntroduction.pageIndex #Main #ContBox01::after {
    margin-left: -689px;
    width: 2000px; }
  #PageIntroduction.pageIndex #Main #ContBox02 {
    padding-top: 58px;
    padding-bottom: 90px; }
    #PageIntroduction.pageIndex #Main #ContBox02 .wrapBox {
      margin-bottom: 60px; }
      #PageIntroduction.pageIndex #Main #ContBox02 .wrapBox .textBox .titleTypeBorder {
        margin-top: 60px; }
      #PageIntroduction.pageIndex #Main #ContBox02 .wrapBox .textBox .buttonTypeBasic {
        margin-top: 48px; }
  #PageIntroduction.pageIndex #Main #ContBox03 .titleTypeBorder {
    margin-bottom: 30px; } }
@media print, screen and (max-width: 767px) {
  #PageIntroduction.pageIndex #Main #ContBox01 {
    margin-bottom: -20px; }
    #PageIntroduction.pageIndex #Main #ContBox01::after {
      left: 0;
      margin-left: 16px;
      width: 500px; }
  #PageIntroduction.pageIndex #Main #ContBox02 {
    padding-top: 0;
    padding-bottom: 20px; }
    #PageIntroduction.pageIndex #Main #ContBox02 .wrapBox {
      margin-bottom: 60px; }
      #PageIntroduction.pageIndex #Main #ContBox02 .wrapBox .textBox .titleTypeBorder {
        margin-top: 31px; }
      #PageIntroduction.pageIndex #Main #ContBox02 .wrapBox .textBox .buttonTypeBasic {
        margin-top: -6px; }
  #PageIntroduction.pageIndex #Main #ContBox03 .titleTypeBorder {
    margin-bottom: 20px; } }
/*
多職種チーム・横断活動部署
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageIntroduction.pageCooperation #Main #ContBox01,
  #PageIntroduction.pageEfforts #Main #ContBox01 {
    padding-bottom: 77px; }
    #PageIntroduction.pageCooperation #Main #ContBox01 .innerBasic,
    #PageIntroduction.pageEfforts #Main #ContBox01 .innerBasic {
      padding-top: 10px; }
    #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox,
    #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox {
      margin-bottom: -181px; }
      #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox:not(:first-of-type) .imgBox.right,
      #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox:not(:first-of-type) .imgBox.right {
        margin-top: 30px; }
      #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox:last-of-type,
      #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox:last-of-type {
        margin-bottom: 0; }
      #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox.right .imgWrapBox::after,
      #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox.right .imgWrapBox::after {
        left: 11px; }
      #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox.right .titleTypeBorder,
      #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox.right .titleTypeBorder {
        margin-left: 60px; }
      #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox.right dl,
      #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox.right dl {
        padding-left: 60px; }
      #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox .titleTypeBorder,
      #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox .titleTypeBorder {
        margin-top: 87px;
        margin-bottom: 29px; }
      #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox dl dd,
      #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox dl dd {
        margin-bottom: 19px; }
        #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox dl dd p,
        #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox dl dd p {
          line-height: 32px;
          text-align: left;
          letter-spacing: 0.045em;
          font-size: 16px; } }
@media print, screen and (max-width: 767px) {
  #PageIntroduction.pageCooperation #Main #ContBox01 .innerBasic,
  #PageIntroduction.pageEfforts #Main #ContBox01 .innerBasic {
    padding-top: 42px;
    padding-right: 0;
    padding-left: 0; }
  #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox,
  #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox {
    margin-bottom: 52px;
    padding-bottom: 0; }
    #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox .imgWrapBox::after,
    #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox .imgWrapBox::after {
      right: auto;
      left: 6px; }
    #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox .titleTypeBorder,
    #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox .titleTypeBorder {
      margin-top: 50px;
      margin-bottom: 19px; }
    #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox dl dd,
    #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox dl dd {
      margin-bottom: 11px; }
      #PageIntroduction.pageCooperation #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox dl dd p,
      #PageIntroduction.pageEfforts #Main #ContBox01 .wrapBox.layoutTypeSideDeco .imgBox dl dd p {
        line-height: 28px;
        text-align: left;
        letter-spacing: 0.045em;
        font-size: 16px; } }
/*
多職種チーム
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageIntroduction.pageCooperation #Main {
   			/*#ContBox01{
  				padding-bottom: 77px;
  				.innerBasic{
  					padding-top: 10px;
  				}
  				.wrapBox{
  					margin-bottom: -181px;
  					&:not(:first-of-type){
  						.imgBox.right{
  							margin-top: 30px;
  						}
  					}
  					&:last-of-type{
  						margin-bottom: 0;
  					}
  					&.layoutTypeSideDeco{
  						.imgBox{
  							&.right{
  								.imgWrapBox{
  									&::after{
  										left: 11px;
  									}
  								}
  								.titleTypeBorder{
  									margin-left: 60px;
  								}
  								dl{
  									padding-left: 60px;
  								}
  
  							}
  							.titleTypeBorder{
  								margin-top: 87px;
  								margin-bottom: 29px;
  							}
  							dl{
  								dd{
  									margin-bottom: 19px;
  									p{
  										line-height: 32px;
  										text-align: left;
  										letter-spacing: 0.045em;
  										font-size: 16px;
  									}
  								}
  							}
  						}
  					}
  				}
  			}*/ } }
@media print, screen and (max-width: 767px) {
  #PageIntroduction.pageCooperation #Main {
   			/*#ContBox01{
  				.innerBasic{
  					padding-top: 42px;
  					padding-right: 0;
  					padding-left: 0;
  				}
  				.wrapBox{
  					//padding-right: 0;
  					&.layoutTypeSideDeco{
  						.imgBox{
  							margin-bottom: 52px;
  							padding-bottom: 0;
  							.imgWrapBox{
  								&::after{
  									right: auto;
  									left: 6px;
  								}
  							}
  							.titleTypeBorder{
  								margin-top: 50px;
  								margin-bottom: 19px;
  							}
  							dl{
  								dd{
  									margin-bottom: 11px;
  									p{
  										line-height: 28px;
  										text-align: left;
  										letter-spacing: 0.045em;
  										font-size: 16px;
  									}
  								}
  							}
  						}
  					}
  				}
  			}*/ } }
/*
横断活動部署
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageIntroduction.pageEfforts {
    		/*#Main{
     			#ContBox01{
    				&::after{
    					width: 1000px;
    				}
    			}
    		}*/ } }
@media print, screen and (max-width: 767px) {
  #PageIntroduction.pageEfforts {
    		/*#Main{
     			#ContBox01{
    				padding-bottom: 107px;
    				&::after{
    					left: 0;
    					margin-left: 116px;
    					width: 1000px;
    				}
    			}
    		}*/ } }
/*
病棟・センター等紹介
-------------------------------------*/
/*
病棟・センター等紹介（詳細）
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageIntroduction.pageEntry #Main #ContBox01 {
    padding-bottom: 10px; }
  #PageIntroduction.pageEntry #Main #ContBox02 .titleTypeBubble {
    margin: -27px auto 19px;
    width: 155px; }
  #PageIntroduction.pageEntry #Main #ContBox02 .borderBox {
    padding-top: 23px;
    padding-bottom: 23px; }
  #PageIntroduction.pageEntry #Main #ContBox02 .titleTypeBorder {
    margin-top: 59px; }
    #PageIntroduction.pageEntry #Main #ContBox02 .titleTypeBorder + .textBox {
      line-height: 32px;
      font-size: 16px; } }
@media print, screen and (max-width: 767px) {
  #PageIntroduction.pageEntry #Main #ContBox01 {
    padding-bottom: 8px; }
  #PageIntroduction.pageEntry #Main #ContBox02 .titleTypeBubble {
    margin-top: -9px;
    margin-bottom: 19px;
    padding-top: 2px;
    padding-bottom: 2px; }
  #PageIntroduction.pageEntry #Main #ContBox02 .borderBox {
    margin-bottom: 30px;
    padding-top: 15px;
    padding-bottom: 15px; }
  #PageIntroduction.pageEntry #Main #ContBox02 .titleTypeBorder {
    margin-top: 34px;
    margin-bottom: 18px; }
    #PageIntroduction.pageEntry #Main #ContBox02 .titleTypeBorder + .textBox {
      line-height: 28px;
      font-size: 16px; } }
/*
看護師募集要項・試験日程　共通
-------------------------------------*/
#PageInformation #MainImg .titleTypeBasic::before {
  display: none; }

@media print, screen and (min-width: 768px) {
  #PageInformation #MainImg {
    background-image: url("../img/contents/information/mainimage.jpg"); }
    #PageInformation #MainImg #MainImgInner {
      top: 62%; }
      #PageInformation #MainImg #MainImgInner .titleTypeBasic::after {
        top: 18%;
        left: 49%; } }
@media print, screen and (max-width: 767px) {
  #PageInformation #MainImg {
    background-image: url("../img/contents/information/mainimage_sp.jpg"); } }
/*
看護師募集要項・試験日程
-------------------------------------*/
#PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton01 {
  padding-bottom: 0; }
#PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 {
  padding: 0; }
#PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton03 {
  padding: 0; }
#PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton04 {
  padding-top: 0; }

@media print, screen and (min-width: 768px) {
  #PageInformation.pageIndex #Main #ContBox01 .stripeBox {
    padding: 0;
    margin-top: 150px; }
    #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li {
      margin-top: 0;
      width: 235px; }
      #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li:nth-of-type(2n) {
        margin-right: 20px; }
      #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li:nth-of-type(2n+1) {
        clear: none; }
      #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li:nth-of-type(4n) {
        margin-right: 0; }
      #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li a {
        font-size: 16px; }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li a span {
          padding-left: 58px;
          min-height: 60px; }
          #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li a span > b {
            display: inline;
            min-height: auto;
            font-size: 14px; }
    #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton03 li {
      width: 100%; }
      #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton03 li a {
        font-size: 16px; }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton03 li a::before {
          left: 50%;
          margin-left: -58px; }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton03 li a span {
          justify-content: center;
          padding-left: 35px;
          min-height: 60px; }
    #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton04 li a::after {
      top: auto;
      bottom: 25px;
      transform: translateY(0); }
    #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton04 li a .textBox {
      padding: 15px 0;
      text-align: center; } }
@media print, screen and (max-width: 767px) {
  #PageInformation.pageIndex #Main #ContBox01 .stripeBox {
    margin-top: 53px; }
    #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton01 {
      margin-bottom: 15px; }
      #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton01 li a span {
        justify-content: center;
        text-align: center;
        font-size: 20px; }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton01 li a span b {
          position: relative;
          display: inline-block; }
          #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton01 li a span b::before {
            content: "";
            position: absolute;
            top: 50%;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            transform: translateY(-50%); }
    #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 {
      display: flex;
      flex-wrap: wrap; }
      #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02::after {
        content: "";
        display: block;
        clear: both; }
      #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li {
        margin-top: 0;
        margin-bottom: 15px;
        width: calc((100% - 15px)/2); }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li:nth-of-type(2n+1) {
          margin-right: 15px; }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li:nth-of-type(4n) {
          margin-right: 0; }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li a {
          padding: 10px 0 10px;
          min-height: 95px;
          font-size: 16px; }
          #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li a::before {
            position: static;
            display: block;
            transform: translateY(0);
            margin: 0 auto 6px; }
          #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li a span {
            display: block;
            padding-left: 0;
            min-height: auto;
            line-height: 20px;
            text-align: center; }
            #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li a span > b {
              display: block;
              min-height: auto;
              font-size: 14px; }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li.icon_05 a {
          padding-top: 27px; }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li.icon_06 a {
          padding-top: 14px; }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li.icon_07 a {
          padding-top: 27px; }
        #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton02 li.icon_08 a {
          padding-top: 14px; }
    #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton03 {
      margin-bottom: 15px; }
      #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton03 li a span {
        min-height: 70px; }
    #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton04 li a::after {
      top: auto;
      bottom: 25px;
      transform: translateY(0); }
    #PageInformation.pageIndex #Main #ContBox01 .stripeBox .listTypeButton.listTypeButton04 li a .textBox {
      padding: 15px 0;
      text-align: center;
      font-size: 20px; } }
/*
看護師募集要項・試験日程
-------------------------------------*/
#PageInformation.pageExam #Main #ContBox01 {
  padding-bottom: 0; }

@media print, screen and (min-width: 768px) {
  #PageInformation.pageExam #Main #ContBox02 .tableTypeBasic tbody tr td .tableTypeBasic tbody tr th {
    width: 185px; }
  #PageInformation.pageExam #Main #ContBox02 .tableTypeBasic tbody tr th {
    width: 200px; }
  #PageInformation.pageExam #Main #ContBox03 .borderBox {
    padding-top: 29px;
    padding-bottom: 36px; }
    #PageInformation.pageExam #Main #ContBox03 .borderBox .titleTypeBasic {
      line-height: 38px; } }
@media print, screen and (max-width: 767px) {
  #PageInformation.pageExam #Main #ContBox02 .titleTypePoint {
    margin-bottom: 43px; }
  #PageInformation.pageExam #Main #ContBox02 .tableTypeBasic td {
    line-height: 24px; }
  #PageInformation.pageExam #Main #ContBox02 .tableTypeBasic.tableTypeOverlap tbody tr td .tableTypeBasic tbody tr th {
    width: 85px; }
  #PageInformation.pageExam #Main #ContBox03 .borderBox {
    margin-bottom: 45px; } }
/*
看護師正職員募集要項
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageInformation.pageRegular #Main #ContBox01 {
    padding-bottom: 40px; }
  #PageInformation.pageRegular #Main #ContBox02 .txtTypeRight {
    margin-top: -30px; }
  #PageInformation.pageRegular #Main #ContBox02 .tableTypeBasic.tableTypeSubtitle tbody td {
    text-align: center; } }
@media print, screen and (max-width: 767px) {
  #PageInformation.pageRegular #Main #ContBox01 {
    padding-bottom: 40px; }
  #PageInformation.pageRegular #Main #ContBox02 .tableTypeBasic.tableTypeSubtitle tbody tr td:nth-of-type(2), #PageInformation.pageRegular #Main #ContBox02 .tableTypeBasic.tableTypeSubtitle tbody tr td:nth-of-type(3) {
    min-height: 66px; }
    #PageInformation.pageRegular #Main #ContBox02 .tableTypeBasic.tableTypeSubtitle tbody tr td:nth-of-type(2)::before, #PageInformation.pageRegular #Main #ContBox02 .tableTypeBasic.tableTypeSubtitle tbody tr td:nth-of-type(3)::before {
      min-height: 65px; }
    #PageInformation.pageRegular #Main #ContBox02 .tableTypeBasic.tableTypeSubtitle tbody tr td:nth-of-type(2) .inner, #PageInformation.pageRegular #Main #ContBox02 .tableTypeBasic.tableTypeSubtitle tbody tr td:nth-of-type(3) .inner {
      min-height: 66px; }
  #PageInformation.pageRegular #Main #ContBox02 .txtTypeRight {
    margin-top: -30px; } }
/*
看護師正職員（日勤のみ）募集要項
看護師夜勤専従募集要項
看護師臨時職員（パート職員）
-------------------------------------*/
#PageInformation.pageDay-shift #Main #ContBox02,
#PageInformation.pageNight-shift #Main #ContBox02,
#PageInformation.pagePart #Main #ContBox02 {
  margin-top: 0; }

@media print, screen and (min-width: 768px) {
  #PageInformation.pageDay-shift #Main #ContBox01,
  #PageInformation.pageNight-shift #Main #ContBox01,
  #PageInformation.pagePart #Main #ContBox01 {
    padding-bottom: 40px; }
  #PageInformation.pageDay-shift #Main #ContBox02,
  #PageInformation.pageNight-shift #Main #ContBox02,
  #PageInformation.pagePart #Main #ContBox02 {
    padding-bottom: 120px; } }
@media print, screen and (max-width: 767px) {
  #PageInformation.pageDay-shift #Main #ContBox01,
  #PageInformation.pageNight-shift #Main #ContBox01,
  #PageInformation.pagePart #Main #ContBox01 {
    padding-bottom: 40px; }
  #PageInformation.pageDay-shift #Main #ContBox02,
  #PageInformation.pageNight-shift #Main #ContBox02,
  #PageInformation.pagePart #Main #ContBox02 {
    padding-bottom: 120px; } }
/*
看護師正職員（日勤のみ）募集要項
-------------------------------------*/
/*
看護師夜勤専従募集要項
-------------------------------------*/
/*
看護師臨時職員（パート職員）
-------------------------------------*/
/*
旭中央病院インターンシップ
-------------------------------------*/
#PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dd {
  margin-bottom: 0;
  border-radius: 5px;
  padding-right: 0;
  padding-left: 0;
  width: 98px;
  height: 26px;
  text-align: center;
  font-weight: bold;
  font-size: 16px; }
  #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dd::after {
    display: none; }
  #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dd::before {
    right: auto;
    display: inline-block;
    border: none;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url("../img/contents/arrow_06_y.png");
    background-size: 27px; }
#PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox {
  background-color: #fff; }
  #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox:last-of-type {
    margin-bottom: 0; }
  #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .textBox .titleTypeBasic {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    margin-bottom: 0;
    text-align: left;
    font-size: 28px; }
    #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .textBox .titleTypeBasic b {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      border-radius: 50%;
      padding-top: 12px;
      width: 67px;
      height: 67px;
      background-color: #ffeff6;
      line-height: 1;
      text-align: center;
      font-size: 12px;
      color: #ff7bae; }
      #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .textBox .titleTypeBasic b span {
        display: block;
        margin-top: 2px;
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        font-size: 28px; }
  #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .tableTypeBasic {
    margin-bottom: 0; }

@media print, screen and (min-width: 768px) {
  #PageInformation.pageInternship #Main #ContBox01 .titleTypeBasic[data-subtitle] {
    margin-bottom: -1px; }
    #PageInformation.pageInternship #Main #ContBox01 .titleTypeBasic[data-subtitle] + p {
      margin-bottom: 43px; }
      #PageInformation.pageInternship #Main #ContBox01 .titleTypeBasic[data-subtitle] + p + .titleTypeBasic {
        margin-bottom: 23px;
        line-height: 40px;
        font-size: 24px; }
  #PageInformation.pageInternship #Main #ContBox01 .listTypecheck {
    margin: 10px auto 10px;
    width: 628px; }
    #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li {
      padding-left: 33px;
      margin-bottom: 13px; }
      #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li::before {
        top: 2px;
        left: -6px; }
      #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dt, #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dd {
        display: inline-block;
        vertical-align: middle; }
      #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dt {
        width: 490px;
        font-size: 18px; }
      #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dd::before {
        left: -43px;
        width: 45px;
        height: 26px; }
  #PageInformation.pageInternship #Main #ContBox02 {
    margin-top: -134px; }
    #PageInformation.pageInternship #Main #ContBox02 .innerBasic {
      padding-bottom: 150px; }
  #PageInformation.pageInternship #Main #ContBox03 .stripeBox {
    margin-top: -28px;
    margin-bottom: 40px;
    border-radius: 8px;
    padding: 20px; }
    #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox {
      margin-bottom: 10px;
      border-radius: 8px;
      padding: 20px; }
      #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox:last-of-type .imgBox {
        margin-bottom: 20px; }
      #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .textBox {
        width: 720px; }
        #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .textBox .titleTypeBasic {
          padding-left: 83px;
          min-height: 120px;
          font-size: 28px; }
          #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .textBox .titleTypeBasic b {
            left: -6px; }
      #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .imgBox {
        width: 180px; }
      #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .tableTypeBasic thead th:first-of-type {
        width: 356px; } }
@media print, screen and (max-width: 767px) {
  #PageInformation.pageInternship #Main #ContBox01 .titleTypeBasic[data-subtitle] {
    margin-bottom: 14px; }
    #PageInformation.pageInternship #Main #ContBox01 .titleTypeBasic[data-subtitle] + p {
      margin-bottom: 29px; }
      #PageInformation.pageInternship #Main #ContBox01 .titleTypeBasic[data-subtitle] + p + .titleTypeBasic {
        margin-bottom: 22px;
        line-height: 28px;
        font-size: 18px; }
  #PageInformation.pageInternship #Main #ContBox01 .listTypecheck {
    margin: 0 auto 10px; }
    #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li {
      padding-left: 40px;
      margin-bottom: 11px; }
      #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li::before {
        top: 2px;
        left: 0; }
      #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl {
        text-align: right; }
        #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dt, #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dd {
          vertical-align: middle; }
        #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dt {
          line-height: 28px;
          text-align: left;
          font-size: 18px; }
        #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dd {
          display: inline-block; }
          #PageInformation.pageInternship #Main #ContBox01 .listTypecheck li dl dd::before {
            left: -33px;
            width: 45px;
            height: 26px; }
  #PageInformation.pageInternship #Main #ContBox02 {
    margin-top: -64px; }
    #PageInformation.pageInternship #Main #ContBox02 .innerBasic {
      padding-bottom: 32px; }
  #PageInformation.pageInternship #Main #ContBox03 .stripeBox {
    margin-top: -11px;
    margin-bottom: 40px;
    border-radius: 6px;
    padding: 10px; }
    #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox {
      margin-bottom: 5px;
      padding: 15px;
      border-radius: 6px; }
      #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox:last-of-type .imgBox {
        margin-bottom: 30px; }
      #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .textBox {
        margin-bottom: 10px; }
        #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .textBox .titleTypeBasic {
          padding-left: 75px;
          min-height: 67px;
          line-height: 28px;
          font-size: 18px; }
          #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .textBox .titleTypeBasic b {
            left: 0; }
      #PageInformation.pageInternship #Main #ContBox03 .stripeBox .wrapBox .tableTypeBasic thead th:first-of-type {
        width: 150px; } }
/*
看護師募集についてのよくある質問
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageInformation.pageFaq #Main #ContBox02 {
    margin-top: -295px; }
    #PageInformation.pageFaq #Main #ContBox02 .innerBasic {
      padding-bottom: 120px; } }
@media print, screen and (max-width: 767px) {
  #PageInformation.pageFaq #Main #ContBox02 {
    margin-top: -121px; }
    #PageInformation.pageFaq #Main #ContBox02 .innerBasic {
      padding-bottom: 120px; } }
/*
福利厚生
-------------------------------------*/
#PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic {
  border-top: none;
  border-bottom: none; }
  #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td {
    background-color: transparent;
    font-size: 16px; }
  #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr th {
    padding-left: 0; }
  #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure {
    margin-bottom: 0; }
    #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure li, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure li, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure li, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure li {
      text-align: center; }
      #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure li img, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure li img, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure li img, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure li img {
        margin-bottom: 2px; }
  #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr:last-of-type th, #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr:last-of-type td, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr:last-of-type th, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr:last-of-type td, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr:last-of-type th, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr:last-of-type td, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr:last-of-type th, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr:last-of-type td {
    border-bottom: none; }
#PageInformation.pageWelfare #Main #ContBox02 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple, #PageInformation.pageWelfare #Main #ContBox03 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple, #PageInformation.pageWelfare #Main #ContBox04 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple, #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple {
  margin-bottom: 0;
  font-size: 16px;
  color: #f54992; }
#PageInformation.pageWelfare #Main #ContBox02 .listFigure::after, #PageInformation.pageWelfare #Main #ContBox03 .listFigure::after, #PageInformation.pageWelfare #Main #ContBox04 .listFigure::after, #PageInformation.pageWelfare #Main #ContBox05 .listFigure::after {
  content: "";
  display: block;
  clear: both; }
#PageInformation.pageWelfare #Main #ContBox02 .listFigure li, #PageInformation.pageWelfare #Main #ContBox03 .listFigure li, #PageInformation.pageWelfare #Main #ContBox04 .listFigure li, #PageInformation.pageWelfare #Main #ContBox05 .listFigure li {
  display: block;
  float: left; }
  #PageInformation.pageWelfare #Main #ContBox02 .listFigure li:nth-of-type(3n), #PageInformation.pageWelfare #Main #ContBox03 .listFigure li:nth-of-type(3n), #PageInformation.pageWelfare #Main #ContBox04 .listFigure li:nth-of-type(3n), #PageInformation.pageWelfare #Main #ContBox05 .listFigure li:nth-of-type(3n) {
    margin-right: 0; }
  #PageInformation.pageWelfare #Main #ContBox02 .listFigure li img, #PageInformation.pageWelfare #Main #ContBox03 .listFigure li img, #PageInformation.pageWelfare #Main #ContBox04 .listFigure li img, #PageInformation.pageWelfare #Main #ContBox05 .listFigure li img {
    width: 100%;
    height: auto; }
#PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic {
  margin-bottom: 0; }
  #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic tbody tr td {
    font-size: 14px; }
  #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic tbody tr th {
    vertical-align: top; }
#PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic01 {
  padding-bottom: 41px; }
#PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 {
  padding-top: 0; }
  #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .titleTypeBorder {
    margin-top: 0; }
    #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .titleTypeBorder span {
      display: block; }
#PageInformation.pageWelfare #Main #ContBox05 .listTypeButton li a {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../img/contents/information/welfare/logo_01.jpg"); }
  #PageInformation.pageWelfare #Main #ContBox05 .listTypeButton li a span {
    text-indent: -9999px; }

@media print, screen and (min-width: 768px) {
  #PageInformation.pageWelfare #Main #ContBox01 {
    padding-top: 164px;
    padding-bottom: 252px;
    background-repeat: no-repeat;
    background-position: center 106px;
    background-image: url("../img/contents/information/welfare/wall_01.png");
    background-size: 1380px auto; }
    #PageInformation.pageWelfare #Main #ContBox01::after {
      margin-left: -142px; }
  #PageInformation.pageWelfare #Main #ContBox02 .fillBox, #PageInformation.pageWelfare #Main #ContBox03 .fillBox, #PageInformation.pageWelfare #Main #ContBox04 .fillBox, #PageInformation.pageWelfare #Main #ContBox05 .fillBox {
    margin-bottom: 148px;
    padding-top: 22px;
    padding-bottom: 22px; }
    #PageInformation.pageWelfare #Main #ContBox02 .fillBox .listTypeTable, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .listTypeTable, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .listTypeTable, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .listTypeTable {
      margin: 0 auto;
      width: 1000px; }
    #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic {
      margin: 0 auto;
      width: 1000px; }
      #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td {
        padding-top: 22px;
        padding-right: 0;
        padding-bottom: 22px;
        line-height: 32px; }
      #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr th {
        width: 162px;
        padding-left: 0; }
      #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure {
        margin-top: -11px;
        margin-bottom: 0; }
        #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure li, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure li, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure li, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure li {
          margin-right: 38px;
          width: 248px;
          font-size: 14px; }
          #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(3n), #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(3n), #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(3n), #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(3n) {
            margin-right: 0; }
          #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(3n+1), #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(3n+1), #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(3n+1), #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(3n+1) {
            clear: both; }
  #PageInformation.pageWelfare #Main #ContBox02 .wrapBox.layoutTypeSideDeco .imgWrapBox::before, #PageInformation.pageWelfare #Main #ContBox03 .wrapBox.layoutTypeSideDeco .imgWrapBox::before, #PageInformation.pageWelfare #Main #ContBox04 .wrapBox.layoutTypeSideDeco .imgWrapBox::before, #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .imgWrapBox::before {
    top: -120px;
    height: calc(100% + 300px); }
  #PageInformation.pageWelfare #Main #ContBox02 .wrapBox.layoutTypeSideDeco .textBox, #PageInformation.pageWelfare #Main #ContBox03 .wrapBox.layoutTypeSideDeco .textBox, #PageInformation.pageWelfare #Main #ContBox04 .wrapBox.layoutTypeSideDeco .textBox, #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .textBox {
    margin-top: -114px; }
    #PageInformation.pageWelfare #Main #ContBox02 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple, #PageInformation.pageWelfare #Main #ContBox03 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple, #PageInformation.pageWelfare #Main #ContBox04 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple, #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple {
      margin-top: 27px;
      line-height: 32px; }
  #PageInformation.pageWelfare #Main #ContBox02 .innerBasic, #PageInformation.pageWelfare #Main #ContBox03 .innerBasic, #PageInformation.pageWelfare #Main #ContBox04 .innerBasic, #PageInformation.pageWelfare #Main #ContBox05 .innerBasic {
    padding-top: 28px;
    width: 760px; }
  #PageInformation.pageWelfare #Main #ContBox02 .listFigure, #PageInformation.pageWelfare #Main #ContBox03 .listFigure, #PageInformation.pageWelfare #Main #ContBox04 .listFigure, #PageInformation.pageWelfare #Main #ContBox05 .listFigure {
    margin-bottom: 61px; }
    #PageInformation.pageWelfare #Main #ContBox02 .listFigure li, #PageInformation.pageWelfare #Main #ContBox03 .listFigure li, #PageInformation.pageWelfare #Main #ContBox04 .listFigure li, #PageInformation.pageWelfare #Main #ContBox05 .listFigure li {
      margin-right: 35px;
      width: 230px; }
  #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic {
    margin-bottom: 0; }
    #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px; }
    #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic tbody tr th {
      letter-spacing: .15em;
      width: 135px; }
  #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic01 {
    padding-bottom: 90px; }
  #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 {
    padding-top: 0;
    width: 1000px; }
    #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox {
      float: left;
      margin-right: 50px;
      width: 300px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column; }
      #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox:nth-of-type(3n) {
        margin-right: 0; }
      #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .titleTypeBorder {
        order: 1;
        margin-bottom: 28px;
        padding-top: 8px;
        padding-bottom: 3px;
        font-size: 24px; }
        #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .titleTypeBorder span {
          font-size: 16px; }
      #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .imgWrapBox {
        order: 2;
        margin-bottom: 41px;
        width: 290px; }
        #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .imgWrapBox::after {
          left: 11px; }
      #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .tableTypeBasic {
        order: 3; }
        #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .tableTypeBasic tbody tr th {
          width: 105px; }
  #PageInformation.pageWelfare #Main #ContBox05 {
    padding-bottom: 165px; }
    #PageInformation.pageWelfare #Main #ContBox05 .wrapBox .titleTypeBorder {
      margin: 0 auto 0;
      width: 1000px; }
    #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .imgBox {
      float: none;
      display: block;
      margin: 0 auto;
      padding-bottom: 53px;
      min-width: 1200px;
      max-width: 1380px;
      width: 100%; }
    #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .imgWrapBox::before {
      top: -168px;
      width: calc(50vw - 87px);
      height: calc(100% + 168px + 121px); }
    #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .textBox {
      float: none;
      margin: 0 auto 0;
      padding-top: 0;
      width: 760px; }
    #PageInformation.pageWelfare #Main #ContBox05 .listTypeButton {
      margin-top: 51px; }
      #PageInformation.pageWelfare #Main #ContBox05 .listTypeButton li {
        float: none;
        margin: 0 auto;
        width: 520px; }
        #PageInformation.pageWelfare #Main #ContBox05 .listTypeButton li a {
          background-size: 320px auto; } }
@media print, screen and (max-width: 767px) {
  #PageInformation.pageWelfare #Main #ContBox01 {
    padding-bottom: 118px; }
    #PageInformation.pageWelfare #Main #ContBox01 .titleTypeBasic + p::after {
      content: "";
      display: block;
      margin: 6px auto 0;
      width: 291px;
      height: 141px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 291px auto;
      background-image: url("../img/contents/information/welfare/wall_01_sp.png"); }
  #PageInformation.pageWelfare #Main #ContBox02 .titleTypePoint, #PageInformation.pageWelfare #Main #ContBox03 .titleTypePoint, #PageInformation.pageWelfare #Main #ContBox04 .titleTypePoint, #PageInformation.pageWelfare #Main #ContBox05 .titleTypePoint {
    margin-bottom: 20px; }
  #PageInformation.pageWelfare #Main #ContBox02 .fillBox, #PageInformation.pageWelfare #Main #ContBox03 .fillBox, #PageInformation.pageWelfare #Main #ContBox04 .fillBox, #PageInformation.pageWelfare #Main #ContBox05 .fillBox {
    margin-bottom: 62px;
    line-height: 28px;
    font-size: 16px; }
    #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td {
      line-height: 28px; }
    #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr th {
      padding: 14px 0 0;
      width: 100%; }
    #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td {
      padding: 0 0 13px; }
      #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure {
        margin-top: -23px;
        margin-bottom: 0;
        text-align: center;
        font-size: 0; }
        #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure li, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure li, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure li, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure li {
          float: none;
          display: inline-block;
          margin-bottom: 10px;
          vertical-align: top;
          width: calc((100% - 16px) / 2);
          line-height: 18px;
          font-size: 12px; }
          #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(2n+1), #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(2n+1), #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(2n+1), #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(2n+1) {
            margin-right: 7.5px; }
          #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(2n), #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(2n), #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(2n), #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure li:nth-of-type(2n) {
            margin-right: 0;
            margin-left: 7.5px; }
          #PageInformation.pageWelfare #Main #ContBox02 .fillBox .tableTypeBasic tbody tr td .listFigure li img, #PageInformation.pageWelfare #Main #ContBox03 .fillBox .tableTypeBasic tbody tr td .listFigure li img, #PageInformation.pageWelfare #Main #ContBox04 .fillBox .tableTypeBasic tbody tr td .listFigure li img, #PageInformation.pageWelfare #Main #ContBox05 .fillBox .tableTypeBasic tbody tr td .listFigure li img {
            margin-bottom: 8px; }
  #PageInformation.pageWelfare #Main #ContBox02 .wrapBox.layoutTypeSideDeco .imgBox, #PageInformation.pageWelfare #Main #ContBox03 .wrapBox.layoutTypeSideDeco .imgBox, #PageInformation.pageWelfare #Main #ContBox04 .wrapBox.layoutTypeSideDeco .imgBox, #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .imgBox {
    margin-bottom: 30px; }
  #PageInformation.pageWelfare #Main #ContBox02 .wrapBox.layoutTypeSideDeco .textBox .titleTypeBorder, #PageInformation.pageWelfare #Main #ContBox03 .wrapBox.layoutTypeSideDeco .textBox .titleTypeBorder, #PageInformation.pageWelfare #Main #ContBox04 .wrapBox.layoutTypeSideDeco .textBox .titleTypeBorder, #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .textBox .titleTypeBorder {
    margin-top: 0;
    margin-bottom: 20px; }
  #PageInformation.pageWelfare #Main #ContBox02 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple, #PageInformation.pageWelfare #Main #ContBox03 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple, #PageInformation.pageWelfare #Main #ContBox04 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple, #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .textBox .titleTypeSinple {
    margin-top: 27px;
    margin-bottom: -2px;
    line-height: 32px; }
  #PageInformation.pageWelfare #Main #ContBox02 .innerBasic, #PageInformation.pageWelfare #Main #ContBox03 .innerBasic, #PageInformation.pageWelfare #Main #ContBox04 .innerBasic, #PageInformation.pageWelfare #Main #ContBox05 .innerBasic {
    padding-bottom: 75px; }
  #PageInformation.pageWelfare #Main #ContBox02 .listFigure, #PageInformation.pageWelfare #Main #ContBox03 .listFigure, #PageInformation.pageWelfare #Main #ContBox04 .listFigure, #PageInformation.pageWelfare #Main #ContBox05 .listFigure {
    margin-bottom: 29px; }
    #PageInformation.pageWelfare #Main #ContBox02 .listFigure li, #PageInformation.pageWelfare #Main #ContBox03 .listFigure li, #PageInformation.pageWelfare #Main #ContBox04 .listFigure li, #PageInformation.pageWelfare #Main #ContBox05 .listFigure li {
      margin-right: 7px;
      width: calc((100% - 15px) / 3); }
  #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic {
    margin-bottom: 0; }
    #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic tbody tr td, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic tbody tr td {
      padding-top: 15px;
      padding-bottom: 15px;
      line-height: 24px; }
    #PageInformation.pageWelfare #Main #ContBox02 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox03 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox04 .tableTypeBasic tbody tr th, #PageInformation.pageWelfare #Main #ContBox05 .tableTypeBasic tbody tr th {
      padding-right: 0;
      padding-left: 10px;
      letter-spacing: .15em;
      width: 75px; }
  #PageInformation.pageWelfare #Main #ContBox02 .fillBox {
    padding: 17px 8px 17px; }
  #PageInformation.pageWelfare #Main #ContBox03 .fillBox {
    padding: 10px 20px; }
  #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic01 {
    padding-bottom: 45px; }
  #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 {
    padding-top: 0; }
    #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox {
      margin-bottom: 45px; }
      #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox::after {
        content: "";
        display: block;
        clear: both; }
      #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox:last-of-type {
        margin-bottom: 0; }
      #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .titleTypeBorder {
        float: right;
        margin-top: 5px;
        margin-bottom: 19px;
        padding-top: 8px;
        padding-bottom: 8px;
        width: calc(100% - 164px);
        line-height: 24px;
        font-size: 18px; }
        #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .titleTypeBorder span {
          font-size: 14px; }
      #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .imgWrapBox {
        float: left;
        margin-bottom: 19px;
        width: 145px; }
        #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .imgWrapBox::after {
          top: 5px;
          left: 5px; }
      #PageInformation.pageWelfare #Main #ContBox04 .innerBasic.innerBasic02 .wrapBox .textBox .tableTypeBasic tbody tr th {
        width: 75px; }
  #PageInformation.pageWelfare #Main #ContBox05 {
    padding-bottom: 100px; }
    #PageInformation.pageWelfare #Main #ContBox05 .fillBox {
      padding: 10px 20px;
      margin-bottom: 40px; }
    #PageInformation.pageWelfare #Main #ContBox05 .wrapBox .titleTypeBorder {
      margin-bottom: 13px; }
    #PageInformation.pageWelfare #Main #ContBox05 .wrapBox.layoutTypeSideDeco .imgBox {
      margin-bottom: 24px; }
    #PageInformation.pageWelfare #Main #ContBox05 .listTypeButton {
      margin-top: -6px; }
      #PageInformation.pageWelfare #Main #ContBox05 .listTypeButton li a {
        background-size: 242px auto; } }
/*
奨学金制度
-------------------------------------*/
#PageInformation.pageScholarship #Main #ContBox01 {
  padding-bottom: 0; }
  #PageInformation.pageScholarship #Main #ContBox01 .overviewImg {
    margin-bottom: 0; }
    #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox {
      position: relative;
      left: 0;
      background-color: #fff;
      transform: translateY(0);
      width: 100%; }
      #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox p {
        text-align: left;
        letter-spacing: .05em;
        font-size: 16px; }

@media print, screen and (min-width: 768px) {
  #PageInformation.pageScholarship #Main #ContBox01 .overviewImg {
    padding-bottom: 30px; }
    #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox {
      top: -60px;
      padding: 52px 57px 81px; }
      #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .titleTypePoint {
        margin-bottom: 51px;
        font-size: 24px; }
        #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .titleTypePoint::after {
          margin-top: 14px; }
      #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox p {
        line-height: 32px; }
      #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .listTypeNumber, #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .listTypeCircle {
        margin-bottom: 32px; }
        #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .listTypeNumber li, #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .listTypeCircle li {
          margin-bottom: 8px; } }
@media print, screen and (max-width: 767px) {
  #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox {
    top: -30px;
    padding: 23px 19px 18px; }
    #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .titleTypePoint {
      margin-bottom: 24px;
      line-height: 28px;
      font-size: 18px; }
      #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .titleTypePoint::after {
        margin-top: 9px; }
    #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .listTypeNumber, #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .listTypeCircle {
      margin-bottom: 32px; }
      #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .listTypeNumber li, #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .listTypeCircle li {
        margin-bottom: 16px; }
      #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .listTypeNumber + p, #PageInformation.pageScholarship #Main #ContBox01 .overviewImg .wrapBox .textBox .listTypeCircle + p {
        margin-top: -17px; } }
/*
看護局だより　共通
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageBlog #MainImg {
    background-image: url("../img/contents/blog/mainimage.jpg"); }
    #PageBlog #MainImg .innerBasic {
      transform: translate(-50%, 66%); }
    #PageBlog #MainImg .titleTypeBasic::before {
      width: 636px;
      height: 323px;
      background-image: url("../img/contents/blog/mainimage_wall.png");
      background-size: 636px auto;
      transform: translate(-50%, -55%); }
    #PageBlog #MainImg .titleTypeBasic::after {
      transform: translate(-51%, -56%) rotate(-10deg); } }
@media print, screen and (max-width: 767px) {
  #PageBlog #MainImg {
    background-image: url("../img/contents/blog/mainimage_sp.jpg"); }
    #PageBlog #MainImg .innerBasic {
      transform: translate(-50%, -52%); }
    #PageBlog #MainImg .titleTypeBasic::before {
      width: 318px;
      height: 176px;
      background-image: url("../img/contents/blog/mainimage_wall_sp.png");
      background-size: 318px auto;
      transform: translate(-50%, -40%); }
    #PageBlog #MainImg .titleTypeBasic::after {
      transform: translate(-53%, -55%) rotate(-10deg); } }
/*
看護局だより
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageBlog.pageIndex #Main #ContBox01 {
    padding-top: 90px;
    padding-bottom: 150px; } }
/*
看護局だより（カテゴリアーカイブ）
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageBlog.pageCategory #Main #ContBox01 {
    padding-top: 132px;
    padding-bottom: 150px; }
    #PageBlog.pageCategory #Main #ContBox01 .titleTypeBasic {
      margin-bottom: 51px; } }
/*
記事詳細共通
-------------------------------------*/
#PageBlog.pageEntry #Main #ContBox01 .date, #PageRecruit.pageEntry #Main #ContBox01 .date {
  display: block; }
#PageBlog.pageEntry #Main #ContBox01 .titleTypeBasic, #PageRecruit.pageEntry #Main #ContBox01 .titleTypeBasic {
  text-align: left; }

@media print, screen and (min-width: 768px) {
  #PageBlog.pageEntry #Main #ContBox01, #PageRecruit.pageEntry #Main #ContBox01 {
    margin-top: 90px; }
    #PageBlog.pageEntry #Main #ContBox01 .innerBasic, #PageRecruit.pageEntry #Main #ContBox01 .innerBasic {
      padding-top: 5px;
      width: 760px; }
    #PageBlog.pageEntry #Main #ContBox01 .date, #PageRecruit.pageEntry #Main #ContBox01 .date {
      margin-bottom: 13px; }
    #PageBlog.pageEntry #Main #ContBox01 .titleTypeBasic, #PageRecruit.pageEntry #Main #ContBox01 .titleTypeBasic {
      margin-bottom: 49px;
      font-size: 28px; }
    #PageBlog.pageEntry #Main #ContBox01 .entryBox, #PageRecruit.pageEntry #Main #ContBox01 .entryBox {
      padding: 56px 0 30px; } }
@media print, screen and (max-width: 767px) {
  #PageBlog.pageEntry #Main #ContBox01, #PageRecruit.pageEntry #Main #ContBox01 {
    margin-top: 90px;
    padding-bottom: 75px; }
    #PageBlog.pageEntry #Main #ContBox01 .innerBasic, #PageRecruit.pageEntry #Main #ContBox01 .innerBasic {
      padding: 0 15px 10px; }
    #PageBlog.pageEntry #Main #ContBox01 .date, #PageRecruit.pageEntry #Main #ContBox01 .date {
      margin-bottom: -2px; }
    #PageBlog.pageEntry #Main #ContBox01 .titleTypeBasic, #PageRecruit.pageEntry #Main #ContBox01 .titleTypeBasic {
      margin-bottom: 38px;
      line-height: 30px;
      font-size: 20px; }
    #PageBlog.pageEntry #Main #ContBox01 .entryBox, #PageRecruit.pageEntry #Main #ContBox01 .entryBox {
      padding: 38px 0 30px; } }
/*
看護局だより（記事詳細）
-------------------------------------*/
/*
お問い合わせ
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageContact.pageIndex #MainImg {
    background-image: url("../img/contents/contact/mainimage.jpg"); }
    #PageContact.pageIndex #MainImg .innerBasic {
      transform: translate(-50%, 66%); }
    #PageContact.pageIndex #MainImg .titleTypeBasic::before {
      width: 636px;
      height: 323px;
      background-image: url("../img/contents/contact/mainimage_wall.png");
      background-size: 636px auto;
      transform: translate(-50%, -43%); }
    #PageContact.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-51%, -56%) rotate(-10deg); }
  #PageContact.pageIndex #Main #ContBox01 {
    padding-top: 50px;
    padding-bottom: 0; }
  #PageContact.pageIndex #Main #ContBox02 {
    margin-top: 0; } }
@media print, screen and (max-width: 767px) {
  #PageContact.pageIndex #MainImg {
    background-image: url("../img/contents/contact/mainimage_sp.jpg"); }
    #PageContact.pageIndex #MainImg .innerBasic {
      transform: translate(-50%, -52%); }
    #PageContact.pageIndex #MainImg .titleTypeBasic::before {
      width: 318px;
      height: 176px;
      background-image: url("../img/contents/contact/mainimage_wall_sp.png");
      background-size: 318px auto;
      transform: translate(-50%, -45%); }
    #PageContact.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-53%, -55%) rotate(-10deg); }
  #PageContact.pageIndex #Main #ContBox01 {
    padding-top: 50px;
    padding-bottom: 0; } }
/*
プライバシーポリシー
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PagePolicy.pageIndex #MainImg {
    background-image: url("../img/contents/policy/mainimage.jpg"); }
    #PagePolicy.pageIndex #MainImg .innerBasic {
      transform: translate(-50%, 66%); }
    #PagePolicy.pageIndex #MainImg .titleTypeBasic::before {
      width: 636px;
      height: 323px;
      background-image: url("../img/contents/policy/mainimage_wall.png");
      background-size: 636px auto;
      transform: translate(-50%, -43%); }
    #PagePolicy.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-51%, -56%) rotate(-10deg); }
  #PagePolicy.pageIndex #Main #ContBox01 {
    padding-top: 40px; }
  #PagePolicy.pageIndex #Main #ContBox02 ol {
    margin-bottom: 15px;
    line-height: 32px;
    font-size: 16px; } }
@media print, screen and (max-width: 767px) {
  #PagePolicy.pageIndex #MainImg {
    background-image: url("../img/contents/policy/mainimage_sp.jpg"); }
    #PagePolicy.pageIndex #MainImg .innerBasic {
      transform: translate(-50%, -52%); }
    #PagePolicy.pageIndex #MainImg .titleTypeBasic::before {
      width: 318px;
      height: 176px;
      background-image: url("../img/contents/policy/mainimage_wall_sp.png");
      background-size: 318px auto;
      transform: translate(-50%, -45%); }
    #PagePolicy.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-53%, -55%) rotate(-10deg); }
  #PagePolicy.pageIndex #Main #ContBox01 {
    padding-top: 40px; }
  #PagePolicy.pageIndex #Main #ContBox02 ol {
    margin-bottom: 15px;
    line-height: 28px;
    font-size: 16px; } }
/*
サイトマップ
-------------------------------------*/
#PageSitemap.pageIndex #MainImg .titleTypeBasic::before {
  display: none; }
#PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li .borderBox {
  color: #f54992; }
  #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li .borderBox::after {
    border-radius: 0;
    width: 7px;
    height: 11px;
    background-image: url("../img/contents/arrow_02.png");
    background-size: 7px auto;
    background-color: transparent; }
#PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li + li .borderBox {
  border-top: none; }
#PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li + li.has_dl .borderBox {
  border-top-color: #f6f6f6;
  border-top-style: solid; }
#PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b b {
  display: block;
  border-bottom: 1px solid #dfdfdf; }
  #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b b.has_a {
    padding: 0; }
    #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b b.has_a a {
      border-bottom: none; }
      #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b b.has_a a::after {
        border-radius: 0;
        width: 7px;
        height: 11px;
        background-image: url("../img/contents/arrow_02.png");
        background-size: 7px auto;
        background-color: transparent; }
#PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul {
  border-bottom: 1px solid #dfdfdf; }
  #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul li {
    border-bottom: none; }
    #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul li:last-of-type {
      margin-bottom: 0; }
    #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul li a {
      line-height: 24px;
      font-size: 14px; }
      #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul li a::after {
        right: 0;
        left: 0;
        border-radius: 0;
        width: 14px;
        height: 1px;
        background-image: none;
        background-color: #ff92c0; }
#PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl + li .borderBox {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  border-top-color: #f6f6f6;
  border-top-style: solid; }

@media print, screen and (min-width: 768px) {
  #PageSitemap.pageIndex #MainImg {
    background-image: url("../img/contents/sitemap/mainimage.jpg"); }
    #PageSitemap.pageIndex #MainImg .innerBasic {
      transform: translate(-50%, 66%); }
    #PageSitemap.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-51%, -56%) rotate(-10deg); }
  #PageSitemap.pageIndex #Main #ContBox01 {
    padding-top: 100px; }
    #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton {
      float: left;
      margin-right: 50px;
      width: 300px; }
      #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton:last-of-type {
        margin-right: 0; }
      #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li {
        float: none;
        margin-top: 0;
        width: 300px; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li .borderBox {
          margin-bottom: 0;
          font-size: 20px; }
          #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li .borderBox span {
            padding-left: 13px;
            min-height: 70px;
            line-height: 32px; }
          #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li .borderBox::after {
            right: 16px; }
          #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li .borderBox[href*="/internship.html"] span {
            min-height: 100px; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li + li.has_dl {
          margin-top: 50px !important; }
          #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li + li.has_dl .borderBox {
            border-top-width: 6px; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b b {
          padding: 15px 40px 15px 20px;
          line-height: 24px; }
          #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b b.has_a a {
            padding: 17px 40px 17px 20px;
            font-size: 16px; }
            #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b b.has_a a::after {
              right: 22px; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul {
          padding: 17px 0 17px 22px; }
          #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul li {
            margin-bottom: 5px; }
            #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul li a {
              padding: 0 0 0 29px; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl + li .borderBox {
          border-top-width: 6px; } }
@media print, screen and (max-width: 767px) {
  #PageSitemap.pageIndex #MainImg {
    background-image: url("../img/contents/sitemap/mainimage_sp.jpg"); }
    #PageSitemap.pageIndex #MainImg .innerBasic {
      transform: translate(-50%, -52%); }
    #PageSitemap.pageIndex #MainImg .titleTypeBasic::after {
      transform: translate(-53%, -55%) rotate(-10deg); }
  #PageSitemap.pageIndex #Main #ContBox01 .innerBasic {
    padding-bottom: 50px; }
  #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton {
    margin-bottom: 30px; }
    #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li {
      margin-top: 0; }
      #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li .borderBox {
        font-size: 20px; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li .borderBox span {
          padding-left: 19px;
          min-height: 61px;
          line-height: 32px;
          font-size: 18px; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li .borderBox::after {
          right: 16px; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li .borderBox[href*="/internship.html"] span {
          min-height: 90px; }
      #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li + li.has_dl {
        margin-top: 28px !important; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li + li.has_dl .borderBox {
          border-top-width: 4px; }
      #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b b {
        padding: 11px 20px 11px 20px;
        line-height: 24px; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b b.has_a a {
          padding: 11px 40px 11px 20px;
          font-size: 14px; }
          #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b b.has_a a::after {
            right: 22px; }
      #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul {
        padding: 14px 0 16px 22px; }
        #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul li {
          margin-bottom: 5px; }
          #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl dl dd.has_b ul li a {
            padding: 0 0 0 29px; }
      #PageSitemap.pageIndex #Main #ContBox01 .listTypeButton > li.has_dl + li .borderBox {
        border-top-width: 4px; } }
/*
採用情報　共通
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageRecruit #MainImg {
    background-image: url("../img/contents/recruit/mainimage.jpg"); }
    #PageRecruit #MainImg .innerBasic {
      transform: translate(-50%, 66%); }
    #PageRecruit #MainImg .titleTypeBasic::before {
      width: 636px;
      height: 323px;
      background-image: url("../img/contents/recruit/mainimage_wall.png");
      background-size: 636px auto;
      transform: translate(-50%, -55%); }
    #PageRecruit #MainImg .titleTypeBasic::after {
      transform: translate(-51%, -56%) rotate(-10deg); } }
@media print, screen and (max-width: 767px) {
  #PageRecruit #MainImg {
    background-image: url("../img/contents/recruit/mainimage_sp.jpg"); }
    #PageRecruit #MainImg .innerBasic {
      transform: translate(-50%, -52%); }
    #PageRecruit #MainImg .titleTypeBasic::before {
      width: 318px;
      height: 176px;
      background-image: url("../img/contents/recruit/mainimage_wall_sp.png");
      background-size: 318px auto;
      transform: translate(-50%, -45%); }
    #PageRecruit #MainImg .titleTypeBasic::after {
      transform: translate(-53%, -55%) rotate(-10deg); } }
/*
採用情報
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageRecruit.pageIndex #Main #ContBox01 {
    padding-top: 90px;
    padding-bottom: 150px; } }
/*
採用情報（カテゴリアーカイブ）
-------------------------------------*/
@media print, screen and (min-width: 768px) {
  #PageRecruit.pageCategory #Main #ContBox01 {
    padding-top: 132px;
    padding-bottom: 150px; }
    #PageRecruit.pageCategory #Main #ContBox01 .titleTypeBasic {
      margin-bottom: 51px; } }
/*
採用情報（記事詳細）
-------------------------------------*/
#PageRecruit.pageEntry #Main #ContBox01 .date.new {
  position: relative;
  display: inline-block; }
  #PageRecruit.pageEntry #Main #ContBox01 .date.new::after {
    position: absolute; }

@media print, screen and (min-width: 768px) {
  #PageRecruit.pageEntry #Main #ContBox01 .date.new::after {
    top: -33px;
    right: -53px; } }
@media print, screen and (max-width: 767px) {
  #PageRecruit.pageEntry #Main #ContBox01 .date.new::after {
    top: -13px;
    right: -44px;
    letter-spacing: .01em; } }
