@import "_var.scss";
@import "base.css";
@import "common.css";
@import "blog.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

/*
トップページ
-------------------------------------*/
#Page.pageIndex {
	#MainImg{
		overflow: visible;
		border-bottom: 1px solid #ff65a6;
		.innerBasic{
			z-index: 2;
		}
		video{
			position: relative;
			z-index: 0;
			width: 100%;
			height: auto;
		}
		.titleTypeBasic{
			text-shadow: none;
			&::before{
				display: none;
			}
			&::after{
				color: rgba(255, 255, 255, .7);
			}
			.boxTypeBalloon{
				margin: 0 auto;
				font-weight: 400;
				span{
					text-align: center;
				}
			}
			em{
				display: block;
				text-shadow: 10px 0 15px rgb(255, 255, 255), 0 10px 15px rgb(255, 255, 255), -10px 0 15px rgb(255, 255, 255), 0 -10px 15px rgb(255, 255, 255), 10px 0 15px rgb(255, 255, 255), 0 10px 15px rgb(255, 255, 255), -10px 0 15px rgb(255, 255, 255), 0 -10px 15px rgb(255, 255, 255);
				font-weight: 700;
				&::before, &:after{
					content: "";
					position: relative;
					display: inline-block;
					background-repeat: no-repeat;
					background-position: center center;
				}
				&::before{
					left: auto;
					transform: translate(0, 0);
					background-image: url("../img/contents/top/parts_01_l.png")
				}
				&::after{
					top: auto;
					left: auto;
					transform: translate(0, 0);
					background-image: url("../img/contents/top/parts_01_r.png")
				}
				span{
					letter-spacing: 0;
				}
			}
			b{
				font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
				font-size: 0;
				font-weight: 500;
				color: #fff;
				span{
					letter-spacing: .1em;
					&:first-of-type{
						background-color: rgba(251, 192, 45, .8);
					}
					&:nth-of-type(2){
						background-color: rgba(57, 186, 142, .8);
					}
				}
			}
		}
		a{
			position: absolute;
			display: block;
			background-repeat: no-repeat;
			background-position: left top;
			background-image: url("../img/contents/top/banner_01.png");
			text-align: left;
			text-indent: -9999px;
		}
	}
	#Main{
		.contBox{
			.buttonTypeBasic{
				margin-right: auto;
				margin-left: auto;
			}
		}
		#ContBox02, #ContBox04{
			.titleTypeBasic{
				position: relative;
				z-index: 1;
				&::before{
					display: none;
				}
				&::after{
					content: attr(data-subtitle);
					position: absolute;
					z-index: -1;
					top: 50%;
					left: 50%;
					display: block;
					width: 100%;
					text-align: center;
					font-family: 'Dancing Script', cursive;
					font-style: italic;
					font-weight: 400;
				}
			}
		}
		#ContBox01{
			background-color: #f6f6f6;
			.titleTypeBasic{
				text-align: left;
			}
			.wrapBox{
				margin-bottom: 0;
				background-repeat: no-repeat;
				background-position: right bottom;
				background-color: #fff;
				.listTypeBlog{
					border-top: none;
					li{
						border: none;
						dl{
							.title{
								font-size: 16px;
								a{
									color: #000;
									&::after{
										display: none;
									}
								}
							}
						}
					}
				}
				.buttonTypeBasic{
					padding-top: 13px;
					padding-bottom: 0;
					width: 160px;
					height: 42px;
					min-height: auto;
					&::after{
						width: 8px;
						height: 12px;
						background-size: 8px auto;
					}
				}
			}
			.textBox{
				background-color: #ff65a6;
				font-size: 0;
				color: #fff;
				.titleTypeLabel{
					background-color: #fff;
					line-height: 26px;
					letter-spacing: .15em;
					font-size: 14px;
					color: #ff65a6;
					&::before, &::after{
						right: -9px;
					}
					&::before{
						top: auto;
						border-width: 13px 9px 0 0;
						border-color: #fff transparent transparent #fff;
					}
					&::after{
						border-width: 0 9px 13px 0;
						border-color: #fff transparent #fff transparent;
					}
				}
				a{
					font-family: 'Lato', sans-serif;
					font-weight: 400;
					color: #fff;
					&::before{
						background-image: url("../img/contents/icon_09_w.png");
					}
				}
				b{
					vertical-align: baseline;
				}
			}
		}
		#ContBox02{
			background-repeat: repeat-x, repeat-x;
			background-position: left top, left bottom;
			background-image: url("../img/contents/wall_05_pink.gif"), url("../img/contents/wall_05_pink.gif");
			background-color: #ffd1da;
			.titleTypeBasic{
				&::after{
					color: #ffe1e7;
				}
			}
			.innerBasic{
				background-color: #fff;
			}
			.wrapBox{
				position: relative;
				margin: 0 auto;
				&::before, &::after{
					content: "";
					position: absolute;
					z-index: 0;
					display: block;
					background-repeat: no-repeat;
					background-position: center top;
					/* ↓アニメーション用のスタイル */
					opacity: 0;
					transition-delay: 6s;
				}
				&::before{
					background-image: url("../img/contents/top/photo_09.png");
					/* ↓アニメーション用のスタイル */
					transform: translateX(-40px);
				}
				&::after{
					background-image: url("../img/contents/top/photo_10.png");
					/* ↓アニメーション用のスタイル */
					transform: translateX(40px);
				}
				&> p{
					text-align: center;
				}
				.listTypeButton{
					li{
						a{
							span{
								padding: 0;
								justify-content: center;
								.titleTypeBasic{
									font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
									font-weight: 500;
								}
								.titleTypeBasic, p{
									margin-bottom: 0;
									text-align: center;
									padding: 0;
								}
								p{
									color: #888;
								}
							}
						}
					}
				}
				&.fadeIn{
					&::before, &::after{
						transition: 0.5s ease-in;
						transform: translate(0, 0);
						opacity: 1 !important;
					}
				}
			}
		}
		#ContBox03{
			.listTypeButton{
				li{
					margin-top: 0;
					border-bottom-style: solid;
					border-bottom-color: #f6f6f6;
					a{
						&::after{
							display: none;
						}
						.textBox{
							position: relative;
							&::after{
								content: "";
								position: absolute;
								top: 50%;
								display: block;
								transform: translateY(-50%);
								width: 10px;
								height: 17px;
								background-repeat: no-repeat;
								background-position: center center;
								background-image: url("../img/contents/arrow_01.png");
								background-size: 10px auto;
							}
							span{
								padding: 0;
								flex-wrap: nowrap;
								flex-direction: column;
								align-items: center;
								justify-content: center;
							}
							h4, p{
								padding: 0;
								text-align: center;
							}
							h4{
								color: #f54992;
							}
							p{
								margin-bottom: 0;
								font-size: 14px;
								color: #888;

							}
						}
						&[href*=youtube]{
							.textBox{
								&::after{
									border-radius: 50%;
									border: 1px solid #ff65a6;
									width: 30px;
									height: 30px;
									background-image: url("../img/contents/arrow_07.png");
									background-size: 9px auto;
								}
							}
						}
					}
				}
			}
		}
		#ContBox04{
			.titleTypeBasic{
				&::after{
					color: #f1f1f1;
				}
				&+ p{
					text-align: center;
				}
			}
			ul{
				li{
					width: 312px;
					.boxTypeBalloon{
						width: 308px;
						height: 103px;
						background-size: 308px;
						span{
							min-height: 76px;
							line-height: 28px;
							text-align: center;
							letter-spacing: .08em;
							font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
							font-size: 16px;
							font-weight: 500;
						}
					}
					.wrapBox{
						position: relative;
						border-radius: 50%;
						overflow: hidden;
						.imgBox{
							position: relative;
							z-index: 0;
							width: 100%;
						}
						.textBox{
							position: absolute;
							top: 234px;
							left: 50%;
							transform: translateX(-50%);
							z-index: 2;
							border-radius: 50%;
							padding-top: 14px;
							width: 402px;
							height: 402px;
							background-repeat: repeat;
							background-position: left top;
							background-image: url("../img/contents/wall_04_pink.gif");
							background-size: 16px auto;
							text-align: center;
							font-size: 20px;
							color: #fff;
							span{
								display: block;
								font-size: 14px;
							}
							&.yellow{
								background-image: url("../img/contents/wall_04_yellow.gif");
							}
							&.green{
								background-image: url("../img/contents/wall_04_green.gif");
							}
						}
					}
				}
			}
			.buttonTypeBasic{
				margin-top: 62px;
			}
		}
		#ContBox06{
			background-color: #ffeff6;
			.txtTypeCenter{
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			.listTypeButton{
				li{
					a{
						span{
							min-height: 100px;
							b{
								font-size: 20px;
								p{
									margin-bottom: 0;
									padding: 0;
									display: block;
									font-size: 14px;
									color: #888;
								}
							}
						}
					}
				}
			}
		}
		#ContBox07{
			.buttonTypeBasic{
				margin-top: 45px;
			}
			.listTypeBlog{
				li{
					a{
						dl{
							dd{
								color: #000;
							}
						}
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #Page.pageIndex {
		#MainImg{
			//height: 800px;
			height: auto;
			.innerBasic{
				//top: 55%;
				top: 62%
				//height: 800px;
			}
			.titleTypeBasic{
				&::after{
					transform: translate(-50%, -25%) rotate(-10deg);
				}
				.boxTypeBalloon{
					width: 531px;
					height: 81px;
					background-image: url("../img/contents/balloon_05_pink.png");
					background-size: 531px auto;
					span{
						padding-top: 6px;
						padding-left: 22px;
						min-height: 57px;
						font-size: 22px;
					}
				}
				em{
					letter-spacing: .12em;
					font-size: 44px;
					&::before, &:after{
						width: 22px;
						height: 14px;
					}
					&::before{
						top: -33px;
						margin-right: 18px;
					}
					&::after{
						bottom: -14px;
						margin-left: 10px;
					}
				}
				b{
					span{
						display: inline-block;
						padding: 0 15px;
						line-height: 30px;
						letter-spacing: .1em;
						font-size: 16px;
						&:first-of-type{
							margin-right: 1px;
						}
					}
				}
			}
			a{
				right: 30px;
				bottom: -32px;
				width: 220px;
				height: 225px;
				background-size: 220px auto;
				&:hover{
					opacity: .7 !important;
					transition-delay: 0;
				}
			}
		}
		#Main{
			.contBox{
				.innerBasic{
					padding-top: 80px;
					padding-bottom: 120px;
				}
				.titleTypeBasic{
					font-size: 28px;
					&::before{
						top: -11px;
					}
				}
				.buttonTypeBasic{
					width: 400px;
				}
			}
			#ContBox02, #ContBox04{
				.titleTypeBasic{
					font-size: 36px;
					&::after{
						font-size: 180px;
					}
				}
			}
			#ContBox01{
				.innerBasic{
					padding-top: 50px;
					padding-bottom: 80px;
				}
				.titleTypeBasic{
					margin-bottom: 1px;
					font-size: 24px;
				}
				.wrapBox{
					position: relative;
					margin-bottom: 0;
					padding: 23px 27px 10px;
					min-height: 236px;
					background-image: url("../img/contents/top/photo_05_pc.jpg");
					background-size: 275px auto;
					.listTypeBlog{
						li{
							margin-bottom: 4px;
							dl{
								.date, .title{
									margin-bottom: 0;
									display: inline-block;
									vertical-align: middle;
								}
								.date{
									width: 128px;
								}
								.title{
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
									-webkit-text-overflow: ellipsis;
									list-style: none;
									line-height: 1;
									font-family: helvetica,'arial black',arial,sans-serif;
									padding: 10px 0;
									width: 590px;
									a{
										display: inline;
										padding: 0;
										font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
										font-weight: 500;
										&:hover{
											text-decoration: underline;
										}
									}
								}
							}
						}
					}
					.buttonTypeBasic{
						position: absolute;
						right: 33px;
						bottom: 28px;
					}
				}
				.textBox{
					padding: 4px 30px;
					font-size: 0;
					.titleTypeLabel, em, b{
						display: inline-block;
						//vertical-align: middle;
					}
					.titleTypeLabel{
						position: relative;
						top: -4px;
					}
					a{
						margin-right: 4px;
						margin-left: 25px;
						letter-spacing: .05em;
						font-size: 28px;
						&::before{
							width: 41px;
							height: 23px;
							background-size: 41px auto;
						}
					}
					b{
						letter-spacing: .1em;
						font-size: 14px;
					}
				}
			}
			#ContBox02{
				padding-top: 135px;
				padding-right: 30px;
				padding-bottom: 120px;
				padding-left: 30px;
				background-size: 8px auto, 8px 648px;
				.titleTypeBasic{
					margin-bottom: 83px;
					&::after{
						transform: translate(-51%, -69%) rotate(-10deg);
					}
				}
				.innerBasic{
					padding-top: 43px;
					padding-bottom: 60px;
					width: auto;
				}
				.wrapBox{
					width: 1000px;
					&::before{
						bottom: -222px;
						left: -116px;
						width: 267px;
						height: 870px;
						background-size: 267px;
					}
					&::after{
						bottom: -231px;
						right: -174px;
						width: 402px;
						height: 911px;
						background-size: 402px;
					}
					&> p{
						margin-bottom: 50px;
						line-height: 38px;
						letter-spacing: 0.15em;
						font-size: 20px;
					}
					.listTypeButton{
						position: relative;
						z-index: 2;
						li{
							margin-top: 20px;
							&:nth-of-type(1), &:nth-of-type(2){
								margin-top: 0;
							}
							a{
								span{
									min-height: 108px;
									.titleTypeBasic{
										font-size: 24px;
										letter-spacing: 0.05em;
									}
									p{
										margin-top: -6px;
										letter-spacing: 0.05em;
										font-size: 16px;
									}
								}
							}
						}
					}
				}
				.buttonTypeBasic{
					margin-top: 62px;
				}
			}
			#ContBox03{
				padding-top: 96px;
				.titleTypeBasic{
					margin-bottom: 29px;
				}
				.listTypeButton{
					li{
						border-bottom-width: 10px;
						width: 50%;
						margin-right: 0;
						&:nth-of-type(2n+1){
							border-right: 1px solid #f6f6f6;
						}
						&:nth-of-type(2n){
							border-left: 1px solid #f6f6f6;
						}
						a{
							.textBox{
								&::after{
									right: 22%;
								}
								span{
									min-height: 139px;
								}
								h4{
									letter-spacing: .1em;
									font-size: 24px;
								}
								p{
									margin-top: 5px;
									line-height: 24px;
								}
							}
							&[href*=youtube]{
								.textBox{
									&::after{
										right: 20%;
									}
								}
							}
						}
					}
				}
			}
			#ContBox04{
				.innerBasic{
					padding-top: 112px;
					padding-bottom: 88px;
				}
				.titleTypeBasic{
					margin-bottom: 83px;
					&::after{
						transform: translate(-52%, -50%) rotate(-10deg);
					}
					&+ p{
						margin-bottom: 58px;
						line-height: 38px;
						letter-spacing: 0.15em;
						font-size: 20px;
					}
				}
				ul{
					&::after {
						content: "";
						display: block;
						clear: both;
					}
					li{
						float: left;
						margin-right: 32px;
						&:nth-of-type(3n){
							margin-right: 0;
						}
						.boxTypeBalloon{
							margin-bottom: 10px;
						}
						&:nth-of-type(2){
							transition-delay: 300ms;
						}
						&:nth-of-type(3){
							transition-delay: 600ms;
						}
					}
				}
				.buttonTypeBasic{
					margin-top: 60px;
				}
			}
			#ContBox05{
				.innerBasic{
					position: relative;
					padding-bottom: 90px;
					&::before, &::after{
						content: "";
						position: absolute;
						z-index: 0;
						display: block;
						background-repeat: no-repeat;
						background-position: center top;
						/* ↓アニメーション用のスタイル */
						opacity: 0;
						transition-delay: 6s;
					}
					&::before{
						bottom: 24px;
						left: -91px;
						width: 136px;
						height: 534px;
						background-image: url("../img/contents/top/photo_11.png");
						background-size: 136px;
						/* ↓アニメーション用のスタイル */
						transform: translateX(-40px);
					}
					&::after{
						bottom: 29px;
						right: -112px;
						width: 272px;
						height: 591px;
						background-image: url("../img/contents/top/photo_08.png");
						background-size: 272px;
						/* ↓アニメーション用のスタイル */
						transform: translateX(40px);
					}
					&.fadeIn{
						&::before, &::after{
							transition: 0.5s ease-in;
							transform: translate(0, 0);
							opacity: 1 !important;
						}
					}
				}
				.titleTypeBasic{
					margin-bottom: 44px;
				}
				.listTypeButton{
					position: relative;
					z-index: 1;
					padding: 0;
					&.listTypeButton01{
						li{
							margin-top: 0;
							margin-right: 20px;
							width: 320px;
							&:nth-of-type(2n){
								margin-right: 20px;
							}
							&:nth-of-type(2n+1){
								clear: none;
							}
							&:nth-of-type(3n){
								margin-right: 0;
							}
							&:nth-of-type(3n+1){
								clear: both;
							}
						}
					}
					&.listTypeButton02{
						li{
							margin-top: 0;
							margin-right: 20px;
							width: 235px;
							&:nth-of-type(2n){
								margin-right: 20px;
							}
							&:nth-of-type(2n+1){
								clear: none;
							}
							&:nth-of-type(4n){
								margin-right: 0;
							}
							&:nth-of-type(4n+1){
								clear: both;
							}
							a{
								font-size: 16px;
								span{
									padding-left: 60px;
									min-height: 60px;
									b{
										font-size: 14px;
									}
								}
							}
						}
					}
					&.listTypeButton03{
						li{
							margin-top: 0;
							margin-right: 20px;
							width: 235px;
							&:nth-of-type(2n){
								margin-right: 20px;
							}
							&:nth-of-type(2n+1){
								clear: none;
							}
							&:nth-of-type(4n){
								margin-right: 0;
							}
							&:nth-of-type(4n+1){
								clear: both;
							}
							a{
								font-size: 16px;
								span{
									padding-left: 20px;
									min-height: 60px;
								}
							}
						}
					}
				}
			}
			#ContBox06{
				.innerBasic{
					position: relative;
					padding-bottom: 90px;
					&::before, &::after{
						content: "";
						position: absolute;
						bottom: 0;
						z-index: 0;
						display: block;
						background-repeat: no-repeat;
						background-position: center top;
						/* ↓アニメーション用のスタイル */
						opacity: 0;
						transition-delay: 6s;
					}
					&::before{
						left: -175px;
						width: 351px;
						height: 360px;
						background-image: url("../img/contents/top/photo_07.png");
						background-size: 351px;
						/* ↓アニメーション用のスタイル */
						transform: translateX(-40px);
					}
					&::after{
						right: -146px;
						width: 392px;
						height: 345px;
						background-image: url("../img/contents/top/photo_06.png");
						background-size: 392px;
						/* ↓アニメーション用のスタイル */
						transform: translateX(40px);
					}
					&.fadeIn{
						&::before, &::after{
							transition: 0.5s ease-in;
							transform: translate(0, 0);
							opacity: 1 !important;
						}
					}
				}
				.titleTypeBasic{
					margin-bottom: 11px;
					&+ p{
						margin-bottom: 22px;
					}
				}
				.txtTypeCenter{
					&:last-of-type{
						margin-top: 22px;
					}
				}
				.listTypeButton, .buttonTypeBasic{
					position: relative;
					z-index: 1;
				}
				.listTypeButton{
					margin-right: auto;
					margin-left: auto;
					width: 740px;
					li{
						width: 360px;
						a{
							span{
								b{
									p{
										margin-top: 2px;
									}
								}
							}
							&::after{
								right: 15px;
							}
						}
					}
				}
				.buttonTypeBasic{
					margin-top: 58px;
				}
			}
			#ContBox07{
				.titleTypeBasic{
					margin-bottom: 34px;
				}
				.listTypeBlog{
					li{
						a{
							padding: 20px 0;
							dl{
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								-webkit-text-overflow: ellipsis;
								list-style: none;
								line-height: 1;
								font-family: helvetica,'arial black',arial,sans-serif;
								font-size: 0;
								dt, dd{
									margin-bottom: 0;
									padding-top: 10px;
									padding-bottom: 10px;
									display: inline-block;
									vertical-align: middle;
								}
								dt{
									padding-left: 15px;
									width: 140px;
									font-size: 14px;
								}
								dd{
									width: 859px;
									font-size: 16px;
									font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
									font-weight: 500;
								}
							}
						}
					}
				}
				.buttonTypeBasic{
					margin-top: 45px;
				}
			}
		}
    }
}
@media print,screen and (min-width: 768px) and (max-width: 1300px) {
    #Page.pageIndex {
		#Main{
			#ContBox03{
				.listTypeButton{
					li{
						a{
							.textBox{
								&::after{
									right: 12%;
								}
							}
							&[href*=youtube]{
								.textBox{
									&::after{
										right: 10%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media print,screen and (max-width: 767px) {
    #Page.pageIndex {
		#MainImg{
			//height: 510px;
			height: auto;
			.innerBasic{
				top: 43%
			}
			.titleTypeBasic{
				&::after{
					transform: translate(-50%, 47%) rotate(-10deg);
					font-size: 76px;
				}
				.boxTypeBalloon{
					width: 239px;
					height: 87px;
					background-image: url("../img/contents/balloon_06_pink.png");
					background-size: 239px auto;
					span{
						padding-left: 10px;
						min-height: 71px;
						line-height: 22px;
						font-size: 16px;
					}
				}
				em{
					position: relative;
					display: inline-block;
					margin-bottom: 14px;
					line-height: 40px;
					letter-spacing: .12em;
					font-size: 32px;
					&::before, &:after{
						position: absolute;
						width: 16px;
						height: 11px;
						background-size: 16px auto;
					}
					&::before{
						top: -10px;
						left: -14px;
					}
					&::after{
						bottom: -1px;
						right: -14px;
					}
				}
				b{
					display: block;
					margin-right: auto;
					margin-left: auto;
					max-width: 325px;
					span{
						display: inline-block;
						width: 100%;
						line-height: 28px;
						letter-spacing: .1em;
						font-size: 13px;
						&:first-of-type{
							margin-bottom: 2px;
							width: 140px;
						}
					}
				}
			}
			a{
				right: 15px;
				bottom: -32px;
				width: 182px;
				height: 182px;
				background-size: 182px auto;
			}
		}
		#Main{
			.contBox{
				.innerBasic{
					padding-top: 73px;
					padding-bottom: 75px;
				}
				.titleTypeBasic{
					line-height: 30px;
					font-size: 22px;
					&::before{
						top: -36px;
					}
					&+ p{
						text-align: justify;
					}
				}
				.buttonTypeBasic{
					max-width: 290px;
				}
			}
			#ContBox02, #ContBox04{
				.titleTypeBasic{
					font-size: 28px;
					&::after{
						font-size: 90px;
					}
				}
			}
			#ContBox01{
				.innerBasic{
					padding-top: 55px;
					padding-bottom: 60px;
				}
				.titleTypeBasic{
					margin-bottom: 10px;
					font-size: 24px;
				}
				.wrapBox{
					position: relative;
					margin-bottom: 0;
					padding: 23px 15px 28px;
					background-image: url("../img/contents/top/photo_05.jpg");
					//background-size: 345px auto;
					background-size: 100% auto;
					.listTypeBlog{
						li{
							margin-bottom: 13px;
							dl{
								.date, .title{
								}
								.date{
									margin-bottom: 3px;
								}
								.title{
									a{
										display: inline;
										padding: 0;
										text-decoration: underline;
									}
								}
							}
						}
					}
					.buttonTypeBasic{
						margin-top: 188px;
						margin-right: 0;
						margin-left: auto;
					}
				}
				.textBox{
					padding: 15px 15px 6px;
					font-size: 0;
					.titleTypeLabel{
						margin-bottom: -2px;
						&::before{
							right: -8.5px;
							border-width: 13px 0 13px 9px;
							border-color: transparent transparent transparent #fff;
						}
						&::after{
							display: none;
						}
					}
					a, b{
						display: inline-block;
					}
					a{
						margin-right: 1px;
						letter-spacing: .05em;
						font-size: 22px;
						&::before{
							width: 35px;
							height: 20px;
							background-size: 35px auto;
						}
					}
					b{
						letter-spacing: .1em;
						font-size: 12px;
					}
				}
			}
			#ContBox02{
				padding-top: 100px;
				padding-bottom: 67px;
				background-size: 8px auto, 8px 67px;
				.titleTypeBasic{
					margin-bottom: 57px;
					&::after{
						transform: translate(-50%, -81%) rotate(-10deg);
					}
				}
				.innerBasic{
					padding-top: 40px;
					padding-bottom: 45px;
					width: auto;
				}
				.wrapBox{
					&::before{
						top: -78px;
						left: -2px;
						width: 67px;
						height: 220px;
						background-size: 67px;
					}
					&::after{
						top: -90px;
						right: -15px;
						width: 102px;
						height: 230px;
						background-size: 102px;
					}
					&> p{
						margin-bottom: 37px;
						line-height: 30px;
						letter-spacing: 0.15em;
						font-size: 16px;
					}
					.listTypeButton{
						position: relative;
						z-index: 2;
						li{
							margin-top: 20px;
							a{
								&::after{
									right: 10px;
								}
								span{
									min-height: 108px;
									.titleTypeBasic{
										margin-top: 0;
										font-size: 20px;
										letter-spacing: 0.05em;
									}
									p{
										margin-top: 0;
										line-height: 22px;
										letter-spacing: 0.05em;
										font-size: 14px;
									}
								}
							}
						}
					}
				}
				.buttonTypeBasic{
					margin-top: 44px;
				}
			}
			#ContBox03{
				padding-top: 95px;
				.titleTypeBasic{
					margin-bottom: 24px;
				}
				.listTypeButton{
					li{
						border-bottom-width: 7.5px;
						a{
							.textBox{
								&::after{
									right: 30px;
								}
								span{
									min-height: 102px;
								}
								h4{
									letter-spacing: .1em;
									font-size: 20px;
								}
								p{
									margin-top: -3px;
									line-height: 22px;
								}
							}
							&[href*=youtube]{
								.textBox{
									&::after{
										right: 18px;
									}
								}
							}
						}
					}
				}
			}
			#ContBox04{
				.innerBasic{
					padding-top: 87px;
					padding-bottom: 76px;
				}
				.titleTypeBasic{
					margin-bottom: 34px;
					&::after{
						transform: translate(-51%, -77%) rotate(-10deg);
					}
					&+ p{
						margin-bottom: 29px;
						line-height: 30px;
						letter-spacing: 0.15em;
						font-size: 16px;
					}
				}
				ul{
					li{
						margin-right: auto;
						margin-left: auto;
						margin-bottom: 32px;
						&:nth-of-type(3n){
							margin-bottom: 0;
						}
						.boxTypeBalloon{
							margin-bottom: 8px;
						}
						.wrapBox{
							.textBox{
								top: 234px;
								padding-top: 8px;
								span{
									margin-top: -7px;
								}
							}
						}
					}
				}
				.buttonTypeBasic{
					margin-top: 44px;
				}
			}
			#ContBox05{
				.innerBasic{
					padding-top: 77px;
					padding-bottom:   40px;
				}
				.titleTypeBasic{
					margin-bottom: 36px;
				}
				.listTypeButton{
					padding: 0;
					&.listTypeButton01{
						position: relative;
						margin-bottom: 15px;
						&::before, &::after{
							content: "";
							position: absolute;
							z-index: 0;
							display: block;
							background-repeat: no-repeat;
							background-position: center top;
							/* ↓アニメーション用のスタイル */
							opacity: 0;
							transition-delay: 6s;
						}
						&::before{
							top: -179px;
							left: 10px;
							width: 47px;
							height: 179px;
							background-image: url("../img/contents/top/photo_11.png");
							background-size: 47px;
							/* ↓アニメーション用のスタイル */
							transform: translate(-40px, 40px);
						}
						&::after{
							top: -203px;
							right: 0;
							width: 94px;
							height: 203px;
							background-image: url("../img/contents/top/photo_08.png");
							background-size: 94px;
							/* ↓アニメーション用のスタイル */
							transform: translate(40px, 40px);
						}
						&.fadeIn{
							&::before, &::after{
								transition: 0.5s ease-in;
								transform: translate(0, 0);
								opacity: 1 !important;
							}
						}
						li{
							a{
								span{
									justify-content: center;
									text-align: center;
									font-size: 20px;
									b{
										position: relative;
										display: inline-block;
										&::before{
											content: "";
											position: absolute;
											top: 50%;
											display: block;
											background-repeat: no-repeat;
											background-position: center center;
											transform: translateY(-50%);
										}
									}
								}
							}
							&.icon_02{
								a::before{
									margin-left: -54px;
								}
							}
						}
					}
					&.listTypeButton02{
						display: flex;
						flex-wrap: wrap;
						&::after{
							content: "";
							display: block;
							clear: both;
						}
						li{
							margin-top: 0;
							margin-bottom: 15px;
							width: calc((100% - 15px)/2);
							&:nth-of-type(2n){
								//float: right;
							}
							&:nth-of-type(2n+1){
								//float: left;
								//clear: none;
								margin-right: 15px;
							}
							&:nth-of-type(4n){
								margin-right: 0;
							}
							a{
								padding: 10px 0 10px;
								min-height: 95px;
								font-size: 16px;
								&::before{
									position: static;
									display: block;
									transform: translateY(0);
									margin: 0 auto 6px;
								}
								span{
									display: block;
									padding-left: 0;
									min-height: auto;
									line-height: 20px;
									text-align: center;
									&> b{
										display: block;
										min-height: auto;
										font-size: 14px;
									}
								}
							}
							&.icon_05{
								a{
									padding-top: 27px;
								}
							}
							&.icon_06{
								a{
									padding-top: 14px;
								}
							}
							&.icon_07{
								a{
									padding-top: 27px;
								}
							}
							&.icon_08{
								a{
									padding-top: 14px;
								}
							}
						}
					}
					&.listTypeButton03{
						li{
							float: left;
							margin-top: 0;
							margin-bottom: 15px;
							width: calc((100% - 15px)/2);
							&:nth-of-type(2n+1){
								margin-right: 15px;
							}
							&:nth-of-type(4n){
								margin-right: 0;
							}
							a{
								font-size: 16px;
								span{
									justify-content: center;
									padding-right: 0;
									padding-left: 0;
									text-align: center;
									min-height: 70px;
								}
							}
						}
					}
				}
			}
			#ContBox06{
				.innerBasic{
					padding-bottom: 60px;
				}
				.titleTypeBasic{
					margin-bottom: 18px;
					&+ p{
						margin-bottom: 113px;
					}
				}
				.txtTypeCenter{
					&:last-of-type{
						margin-top: 22px;
					}
				}
				.listTypeButton{
					position: relative;
					&::before, &::after{
						content: "";
						position: absolute;
						z-index: 0;
						display: block;
						background-repeat: no-repeat;
						background-position: center top;
						/* ↓アニメーション用のスタイル */
						opacity: 0;
						transition-delay: 6s;
					}
					&::before{
						top: -108px;
						left: 56px;
						width: 108px;
						height: 108px;
						background-image: url("../img/contents/top/photo_07.png");
						background-size: 108px;
						/* ↓アニメーション用のスタイル */
						transform: translateX(-40px);
					}
					&::after{
						top: -103px;
						right: 47px;
						width: 120px;
						height: 103px;
						background-image: url("../img/contents/top/photo_06.png");
						background-size: 120px;
						/* ↓アニメーション用のスタイル */
						transform: translateX(40px);
					}
					&.fadeIn{
						&::before, &::after{
							transition: 0.5s ease-in;
							transform: translate(0, 0);
							opacity: 1 !important;
						}
					}
					li{
						a{
							span{
								b{
									p{
										margin-top: -4px;
									}
								}
							}
						}
					}
				}
				.buttonTypeBasic{
					margin-top: 30px;
				}
			}
			#ContBox07{
				.innerBasic{
					padding-top: 73px;
					padding-bottom: 75px;
				}
				.titleTypeBasic{
					margin-bottom: 25px;
				}
				.listTypeBlog{
					li{
						a{
							padding-bottom: 17px;
							dl{
								margin-bottom: 0;
								dt{
									margin-bottom: 2px;
								}
								dd{
									text-decoration: underline;
									line-height: 28px;
									font-size: 16px;
								}
							}
						}
					}
				}
				.buttonTypeBasic{
					margin-top: 30px;
				}
			}
		}
    }
}

/*
私たちの約束
-------------------------------------*/
#PageGeeting.pageIndex {
	#MainImg{
		.titleTypeBasic{
			.boxTypeBalloon{
				font-weight: 400;
			}
			&::before{
				background-image: url("../img/contents/greeting/mainimage_wall.png");
			}
		}
	}
	#Main, #TopicPath{
		background-color: #ffeff6;
	}
	#Main{
		#ContBox01{
			background-repeat: repeat-x, repeat;
			background-position: left top, left top;
			background-image: url("../img/contents/wall_pink.gif"), url("../img/contents/wall_01_pink.gif");
			ol{
				counter-reset: numberGreeting;
				list-style: none;
				& >li{
					position: relative;
					background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-x, repeat-y, repeat-x, repeat-y;
					background-position: left top, right top, right bottom, left bottom, left top, right top, left bottom, left top;
					background-image: url("../img/contents/box_corner_a_01.gif"), url("../img/contents/box_corner_b_01.gif"), url("../img/contents/box_corner_c_01.gif"), url("../img/contents/box_corner_d_01.gif"), url("../img/contents/box_border_a_01.gif"), url("../img/contents/box_border_b_01.gif"), url("../img/contents/box_border_c_01.gif"), url("../img/contents/box_border_d_01.gif");
					background-size: 15px auto, 15px auto, 15px auto, 15px auto, 1px auto, auto 1px, 1px auto, auto 1px;
					background-color: #fff;
					font-family: 'M PLUS 1p', sans-serif;
					font-weight: 400;
					&::before{
						counter-increment: numberGreeting;
						content: '0' counter(numberGreeting) '. ';
						position: absolute;
						font-family: 'Dancing Script', cursive;
						font-style: italic;
						font-weight: 400;
						color: #ff65a6;
					}
				}
			}
		}
		#ContBox02{
			background-color: #fff;
			.listTypeButton{
				li{
					background-color: #f6f6f6;
					dl{
						dt{
							a{
								&::after{
									display: none;
								}
								.textBox{
									position: relative;
									&::after{
										content: "";
										position: absolute;
										top: 50%;
										display: block;
										transform: translateY(-50%);
										width: 10px;
										height: 17px;
										background-repeat: no-repeat;
										background-position: center center;
										background-image: url("../img/contents/arrow_01.png");
										background-size: 10px auto;
									}
									span{
										padding: 0;
										flex-wrap: nowrap;
										flex-direction: column;
										align-items: center;
										justify-content: center;
									}
									h4, p{
										padding: 0;
										text-align: center;
									}
									h4{
										color: #f54992;
									}
									p{
										margin-bottom: 0;
										font-size: 14px;
										color: #888;

									}
								}
							}
						}
						dd{
							ul{
								li{
									border-bottom-width: 2px;
									border-bottom-color: #f6f6f6;
									width: 100%;
									&:last-of-type{
										border-bottom: none;
									}
								}
							}
						}
					}
				}
			}
		}
		#ContBox03{
			background-color: #fff;
			.listTypeButton{
				li{
					a{
						color: #f54992;
					}
					&.green{
						a{
							color: #16b982;
						}
					}
					&.yellow{
						a{
							color: #f49400;
						}
					}
					&.blue{
						a{
							color: #00b6dd;
						}
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageGeeting.pageIndex {
		#MainImg{
			background-image: url("../img/contents/greeting/mainimage.jpg");
			.innerBasic{
				transform: translate(-50%, -16%);
			}
			.titleTypeBasic{
				.boxTypeBalloon{
					margin: 0 auto -3px;
					width: 430px;
					height: 82px;
					background-image: url("../img/contents/balloon_08_pink_pc.png");
					background-size: 430px;
					font-size: 22px;
					span{
						padding-left: 18px;
						min-height: 60px;
						letter-spacing: .12em;
					}
				}
				&::before{
					width: 638px;
					height: 323px;
					background-size: 638px auto;
				}
			}
		}
		#Main{
			#ContBox01{
				padding-top: 21px;
				padding-bottom: 90px;
				background-size: 2px 300px, 8px auto;
				.titleTypeBasic{
					margin-bottom: 51px;
				}
				ol{
					&::after {
						content: "";
						display: block;
						clear: both;
					}
					& >li{
						float: left;
						line-height: 38px;
						letter-spacing: .15em;
						font-size: 20px;
						&:nth-of-type(1){
							margin-right: 30px;
							padding: 103px 23px 0 38px;
							width: 425px;
							height: 468px;
						}
						&:nth-of-type(2){
							margin-bottom: 30px;
							padding: 62px 23px 0 128px;
							width: 545px;
							height: 238px;
						}
						&:nth-of-type(3){
							padding: 62px 23px 0 128px;
							width: 545px;
							height: 200px;
						}
						&::before{
							top: 37px;
							left: 34px;
							font-size: 44px;
						}
					}
				}
			}
			#ContBox02{
				padding-top: 110px;
				.titleTypeBasic{
					margin-bottom: 30px;
				}
				.listTypeButton{
					li{
						float: left;
						clear: none;
						border-right: 1px solid #fff;
						margin-top: 0;
						margin-right: 0;
						width: 33.3333333333%;
						&:last-of-type{
							border-right: none;
							margin-right: 0;
						}
						dl{
							dt{
								margin-right: -1px;
								border-right: 1px solid #f6f6f6;
								width: calc(100% + 1px);
								a{
									.textBox{
										&::after{
											right: 25%;
										}
										span{
											min-height: 88px;
										}
										h4{
											letter-spacing: .1em;
											font-size: 24px;
										}
									}
								}
							}
							dd{
								padding: 30px;
								ul{
									li{
										float: none;
										a{
											&::after{
												right: 14px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox03{
				padding-top: 110px;
				padding-bottom: 150px;
				.titleTypeBasic{
					margin-bottom: 31px;
				}
				.listTypeButton{
					li{
						margin-top: 0;
						margin-right: 20px;
						width: 235px;
						clear: none;
						&:nth-of-type(4n){
							margin-right: 0;
						}
						a{
							padding: 96px 5px 0;
							height: 235px;
							text-align: center;
							font-size: 22px;
							&::before, &::after{
								left: 50%;
								transform: translateX(-50%);
							}
							&::after{
								top: auto;
								bottom: 45px;
							}
							span{
								display: block;
								padding: 0;
							}
						}
						&.icon_12{
							a{
								&::before{
									top: 39px;
								}
							}
						}
						&.icon_13{
							a{
								&::before{
									top: 39px;
								}
							}
						}
						&.icon_14{
							a{
								&::before{
									top: 45px;
								}
							}
						}
						&.icon_15{
							a{
								&::before{
									top: 44px;
								}
							}
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageGeeting.pageIndex {
		#MainImg{
			background-image: url("../img/contents/greeting/mainimage_sp.jpg");
			.innerBasic{
				transform: translate(-50%, -62%);
			}
			.titleTypeBasic{
				.boxTypeBalloon{
					margin: 0 auto -8px;
					width: 299px;
					height: 65px;
					background-image: url("../img/contents/balloon_08_pink.png");
					background-size: 299px;
					font-size: 16px;
					span{
						padding-left: 18px;
						min-height: 52px;
						letter-spacing: .12em;
					}
				}
				&::before{
					width: 251px;
					height: 117px;
					background-size: 251px auto;
					transform: translate(-50%, -15%);
				}
				&::after{
					transform: translate(-50%, -34%) rotate(-10deg);
				}
			}
		}
		#Main{
			.contBox{
				.titleTypeBasic{
					font-size: 24px;
					&::before{
						top: -38px;
					}
				}
			}
			#ContBox01{
				padding-top: 42px;
				padding-bottom: 59px;
				background-size: 2px 300px, 8px auto;
				.titleTypeBasic{
					margin-bottom: 24px;
				}
				ol{
					& >li{
						margin-bottom: 15px;
						padding: 62px 23px 53px 70px;
						line-height: 30px;
						letter-spacing: .15em;
						font-size: 16px;
						&::before{
							top: 28px;
							left: 31px;
							font-size: 30px;
						}
						&:nth-of-type(1){
							padding: 78px 23px 53px 35px;
						}
						&:last-of-type{
							margin-bottom: 0;
						}
					}
				}
			}
			#ContBox02{
				padding-top: 97px;
				.titleTypeBasic{
					margin-bottom: 23px;
				}
				.listTypeButton{
					li{
						margin-top: 0;
						dl{
							dt{
								a{
									.textBox{
										&::after{
											right: 20%;
										}
										span{
											min-height: 70px;
										}
										h4{
											letter-spacing: .1em;
											font-size: 20px;
										}
									}
								}
							}
							dd{
								padding: 15px 15px 45px;
								ul{
									li{
										a{
											padding: 17px 14px 17px;
											font-size: 16px;
											&::after{
												right: 15px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox03{
				padding-top: 97px;
				padding-bottom: 105px;
				.titleTypeBasic{
					margin-bottom: 24px;
				}
				.listTypeButton{
					li{
						a{
							text-align: center;
							font-size: 18px;
							&::after{
								right: 40px;
							}
							span{
								padding-right: 59px;
								justify-content: center;
								min-height: 90px;
							}
						}
						&.icon_12{
							a{
								&::before{
									left: 38px;
								}
							}
						}
						&.icon_13{
							a{
								&::before{
									left: 41px;
								}
							}
						}
						&.icon_14{
							a{
								&::before{
									left: 36px;
								}
							}
						}
						&.icon_15{
							a{
								&::before{
									left: 40px;
								}
							}
						}
					}
				}
			}
		}
    }
}

/*
看護局のご紹介（職員数・体制）
-------------------------------------*/
#PageAbout.pageIndex {
	#MainImg{
		.titleTypeBasic{
			&::before{
				background-image: url("../img/contents/greeting/mainimage_wall.png");
			}
		}
	}
	#TopicPath{
		background-color: #ffeff6;
	}
	#Main{
		#ContBox05{
			.titleTypeBasic{
				.boxTypeBalloon{
					font-weight: 400;
				}
			}
			background-repeat: repeat-x, repeat;
			background-position: left top, left top;
			background-image: url("../img/contents/wall_pink.gif"), url("../img/contents/wall_01_pink.gif");
			ol{
				counter-reset: numberGreeting;
				list-style: none;
				& >li{
					position: relative;
					background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-x, repeat-y, repeat-x, repeat-y;
					background-position: left top, right top, right bottom, left bottom, left top, right top, left bottom, left top;
					background-image: url("../img/contents/box_corner_a_01.gif"), url("../img/contents/box_corner_b_01.gif"), url("../img/contents/box_corner_c_01.gif"), url("../img/contents/box_corner_d_01.gif"), url("../img/contents/box_border_a_01.gif"), url("../img/contents/box_border_b_01.gif"), url("../img/contents/box_border_c_01.gif"), url("../img/contents/box_border_d_01.gif");
					background-size: 15px auto, 15px auto, 15px auto, 15px auto, 1px auto, auto 1px, 1px auto, auto 1px;
					background-color: #fff;
					font-family: 'M PLUS 1p', sans-serif;
					font-weight: 400;
					&::before{
						counter-increment: numberGreeting;
						content: '0' counter(numberGreeting) '. ';
						position: absolute;
						font-family: 'Dancing Script', cursive;
						font-style: italic;
						font-weight: 400;
						color: #ff65a6;
					}
				}
			}
		}
		#ContBox02{
			.wrapBox{
				.imgBox{
					p{
						margin-bottom: 0;
						text-align: center;
					}
				}
				.textBox{
					p{
						&:last-of-type{
							margin-bottom: 0;
						}
					}
				}
			}
		}
		#ContBox06{
			background-color: #fff;
			.listTypeButton{
				li{
					a{
						color: #f54992;
					}
					&.green{
						a{
							color: #16b982;
						}
					}
					&.yellow{
						a{
							color: #f49400;
						}
					}
					&.blue{
						a{
							color: #00b6dd;
						}
					}
				}
			}
		}
        #ContBox07{
            .listFigure{
                text-align: center;
                font-size: 0;
                li{
                    display: inline-block;
                    img{
                        height: auto;
                    }
                }
            }
            .listTypeButton{
                li{
                    .borderBox{
                        position: relative;
                        display: block;
                        text-align: center;
                        text-decoration: none;
                        font-size: 18px;
                        color: #222;
                        &::after{
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        span{
                            justify-content: space-evenly;
                        }
                    }
                }
            }
        }
	}
}
@media print,screen and (min-width: 768px) {
    #PageAbout.pageIndex {
		#MainImg{
			background-image: url("../img/contents/greeting/mainimage.jpg");
			.innerBasic{
				transform: translate(-50%, 66%);
			}
			.titleTypeBasic{
				&::before{
					width: 638px;
					height: 323px;
					background-size: 638px auto;
				}
				&::after{
					transform: translate(-51%, -56%) rotate(-10deg);
				}
			}
		}
		#Main{
			#ContBox05{
				margin-bottom: 40px;
				padding-top: 21px;
				padding-bottom: 90px;
				background-size: 2px 300px, 8px auto;
				.titleTypeBasic{
					margin-bottom: 51px;
					.boxTypeBalloon{
						margin: 0 auto;
						width: 430px;
						height: 82px;
						background-image: url("../img/contents/balloon_08_pink_pc.png");
						background-size: 430px;
						font-size: 22px;
						span{
							padding-left: 18px;
							min-height: 60px;
							letter-spacing: .12em;
						}
					}
				}
				ol{
					&::after {
						content: "";
						display: block;
						clear: both;
					}
					& >li{
						float: left;
						line-height: 38px;
						letter-spacing: .15em;
						font-size: 20px;
						&:nth-of-type(1){
							margin-right: 30px;
							padding: 103px 23px 0 38px;
							width: 425px;
							height: 468px;
						}
						&:nth-of-type(2){
							margin-bottom: 30px;
							padding: 62px 23px 0 128px;
							width: 545px;
							height: 238px;
						}
						&:nth-of-type(3){
							padding: 62px 23px 0 128px;
							width: 545px;
							height: 200px;
						}
						&::before{
							top: 37px;
							left: 34px;
							font-size: 44px;
						}
					}
				}
			}
			#ContBox02{
				.wrapBox{
					.imgBox{
						float: left;
						width: 240px;
						p{
							margin-top: 21px;
							line-height: 28px;
							.txtTypeGray{
								font-size: 14px;
							}
						}
					}
					.textBox{
						float: right;
						margin-top: -8px;
						width: 700px;
					}
				}
			}
			#ContBox04{
				.tableTypeBasic{
					tbody{
						th{
							width: 360px;
						}
					}
				}
			}
			#ContBox06{
				//padding-top: 110px;
				//padding-bottom: 150px;
				.titleTypeBasic{
					margin-bottom: 31px;
				}
				.listTypeButton{
					margin: 0 auto;
					width: 745px;
					li{
						margin-top: 0;
						margin-right: 20px;
						width: 235px;
						clear: none;
						&:nth-of-type(3n){
							margin-right: 0;
						}
						a{
							padding: 96px 5px 0;
							height: 235px;
							text-align: center;
							font-size: 22px;
							&::before, &::after{
								left: 50%;
								transform: translateX(-50%);
							}
							&::after{
								top: auto;
								bottom: 45px;
							}
							span{
								display: block;
								padding: 0;
							}
						}
						&.icon_12{
							a{
								&::before{
									top: 39px;
								}
							}
						}
						&.icon_13{
							a{
								&::before{
									top: 39px;
								}
							}
						}
						&.icon_14{
							a{
								&::before{
									top: 45px;
								}
							}
						}
						&.icon_15{
							a{
								&::before{
									top: 44px;
								}
							}
						}
					}
				}
			}
			#ContBox07{
				//padding-bottom: 150px;
				.titleTypePoint{
					margin-bottom: 52px;
				}
                .listFigure{
                    margin-bottom: 52px;
                    li{
                        margin-right: 2px;
                        img{
                            width: 330px;
                        }
                    }
                }
                .listTypeButton{
                    margin-top: 50px;
                    li{
                        float: none;
                        margin: 0 auto;
                        width: 520px;
                        font-size: 20px;
                        .borderBox{
                            //padding: 30px 20px;
                            width: 520px;
                            &::after{
                                right: 25px;
                            }
                        }
                    }
                }
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageAbout.pageIndex {
		#MainImg{
			background-image: url("../img/contents/greeting/mainimage_sp.jpg");
			.innerBasic{
				transform: translate(-50%, -52%);
			}
			.titleTypeBasic{
				&::before{
					width: 251px;
					height: 117px;
					background-size: 251px auto;
					transform: translate(-50%, -38%);
				}
				&::after{
					transform: translate(-50%, -55%) rotate(-10deg);
				}
			}
		}
		#Main{
			#ContBox05{
				margin-bottom: 40px;
				padding-top: 42px;
				padding-bottom: 59px;
				background-size: 2px 300px, 8px auto;
				.titleTypeBasic{
					margin-bottom: 24px;
					.boxTypeBalloon{
						margin: 0 auto;
						width: 299px;
						height: 65px;
						background-image: url("../img/contents/balloon_08_pink.png");
						background-size: 299px;
						font-size: 16px;
						span{
							padding-left: 18px;
							min-height: 42px;
							letter-spacing: .12em;
						}
					}
				}
				ol{
					& >li{
						margin-bottom: 15px;
						padding: 62px 23px 53px 70px;
						line-height: 30px;
						letter-spacing: .15em;
						font-size: 16px;
						&::before{
							top: 28px;
							left: 31px;
							font-size: 30px;
						}
						&:nth-of-type(1){
							padding: 78px 23px 53px 35px;
						}
						&:last-of-type{
							margin-bottom: 0;
						}
					}
				}
			}
			#ContBox02{
				.wrapBox{
					.imgBox{
						margin-bottom: 16px;
						text-align: center;
						img{
							width: 180px;
						}
						p{
							margin-top: 9px;
							line-height: 28px;
							.txtTypeGray{
								font-size: 14px;
							}
						}
					}
					.textBox{
					}
				}
			}
			#ContBox06{
				//padding-top: 97px;
				//padding-bottom: 105px;
				.titleTypeBasic{
					margin-bottom: 24px;
				}
				.listTypeButton{
					li{
						a{
							text-align: center;
							font-size: 18px;
							&::after{
								right: 40px;
							}
							span{
								padding-right: 59px;
								justify-content: center;
								min-height: 90px;
							}
						}
						&.icon_12{
							a{
								&::before{
									left: 38px;
								}
							}
						}
						&.icon_13{
							a{
								&::before{
									left: 41px;
								}
							}
						}
						&.icon_14{
							a{
								&::before{
									left: 36px;
								}
							}
						}
						&.icon_15{
							a{
								&::before{
									left: 40px;
								}
							}
						}
					}
				}
			}
			#ContBox07{
				.titleTypePoint{
					margin-bottom: 24px;
				}
                .listFigure{
                    margin-bottom: 26px;
                    text-align: center;
                    font-size: 0;
                    li{
                        margin-right: 1px;
                        margin-bottom: 0;
                        width: calc((100% - 4px) / 3);
                        &:last-of-type{
                            margin-right: 0;
                        }
                    }
                }
                .listTypeButton{
                    margin-top: -7px;
                    li{
                        .borderBox{
                            font-size: 18px;
                            &::after{
                                right: 17px;
                            }
                        }
                    }
                }
			}
		}
    }
}

/*
先輩からのメッセージ
-------------------------------------*/
#PageMessage {
	#MainImg{
	}
}
@media print,screen and (min-width: 768px) {
	#PageMessage {
		#MainImg{
			background-image: url("../img/contents/message/mainimage.jpg");
			.innerBasic{
				transform: translate(-50%, 66%);
			}
			.titleTypeBasic{
				&::before{
					width: 636px;
					height: 323px;
					background-image: url("../img/contents/message/mainimage_wall.png");
					background-size: 636px auto;
					transform: translate(-50%, -55%);
				}
				&::after{
					transform: translate(-51%, -56%) rotate(-10deg);
				}
			}
		}
	}
}
@media print,screen and (max-width: 767px) {
	#PageMessage {
		#MainImg{
			background-image: url("../img/contents/message/mainimage_sp.jpg");
			.innerBasic{
				transform: translate(-50%, -52%);
			}
			.titleTypeBasic{
				&::before{
					width: 318px;
					height: 176px;
					background-image: url("../img/contents/message/mainimage_wall_sp.png");
					background-size: 318px auto;
					transform: translate(-50%, -45%);
				}
				&::after{
					transform: translate(-53%, -55%) rotate(-10deg);
				}
			}
		}
	}
}

/*
先輩からのメッセージ　トップページ
-------------------------------------*/
#PageMessage.pageIndex {
}
@media print,screen and (min-width: 768px) {
    #PageMessage.pageIndex {
		#Main{
			#ContBox01{
				padding-top: 49px;
				&::after{
					margin-left: -159px;
				}
			}
			#ContBox02{
				.innerBasic{
					&> .txtTypeCenter{
						margin-bottom: 82px;
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageMessage.pageIndex {
		#Main{
			#ContBox01{
				padding-top: 40px;
				&::after{
					margin-left: -83px;
				}
			}
			#ContBox02{
				.innerBasic{
					&> .txtTypeCenter{
						margin-bottom: 22px;
						padding-right: 15px;
						padding-left: 15px;
					}
				}
			}
		}
    }
}

/*
先輩からのメッセージ　下層
-------------------------------------*/
#PageMessage.pageCase {
	#Main{
		#ContBox01{
			.innerBasic{
				&:not(:last-of-type){
					padding-bottom: 0;
				}
			}
			.titleTypeQuestion{
				padding-left: 0;
			}
			&> img{
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageMessage.pageCase {
		#SubBox{
			.listTypeMenu{
				li{
					&:last-of-type{
						margin-right: 0;
					}
				}
			}
		}
		#SubBoxBottom{
			display: none;
		}
		#Main{
			#ContBox01{
				margin-top: 0;
				padding-top: 179px;
				.innerBasic{
					width: 760px;
				}
				.overviewImg{
					margin-bottom: 95px;
				}
				.titleTypeQuestion{
					margin-bottom: 39px;
					&[data-subtitle]{
						&::before{
							margin-left: -31px;
							margin-bottom: 65px;
						}
					}
				}
				&> img{
					margin: 53px auto 126px;
					width: 760px;
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageMessage.pageCase {
		#SubBox{
			display: none;
		}
		#Main{
			#ContBox01{
				padding-top: 112px;
				.innerBasic{
					padding-right: 30px;
					padding-bottom: 25px;
					padding-left: 30px;
				}
				.overviewImg{
					margin-bottom: -3px;
				}
				.titleTypeQuestion{
					padding-top: 30px;
					margin-bottom: 29px;
					&[data-subtitle]{
						&::before{
							top: -5px;
							left: -1px;
							margin-left: -16px;
						}
					}
				}
				&> img{
					margin: 39px 0 53px;
				}
			}
		}
    }
}

/*
教育&キャリアアップ
-------------------------------------*/
#PageEducation {
	#MainImg{
	}
}
@media print,screen and (min-width: 768px) {
	#PageEducation {
		#MainImg{
			background-image: url("../img/contents/education/mainimage.jpg");
			.innerBasic{
				transform: translate(-50%, 66%);
			}
			.titleTypeBasic{
				&::before{
					width: 636px;
					height: 323px;
					background-image: url("../img/contents/education/mainimage_wall.png");
					background-size: 636px auto;
					transform: translate(-50%, -55%);
				}
				&::after{
					transform: translate(-52.5%, -56%) rotate(-10deg);
				}
			}
		}
	}
}
@media print,screen and (max-width: 767px) {
	#PageEducation {
		#MainImg{
			background-image: url("../img/contents/education/mainimage_sp.jpg");
			.innerBasic{
				transform: translate(-50%, -52%);
			}
			.titleTypeBasic{
				&::before{
					width: 318px;
					height: 162px;
					background-image: url("../img/contents/education/mainimage_wall_sp.png");
					background-size: 318px auto;
					transform: translate(-50%, -36%);
				}
				&::after{
					transform: translate(-55%, -55%) rotate(-10deg);
				}
			}
		}
	}
}

/*
看護師教育（クリニカルラダー）とキャリアアップ
-------------------------------------*/
#PageEducation.pageIndex {
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageIndex {
		#Main{
			#ContBox01{
				&::after{
					margin-left: -326px;
				}
			}
			#ContBox02{
				.innerBasic{
					padding-bottom: 122px;
				}
				.borderBox{
					margin-bottom: 60px;
					&:first-of-type{
						padding-top: 23px;
						padding-bottom: 23px;
						.titleTypeBasic{
							letter-spacing: .15em;
						}
					}
				}
				.listTypeNumber{
					li{
						margin-bottom: 7px;
					}
				}
			}
			#ContBox03{
				.innerBasic{
					padding-bottom: 170px;
				}
				.borderBox{
					margin-bottom: 60px;
					padding-top: 23px;
					//padding-bottom: 23px;
					.titleTypeBasic{
						letter-spacing: .1em;
					}
				}
				.imgBox{
					margin-top: -23px;
					&+ .borderBox{
						margin-top: 32px;
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageIndex {
		#Main{
			#ContBox02{
				.borderBox{
					margin-bottom: 50px;
					padding-top: 15px;
					padding-bottom: 15px;
					.titleTypeBasic{
						letter-spacing: 0;
					}
				}
				.listTypeNumber{
					li{
						margin-bottom: 17px;
					}
				}
			}
			#ContBox03{
				.innerBasic{
					padding-bottom: 170px;
				}
				.borderBox{
					margin-bottom: 47px;
					&:first-of-type{
						padding-top: 15px;
						//padding-bottom: 23px;
						.titleTypeBasic{
							letter-spacing: 0;
						}
						p{
							text-align: center;
						}
					}
				}
				.imgBox{
					margin-top: 2px;
					&+ .borderBox{
						margin-top: 15px;
					}
				}
			}
		}
    }
}

/*
ラダーレベル別研修
-------------------------------------*/
#PageEducation.pageTraining {
	#Main{
		#ContBox02{
			.wrapBox{
				background-color: #fff;
				.textBox{
					.listTypeCircle{
						margin-bottom: 0;
						li{
							margin-bottom: 0;
						}
					}
				}
				.titleTypeBorder{
					margin-top: 0;
				}
				p{
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageTraining {
		#Main{
			#ContBox01{
				&::after{
					margin-left: -97px;
				}
			}
			#ContBox02{
				margin-bottom: 130px;
				.innerBasic{
					padding-top: 76px;
					padding-bottom: 150px;
					width: 1000px;
				}
				.titleTypePoint{
					margin-bottom: 30px;
				}
				.wrapBox{
					margin-bottom: 48px;
					.textBox, .imgBox{
						width: 50%;
					}
					.textBox{
						&.left{
							padding: 91px 30px 51px 91px;
						}
						&.right{
							padding: 91px 91px 51px 60px;
						}
						.listTypeCircle{
							li{
								padding-left: 20px;
							}
						}
					}
					.imgBox{
						&.left{
							padding: 30px 0 30px 30px;
						}
						&.right{
							padding: 30px 30px 30px 0;
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageTraining {
		#Main{
			#ContBox01{
				&::after{
					margin-left: -65px;
				}
			}
			#ContBox02{
				margin-bottom: 70px;
				.innerBasic{
					padding-top: 50px;
					padding-bottom: 17px;
				}
				.titleTypePoint{
					margin-bottom: 20px;
				}
				.wrapBox{
					margin-bottom: 40px;
					.textBox, .imgBox{
					}
					.textBox{
						padding: 31px 25px 31px 25px;
						&+ .textBox{
							padding-top: 0;
						}
						&+ .imgBox{
						margin-top: -5px;
						}
						.titleTypeBorder{
							margin-bottom: 15px;
						}
						.listTypeCircle{
							li{
								padding-left: 14px;
								&::before{
									top: 10px;
									width: 8px;
									height: 8px;
								}
							}
						}
					}
					.imgBox{
						padding: 0 15px 15px 15px;
					}
				}
			}
		}
    }
}

/*
コース研修・ゼミナール等 院内認定看護師育成研修/技能認定研修
-------------------------------------*/
#PageEducation.pageSeminar {
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageSeminar {
		#Main{
			#ContBox02{
				margin-top: -141px;
				.innerBasic{
					padding-bottom: 108px;
				}
				.titleTypeBorder{
					margin-bottom: 28px;
				}
				.tableTypeBasic{
					thead{
						tr{
							th{
								&:nth-of-type(1){
									width: 190px;
								}
								&:nth-of-type(2){
									width: 83px;
								}
								&:nth-of-type(4){
									width: 232px;
								}
							}
						}
					}
					tbody{
						tr{
							td{
								&:nth-of-type(1){
									text-align: center;
								}
							}
						}
					}
					&+ .txtTypeRight{
						margin-top: -8px;
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageSeminar {
		#Main{
			#ContBox01{
				padding-bottom: 118px;
				&::after{
					margin-left: -70px;
				}
			}
			#ContBox02{
				margin-top: -67px;
				.innerBasic{
					padding-bottom: 50px;
				}
				.titleTypeBorder{
					margin-bottom: 22px;
				}
				.tableTypeScroll{
					margin-bottom: 50px;
				}
				.tableTypeBasic{
					margin-bottom: 50px;
					width: 445px;
					thead{
						tr{
							th{
								&:nth-of-type(1){
									width: 100px;
								}
								&:nth-of-type(2){
									//width: 45px;
									width: 90px;
								}
								&:nth-of-type(3){
									width: 155px;
								}
								&:nth-of-type(4){
									width: 100px;
								}
							}
						}
					}
					tbody{
						tr{
							td{
								&:nth-of-type(1){
								}
							}
						}
					}
					&+ .txtTypeRight{
						margin-top: -29px;
						font-size: 12px;
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px)  and (min-width: 475px) {
    #PageEducation.pageSeminar {
		#Main{
			#ContBox02{
				.textTypeSwipe{
					display: none;
				}
				.tableTypeBasic{
					width: 100%;
				}
			}
		}
	}
}

/*
地域医療支援センター/スキルセンター
-------------------------------------*/
#PageEducation.pageSkills-center {
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageSkills-center {
		#Main{
			#ContBox02, #ContBox03{
				.innerBasic{
					padding-bottom: 153px;
				}
				.titleTypeBorder{
					margin-top: 61px;
					margin-bottom: 22px;
				}
				.listFigure{
					font-size: 0;
					li{
						margin-right: 2px;
						&:nth-of-type(2n){
							margin-right: 0;
						}
						img{
							height: 299px;
						}
					}
				}
				.wrapBox{
					margin-bottom: 0;
					.textBox{
						width: 50%;
						&:nth-of-type(odd){
							padding-right: 30px;
						}
						&:nth-of-type(even){
							padding-left: 30px;
						}
					}
					&:first-child{
						.titleTypeBorder{
							margin-top: 0;
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageSkills-center {
		#Main{
			#ContBox01{
				&::after{
					margin-left: -73px;
				}
			}
			#ContBox02, #ContBox03{
				.titleTypeBorder{
					margin-top: 33px;
					margin-bottom: 18px;
				}
				.wrapBox{
					margin-bottom: 0;
					&:first-child{
						.textBox{
							&:first-child{
								.titleTypeBorder{
									margin-top: 0;
								}
							}
						}
					}
				}
			}
			#ContBox02{
				.innerBasic{
					padding-bottom: 76px;
				}
			}
			#ContBox03{
				.innerBasic{
					padding-bottom: 103px;
				}
			}
		}
    }
}

/*
新人看護師教育プログラム
-------------------------------------*/
#PageEducation.pageProgram {
	#Main{
		#ContBox02{
			.listTypeButton{
				li{
					&.borderBox{
						p{
							&:last-of-type{
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
		#ContBox03{
			.wrapBox{
				.contSubBox{
					.titleTypeLabel{
						font-family: 'M PLUS 1p', sans-serif;
						font-weight: bold;
						b{
							span{
								font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
								font-weight: bold;
							}
						}
					}
					.balloonBox{
						padding: 0;
						.textBox{
							.listTypeCircle{
								margin-bottom: 0;
								li{
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageProgram {
		#Main{
			#ContBox01{
				&::after{
					margin-left: -609px;
					width: 2000px;
				}
				.titleTypeBasic[data-subtitle]{
					&+ .titleTypeBasic{
						margin-bottom: 13px;
						line-height: 40px;
						letter-spacing: 0.1em;
						font-size: 24px;
					}
				}
			}
			#ContBox02{
				margin-top: -120px;
				.innerBasic{
					padding-bottom: 90px;
				}
				.listTypeButton{
					li{
						&.borderBox{
							padding: 26px 0 29px;
							p{
								&:last-of-type{
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
			#ContBox03{
				.innerBasic{
					padding-bottom: 150px;
				}
				.wrapBox{
					.contSubBox{
						position: relative;
						display: flex;
						flex-wrap: nowrap;
						align-items: stretch;
						margin-top: -8px;
						&::before{
							content: "";
							position: absolute;
							display: block;
							z-index: 1;
							bottom: -38px;
							left: 0;
							border: none;
							width: 120px;
							height: 50px;
							background-repeat: no-repeat, repeat-y;
							background-position: center bottom, left top;
							background-size: 120px auto, 120px auto;
						}
						.titleTypeLabel{
							display: flex;
							align-items: center;
							flex-wrap: nowrap;
							justify-content: center;
							margin-right: 20px;
							padding-top: 27px;
							padding-left: 0;
							width: 120px;
							height: auto;
							font-size: 24px;
							&::before, &::after{
								display: none;
							}
							b{
								padding-top: 17px;
								span{
									font-size: 14px;
								}
							}
						}
						.balloonBox{
							margin-top: 26px;
							width: 860px;
							&::after, &::before{
								top: 50%;
								transform: translateY(-50%);
							}
							&::after{
								left: -11px;
								border-color: transparent #eaeaea transparent transparent;
								border-width: 11px 11px 11px 0;
							}
							&::before{
								left: -9px;
								border-color: transparent #fff transparent transparent;
								border-width: 9px 9px 9px 0;
							}
							.textBox{
								padding: 11px 0 10px 17px;
								width: 445px;
							}
							.imgBox{
								position: absolute;
								z-index: 5;
							}
						}
						&.contSubBox01{
							z-index: 2;
							margin-top: 0;
							&::before{
								background-image: url("../img/contents/education/new/program/parts_02.jpg"), url("../img/contents/education/new/program/parts_01.jpg");
							}
							.titleTypeLabel{
								padding-top: 10px;
								&::before{
									border-color: #fcd28d #fcd28d transparent transparent;
								}
								&::after{
									border-color: #fcd28d transparent transparent #fcd28d;
								}
								b{
									padding-top: 0;
								}
							}
							.balloonBox{
								margin-top: 0;
								.imgBox{
									top: 22px;
									right: 97px;
									width: 330px;
								}
							}
						}
						&.contSubBox02{
							&::before{
								background-image: url("../img/contents/education/new/program/parts_03.jpg"), url("../img/contents/education/new/program/parts_04.jpg");
							}
							.titleTypeLabel{
								&::before{
									border-color: #ffcb93 #ffcb93 transparent transparent;
								}
								&::after{
									border-color: #ffcb93 transparent transparent #ffcb93;
								}
							}
							.balloonBox{
								.imgBox{
									top: 18px;
									right: 33px;
									width: 455px;
								}
							}
						}
						&.contSubBox03{
							&::before{
								background-image: url("../img/contents/education/new/program/parts_05.jpg"), url("../img/contents/education/new/program/parts_07.jpg");
							}
							.titleTypeLabel{
								&::before{
									border-color: #ffb798 #ffb798 transparent transparent;
								}
								&::after{
									border-color: #ffb798 transparent transparent #ffb798;
								}
							}
							.balloonBox{
								.imgBox{
									top: 34px;
									right: 61px;
									width: 148px;
								}
							}
						}
						&.contSubBox04{
							&::before{
								background-image: url("../img/contents/education/new/program/parts_06.jpg"), url("../img/contents/education/new/program/parts_08.jpg");
							}
							.titleTypeLabel{
								&::before{
									border-color: #ffaba0 #ffaba0 transparent transparent;
								}
								&::after{
									border-color: #ffaba0 transparent transparent #ffaba0;
								}
							}
							.balloonBox{
								.imgBox{
									top: 16px;
									right: 43px;
									width: 325px;
								}
							}
						}
						&.contSubBox05{
							&::before{
								background-image: url("../img/contents/education/new/program/parts_10.jpg"), url("../img/contents/education/new/program/parts_09.jpg");
							}
							.titleTypeLabel{
								&::before{
									border-color: #f9a1b2 #f9a1b2 transparent transparent;
								}
								&::after{
									border-color: #f9a1b2 transparent transparent #f9a1b2;
								}
							}
							.balloonBox{
								.imgBox{
									top: 49px;
									right: 93px;
									width: 128px;
								}
							}
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageProgram {
		#Main{
			#ContBox01{
				&::after{
					margin-left: -159px;
					width: 500px;
				}
				/*.titleTypeBasic{
					margin-bottom: 0;
					&+ .titleTypeBasic{
						line-height: 32px;
						font-size: 20px;
					}
				}*/
			}
			#ContBox02{
				margin-top: -64px;
				.innerBasic{
					padding-bottom: 77px;
				}
				.listTypeButton{
					li{
						&.borderBox{
							padding: 0 0 15px;;
							.titleTypeBasic{
								margin-bottom: 10px;
							}
							p{
								line-height: 28px;
								font-size: 16px;
								&:last-of-type{
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
			#ContBox03{
				.innerBasic{
					padding-bottom: 150px;
				}
				.wrapBox{
					.contSubBox{
						margin-top: 30px;
						&:first-of-type{
							margin-top: 0;
						}
						.titleTypeLabel{
							margin-bottom: 0;
							padding-left: 25px;
							width: calc(100% - 15px);
							height: 34px;
							line-height: 34px;
							text-align: center;
							font-size: 18px;
							&::before, &::after{
								right: -15px;
							}
							&::before{
								border-width: 17.5px 15px 0 0;
							}
							&::after{
								border-width: 0 15px 17.5px 0;
							}
							b{
								span{
									font-size: 12px;
								}
							}
						}
						.balloonBox{
							margin-top: 16px;
							.textBox{
								padding: 13px 10px 10px 19px;
								.listTypeCircle{
									li{
										padding-left: 19px;
										line-height: 28px;
										font-size: 16px;
									}
								}
							}
							.imgBox{
								position: relative;
								z-index: 5;
							}
						}
						&.contSubBox01{
							.balloonBox{
								.imgBox{
									top: -10px;
									left: 50%;
									transform: translateX(-50%);
									margin-bottom: -33px;
									width: 247px;
								}
							}
						}
						&.contSubBox02{
							.balloonBox{
								.imgBox{
									top: -6px;
									left: 47%;
									transform: translateX(-50%);
									margin-bottom: 6px;
									width: 342px;
								}
							}
						}
						&.contSubBox03{
							.balloonBox{
								position: relative;
								.textBox{
									padding-bottom: 72px;
								}
								.imgBox{
									position: absolute;
									bottom: -21px;
									right: 8px;
									width: 111px;
								}
							}
						}
						&.contSubBox04{
							.balloonBox{
								.imgBox{
									top: -6px;
									left: 47%;
									transform: translateX(-50%);
									margin-bottom: -21px;
									width: 243px;
								}
							}
						}
						&.contSubBox05{
							.balloonBox{
								position: relative;
								.textBox{
									padding-bottom: 3px;
								}
								.imgBox{
									position: absolute;
									bottom: -18px;
									right: 12px;
									width: 96px;
								}
							}
						}
					}
				}
			}
		}
    }
}

/*
新人看護師の1年
-------------------------------------*/
#PageEducation.pageSchedule {
	#Main{
		#ContBox01{
			padding-bottom: 0;
		}
		#ContBox02{
			margin-top: 0;
			.contSubBox{
				.titleTypeLabel{
					display: block;
					background-repeat: no-repeat, repeat;
					background-position: left top;
					background-color: transparent;
					b{
						visibility: hidden;
					}
					&::before, &::after{
						display: block;
						z-index: 1;
					}
				}
				&> .wrapBox{
					.listTypeCircle{
						margin-bottom: 0;
						li{
							margin-bottom: 0;
						}
					}
					.textBox{
						.titleTypeBasic{
							margin-bottom: 10px;
							text-align: left;
							letter-spacing: 0;
						}
					}
					.stripeBox{
						background-size: auto 1px;
						&> .titleTypeCircle{
							&:first-of-type{
								margin-top: 0;
							}
						}
						.wrapBox{
							margin-bottom: 0;
						}
						p{
							margin-bottom: 0;
						}
					}
					.borderBox{
						margin-bottom: 0;
					}
				}
				&.contSubBox01{
					&> .wrapBox{
						.stripeBox{
							background-image: url("../img/contents/wall_02_beige.gif");
							li{
								display: inline-block;
							}
						}
					}
				}
				&.contSubBox02{
					&> .wrapBox{
						.stripeBox{
							background-image: url("../img/contents/wall_02_green.gif");
						}
					}
					.borderBox{
						&+ .wrapBox{
							.textBox{
								p{
									&:last-of-type{
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
				&.contSubBox03{
					&> .wrapBox{
						.textBox{
							.listTypecheck{
								li{
									margin-bottom: 0;
								}
							}
							.blue{
								margin-bottom: 0;
								color: #3d95c6;
							}
						}
						.stripeBox{
							background-image: url("../img/contents/wall_02_blue.gif");
						}
					}
				}
				&.contSubBox04{
					&> .wrapBox{
						.stripeBox{
							background-image: url("../img/contents/wall_02_yellow.gif");
						}
					}
				}
				&.contSubBox05{
					&> .wrapBox{
						.textBox{
							.listTypecheck{
								li{
									margin-bottom: 0;
								}
							}
						}
						.stripeBox{
							background-image: url("../img/contents/wall_02_pink.gif");
							.wrapBox{
								.box{
									&.right{
										.listFigure{
											li{
												img{
													width: 100%;
													height: auto;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		#ContBox03{
			.innerBasic{
				&> .wrapBox{
					&:first-of-type{
						img{
							width: 100%;
							height: auto;
						}
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageSchedule {
		#Main{
			#ContBox01{
				padding-bottom: 0;
				.overviewImg{
					.textBox{
						padding-top: 15px;
						.titleTypeBasic{
							margin-bottom: 17px;
							font-weight: 700;
							span{
								position: relative;
								display: inline-block;
								padding-right: 33px;
								&::before, &:after{
									content: "";
									position: absolute;
									display: block;
									width: 15px;
									height: 10px;
									background-repeat: no-repeat;
									background-position: center center;
									background-size: 15px auto;
								}
								&::before{
									top: 1px;
									left: -28px;
									background-image: url("../img/contents/top/parts_01_l.png")
								}
								&::after{
									bottom: 2px;
									right: 9px;
									background-image: url("../img/contents/top/parts_01_r.png")
								}
							}
						}
					}
				}
			}
			#ContBox02{
				.innerBasic{
					padding-top: 40px;
					padding-bottom: 68px;
				}
				.contSubBox{
					position: relative;
					display: flex;
					flex-wrap: nowrap;
					align-items: stretch;
					margin-bottom: 60px;
					.titleTypeLabel{
						width: 50px;
						height: auto;
						background-size: 50px auto, auto 1px;
						&::before, &::after{
							top: auto;
							bottom: 0;
						}
						&::before{
							right: auto;
							left: 0;
							border-width: 0 25px 20px 0;
							border-color: transparent transparent #fff #fff;
						}
						&::after{
							right: 0;
							border-width: 0 0 20px 25px;
							border-color: transparent #fff #fff transparent;
						}
					}
					&> .wrapBox{
						width: 890px;
						margin-bottom: 60px;
						.imgBox, .textBox{
							margin-bottom: 19px;
						}
						.imgBox{
							width: 277px;
						}
						.textBox{
							margin-top: -8px;
							width: 587px;
							.titleTypeBasic{
								margin-bottom: 10px;
								line-height: 44px;
								font-size: 28px;
							}
							&+ .titleTypeBorder{
								clear: both;
								margin-bottom: 20px;
							}
							&+ .stripeBox{
								clear: both;
							}
						}
						.stripeBox{
							padding: 63px 60px 60px;
							.titleTypeCircle{
								margin-top: 37px;
							}
							.listFigure{
								li{
									margin-right: 2px;
									&:nth-of-type(2){
										margin-right: 0;
									}
									img{
										height: 229px;
									}
								}
							}
							.wrapBox{
								.box{
									width: 370px;
									&.left{
										float: left;
									}
									&.right{
										float: right;
									}
								}
							}
						}
						.borderBox{
							margin-top: 30px;
						}
					}
					&:nth-of-type(odd){
						.titleTypeLabel{
							margin-right: 60px;
						}
					}
					&:nth-of-type(even){
						flex-direction: row-reverse;
						.titleTypeLabel{
							margin-left: 60px;
						}
					}
					&.contSubBox01{
						.titleTypeLabel{
							background-image: url("../img/contents/education/new/schedule/parts_01.png"), url("../img/contents/wall_02_beige.gif");
						}
						&> .wrapBox{
							.stripeBox{
								.listTypeCircle{
									margin-right: -6px;
									padding-left: 5px;
									li{
										margin-right: 9px;
									}
								}
							}
						}
					}
					&.contSubBox02{
						.titleTypeLabel{
							background-image: url("../img/contents/education/new/schedule/parts_02.png"), url("../img/contents/wall_02_green.gif");
						}
						&> .wrapBox{
							margin-bottom: 40px;
							.stripeBox{
							}
						}
						.borderBox{
							margin-bottom: 21px;
							&+ .wrapBox{
								.imgBox{
									width: 194px;
								}
								.textBox{
									padding-top: 10px;
									width: 670px;
								}
							}
						}
					}
					&.contSubBox03{
						.titleTypeLabel{
							background-image: url("../img/contents/education/new/schedule/parts_03.png"), url("../img/contents/wall_02_blue.gif");
						}
						&> .wrapBox{
							margin-bottom: 18px;
							.textBox{
								.listTypecheck{
									margin-bottom: 30px;
									&+ img{
										width: 290px;
										height: auto;
									}
								}
								.blue{
									margin-top: 8px;
								}
							}
							.stripeBox{
							}
							&> .wrapBox{
								.textBox{
									padding-top: 32px;
									width: 445px;
									&.left{
										padding-left: 62px;
									}
									&.right{
										padding-left: 14px;
										img{
											margin-top: 12px;
											width: 108px;
											height: auto;
										}
									}
								}
							}
						}
					}
					&.contSubBox04{
						.titleTypeLabel{
							background-image: url("../img/contents/education/new/schedule/parts_04.png"), url("../img/contents/wall_02_yellow.gif");
						}
						&> .wrapBox{
							.stripeBox{
								padding-bottom: 41px;
							}
						}
					}
					&.contSubBox05{
						.titleTypeLabel{
							background-image: url("../img/contents/education/new/schedule/parts_05.png"), url("../img/contents/wall_02_pink.gif");
						}
						&> .wrapBox{
							margin-bottom: 22px;
							.textBox{
								.listTypecheck{
									margin-bottom: 21px;
								}
							}
							.stripeBox{
								padding-bottom: 35px;
								.wrapBox{
									.box{
										.titleTypeCircle{
											margin-top: -8px;
											letter-spacing: 0.02em;
										}
										&.right{
											padding: 3px 0 0 10px;
											.listFigure{
												li{
													width: 355px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox03{
				.innerBasic{
					padding-bottom: 120px;
					&> .wrapBox{
						&+ .wrapBox{
							margin-top: 57px;
							.textBox{
								width: 50%;
								&.left{
									padding-right: 10px;
									padding-left: 116px;
									.tableTypeDecoration{
										tbody{
											tr{
												th{
													width: 120px;
												}
											}
										}
									}
								}
								&.right{
									padding-right: 120px;
									padding-left: 15px;
									.tableTypeDecoration{
										tbody{
											tr{
												th{
													width: 115px;
												}
											}
										}
									}
								}
							}
							&+ .titleTypeBorder{
								margin-top: 0;
								margin-bottom: 26px;
							}
						}
					}
				}
				.tableTypeStripe{
					tbody{
						tr{
							th{
								width: 92px;
							}
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageSchedule {
		#Main{
			#ContBox01{
				.overviewImg{
					padding-bottom: 21px;
					.wrapBox{
						.imgBox{
							.titleTypeBasic{
								position: absolute;
								top: 50%;
								left: 0;
								transform: translateY(-50%);
								margin-bottom: 0;
								padding-left: 28px;
								text-align: left;
								span{
									position: relative;
									display: inline-block;
									&::before, &:after{
										content: "";
										position: absolute;
										display: block;
										width: 10px;
										height: 7px;
										background-repeat: no-repeat;
										background-position: center center;
										background-size: 10px auto;
									}
									&::before{
										top: 1px;
										left: -14px;
										background-image: url("../img/contents/top/parts_01_l.png")
									}
									&::after{
										bottom: -2px;
										right: -14px;
										background-image: url("../img/contents/top/parts_01_r.png")
									}
								}
							}
						}
						&> p{
							margin-top: -25px;
							padding-left: 16px;
							text-align: left;
						}
					}
				}
			}
			#ContBox02{
				.innerBasic{
					padding-top: 39px;
					padding-bottom: 13px;
				}
				.contSubBox{
					position: relative;
					margin-bottom: 60px;
					.titleTypeLabel{
						margin-bottom: 29px;
						height: 35px;
						background-size: auto 35px, auto 1px;
						&::before, &::after{
							z-index: 1;
							right: 0;
						}
						&::before{
							border-width: 0 0 17.5px 14px;
							border-color: transparent #fff #fff transparent;
						}
						&::after{
							border-width: 0 14px 17.5px 0;
							border-color: #fff #fff transparent transparent;
						}
					}
					&> .wrapBox{
						margin-bottom: 60px;
						&> .imgBox{
							float: left;
							width: 141px;
						}
						.textBox{
							.titleTypeBasic{
								margin-bottom: 10px;
								padding-top: 12px;
								padding-left: 153px;
								line-height: 28px;
								font-size: 18px;
								&+ *{
									clear: both;
								}
								&+ .listTypeCircle{
									padding-top: 8px;
									li{
										padding-left: 20px;
									}
								}
							}
							&+ .titleTypeBorder{
								margin-top: 26px;
								margin-bottom: 20px;
							}
							&+ .stripeBox{
								clear: both;
							}
						}
						.stripeBox{
							padding: 28px 15px 28px;
							.titleTypeCircle{
								margin-top: 24px;
							}
						}
						.borderBox{
							margin-top: 15px;
							padding: 21px 22px 16px;
							line-height: 28px;
							font-size: 16px;
						}
					}
					&.contSubBox01{
						.titleTypeLabel{
							background-image: url("../img/contents/education/new/schedule/parts_01_sp.png"), url("../img/contents/wall_02_beige.gif");
						}
						&> .wrapBox{
							.stripeBox{
								padding-bottom: 21px;
								.listTypeCircle{
									margin-right: -6px;
									padding-left: 5px;
									li{
										margin-right: 9px;
									}
								}
							}
						}
					}
					&.contSubBox02{
						.titleTypeLabel{
							background-image: url("../img/contents/education/new/schedule/parts_02_sp.png"), url("../img/contents/wall_02_green.gif");
						}
						&> .wrapBox{
							margin-bottom: 40px;
							.stripeBox{
								margin-top: 22px;
							}
						}
						.borderBox{
							margin-bottom: 21px;
							&+ .wrapBox{
								.imgBox{
									margin: 0 auto 7px;
									width: 194px;
								}
							}
						}
					}
					&.contSubBox03{
						.titleTypeLabel{
							background-image: url("../img/contents/education/new/schedule/parts_03_sp.png"), url("../img/contents/wall_02_blue.gif");
						}
						&> .wrapBox{
							margin-bottom: 18px;
							.textBox{
								.listTypecheck{
									padding-top: 10px;
									margin-bottom: 20px;
									&+ img{
										max-width: 290px;
										width: 100%;
										height: auto;
									}
								}
								.blue{
									margin-top: 12px;
								}
							}
							.stripeBox{
								margin-top: 20px;
								padding-bottom: 21px;
							}
							&> .wrapBox{
								.textBox{
									padding-left: 14px;
									&.left{
										margin-bottom: 10px;
										padding-top: 28px;
									}
									&.right{
										img{
											margin-top: 3px;
											width: 108px;
											height: auto;
										}
									}
								}
							}
						}
					}
					&.contSubBox04{
						.titleTypeLabel{
							background-image: url("../img/contents/education/new/schedule/parts_04_sp.png"), url("../img/contents/wall_02_yellow.gif");
						}
						&> .wrapBox{
							&> .textBox{
								margin-bottom: 19px;
							}
							.stripeBox{
								padding-bottom: 41px;
							}
						}
					}
					&.contSubBox05{
						.titleTypeLabel{
							background-image: url("../img/contents/education/new/schedule/parts_05_sp.png"), url("../img/contents/wall_02_pink.gif");
						}
						&> .wrapBox{
							margin-bottom: 22px;
							.textBox{
								.titleTypeBasic{
									padding-top: 0;
								}
								.listTypecheck{
									margin-bottom: 21px;
								}
							}
							.stripeBox{
								margin-top: 22px;
								padding-bottom: 6px;
								.wrapBox{
									.box{
										.titleTypeCircle{
											margin-top: -8px;
											letter-spacing: 0.02em;
										}
										&.right{
											padding: 17px 0 0 7px;
											.listFigure{
												li{
													max-width: 303px;
													width: 100%;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox03{
				.innerBasic{
					padding-bottom: 57px;
					&> .wrapBox{
						&:first-of-type{
							overflow-y: scroll;
							p{
								text-indent: -9999px;
								margin: 16px auto 27px;
								width: 168px;
								height: 18px;
								background-repeat: no-repeat;
								background-position: left top;
								background-image: url("../img/contents/education/new/schedule/parts_06.png");
								background-size: 168px auto;
							}
							img{
								width: 750px;
							}
						}
						&+ .wrapBox{
							margin-top: 35px;
							.textBox{
								.tableTypeDecoration{
									tbody{
										tr{
											th{
												width: 100px;
											}
										}
									}
								}
								&.left{
									.tableTypeDecoration{
										margin-bottom: 25px;
									}
								}
							}
							&+ .titleTypeBorder{
								margin-top: 50px;
								margin-bottom: 21px;
							}
						}
					}
				}
				.tableTypeStripe{
					tbody{
						tr{
							th{
								width: 65px;
							}
						}
					}
				}
			}
		}
    }
}

/*
新人看護師指導体制（マンツーマン教育）
-------------------------------------*/
#PageEducation.pagePreceptorship {
	#Main{
		#ContBox01{
			padding-bottom: 223px;
			&::after{
				margin-left: -414px;
			}
			.overviewImg{
				.titleTypeBasic{
					text-shadow: 10px 0 15px rgba(255, 255, 255, 1), 0 10px 15px rgba(255, 255, 255, 1), -10px 0 15px rgba(255,255, 255, 1), 0 -10px 15px rgba(255, 255, 255, 1),10px 0 15px rgba(255, 255, 255, 1), 0 10px 15px rgba(255, 255, 255, 1), -10px 0 15px rgba(255,255, 255, 1), 0 -10px 15px rgba(255, 255, 255, 1);
					font-weight: 700;
				}
			}
		}
		#ContBox02{
			counter-reset:number03;
			.wrapBox{
				.imgBox{
					img{
						border-radius: 50%;
					}
				}
				.textBox{
					.titleTypeCircle{
						text-align: left;
						&::before{
							counter-increment: number03;
							content: counter(number03);
							border-color: #ffdfed;
							text-align: center;
							line-height: 1;
							font-family: 'Lato', sans-serif;
							font-weight: 700;
						}
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pagePreceptorship {
		#Main{
			#ContBox01{
				.borderBox{
					margin-top: 51px;
				}
			}
			#ContBox02{
				.wrapBox{
					margin-bottom: 34px;
					.imgBox{
						width: 303px;
					}
					.textBox{
						padding-top: 4px;
						width: 644px;
						.titleTypeCircle{
							padding-left: 112px;
							line-height: 44px;
							letter-spacing: .05em;
							font-size: 28px;
							&::before{
								top: -1px;
								border-width: 12px;
								padding-top: 13px;
								width: 90px;
								height: 90px;
								font-size: 36px;
							}
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pagePreceptorship {
		#Main{
			#ContBox01{
				padding-bottom: 120px;
				&::after{
					margin-left: -157px;
					width: 500px;
				}
				.overviewImg{
					.wrapBox{
						.textBox{
							.titleTypeBasic{
								font-size: 18px;
							}
						}
					}
				}
				.borderBox{
					margin-top: 23px;
				}
			}
			#ContBox02{
				.wrapBox{
					margin-bottom: 34px;
					.imgBox{
						margin: 0 auto -8px;
						max-width: 303px;
						width: 100%;
					}
					.textBox{
						.titleTypeCircle{
							padding-left: 80px;
							line-height: 24px;
							letter-spacing: .05em;
							font-size: 18px;
							&::before{
								top: 3px;
								border-width: 9px;
								padding-top: 9px;
								width: 67px;
								height: 67px;
								font-size: 27px;
							}
						}
					}
				}
			}
		}
    }
}

/*
経験看護師採用（共通）
-------------------------------------*/
@media print,screen and (min-width: 768px) {
    #PageEducation.pageMid-career,
	#PageEducation.pageEducation-halfway,
	#PageEducation.pageEmployment {
		#SubBox{
			.listTypeMenu{
				li{
					width: calc((100% - 46px) / 4);
				}
			}
		}
	}
}

/*
経験看護師採用
-------------------------------------*/
#PageEducation.pageMid-career {
	#Main{
		#ContBox02{
			background-repeat: repeat-x, repeat;
			background-position: left 30px, left top;
			background-image: url("../img/contents/wall_pink_02.gif"), url("../img/contents/wall_05_pink.gif");
			background-color: #ffd1da;
			.titleTypeBasic{
				&::after{
					color: #ffe1e7;
				}
			}
			.titleTypeBasic{
				position: relative;
				z-index: 1;
				&::before{
					display: none;
				}
				&::after{
					content: attr(data-subtitle);
					position: absolute;
					z-index: -1;
					top: 50%;
					left: 50%;
					display: block;
					width: 100%;
					text-align: center;
					font-family: 'Dancing Script', cursive;
					font-style: italic;
					font-weight: 400;
				}
			}
			.innerBasic{
				position: relative;
				width: auto;
				background-color: #fff;
				&::before, &::after{
					content: "";
					position: absolute;
					bottom: 0;
					z-index: 0;
					display: block;
					background-repeat: no-repeat;
					background-position: center top;
					/* ↓アニメーション用のスタイル */
					opacity: 0;
					transition-delay: 6s;
				}
				&::before{
					left: 50%;
					background-image: url("../img/contents/education/mid-career/photo_04.png");
					/* ↓アニメーション用のスタイル */
					transform: translateX(-40px);
				}
				&::after{
					right: 50%;
					background-image: url("../img/contents/education/mid-career/photo_02.png");
					/* ↓アニメーション用のスタイル */
					transform: translateX(40px);
				}
				&.fadeIn{
					&::before, &::after{
						transition: 0.5s ease-in;
						transform: translate(0, 0);
						opacity: 1 !important;
					}
				}
			}
			.wrapBox{
				margin: 0 auto;
				.titleTypePoint{
					&:first-of-type{
						margin-top: 0;
					}
					&+ p{
						text-align: center;
					}
					&.titleTypePoint01{
						&+ p{
							position: relative;
							&::before, &::after{
								content: "";
								position: absolute;
								z-index: 0;
								display: block;
								background-repeat: no-repeat;
								background-position: center top;
								/* ↓アニメーション用のスタイル */
								opacity: 0;
								transition-delay: 6s;
							}
							&::before{
								background-image: url("../img/contents/education/mid-career/photo_01.png");
								/* ↓アニメーション用のスタイル */
								transform: translateX(-40px);
							}
							&::after{
								background-image: url("../img/contents/education/mid-career/photo_03.png");
								/* ↓アニメーション用のスタイル */
								transform: translateX(40px);
							}
							&.fadeIn{
								&::before, &::after{
									transition: 0.5s ease-in;
									transform: translate(0, 0);
									opacity: 1 !important;
								}
							}
						}
					}
				}
				.listTypeButton{
					li{
						a{
							color: #f54992;
							&.yellow{
								color: #f49400;
							}
							&.green{
								color: #16b982;
							}
							&.blue{
								color: #00b6dd;
							}
						}
					}
				}
			}
		}
		#ContBox03{
			.innerBasic{
				p{
					text-align: justify;
					letter-spacing: .05em;
					font-size: 16px;
				}
				.titleTypeSimple{
					font-size: 16px;
					color: #f54992;
				}
			}
		}
		#ContBox04{
			.wrapBox{
				background-color: #fff;
				.textBox{
					.titleTypeBasic{
						text-align: left;
					}
					.titleTypeSimple{
						color: #f54992;
					}
					.titleTypeLabel{
						display: block;
						font-family: 'Lato', sans-serif;
						font-weight: 400;
					}
					.buttonTypeBasic{
						&::after{
							width: 16px;
							height: 16px;
							background-image: url("../img/contents/plus_01.png");
							background-size: 16px auto;
						}
					}
				}
			}
		}
		#ContBox05{
			padding-top: 120px;
			padding-bottom: 94px;
			.titleTypeBasic{
				.titleTypeLabel{
					display: block;
					padding: 0;
					font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
					font-weight: 500;
					&::before, &::after{
						display: none;
					}
				}
			}
			.innerBasic{
				&.innerBasic01{
					margin: 0 auto;
					&::after{
						content: "";
						display: block;
						width: 16px;
						height: 17px;
						background-repeat: no-repeat;
						background-position: center center;
						background-image: url("../img/contents/arrow_05.png");
						background-size: 16px auto;
					}
				}
				&.innerBasic02{
					iframe{
						display: block;
					}
					.txtTypeRight{
						text-align: right;
						font-size: 14px;
						a{
							color: #888;
						}
					}
				}
			}
			&> .titleTypeSimple{
				font-size: 16px;
				text-align: center;
				color: #f54992;
				&+ p{
					letter-spacing: .05em;
					font-size: 16px;
				} 
			}
			.borderBox{
				position: relative;
				&::after{
					content: "";
					position: absolute;
					z-index: 0;
					display: block;
					background-repeat: no-repeat;
					background-position: center top;
					/* ↓アニメーション用のスタイル */
					opacity: 0;
					transition-delay: 6s;
				}
				&::after{
					background-image: url("../img/contents/education/mid-career/photo_05.png");
					/* ↓アニメーション用のスタイル */
					transform: translateX(40px);
				}
				&.fadeIn{
					&::after{
						transition: 0.5s ease-in;
						transform: translate(0, 0);
						opacity: 1 !important;
					}
				}
				p{
					letter-spacing: .05em;
					font-size: 16px;
					&.titleTypeBasic{
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.modaal-container{
		background-color: transparent;
	}
	.modaal-content-container{
		.wrapBox{
			margin-bottom: 0;
			.imgBox{
				img{
					width: 100%;
					height: auto;
				}
			}
			.textBox{
				position: relative;
				width: auto;
				background-color: #fff;
				.titleTypeSimple{
					color: #ff65a6;
				}
				.titleTypeLabel{
					position: relative;
					display: block;
					padding-left: 10px;
					//width: 93px;
					width: 105px;
					background-color: #ff65a6;
					line-height: 26px;
					font-family: 'Lato', sans-serif;
					font-weight: 400;
					font-size: 14px;
					color: #fff;
					&::after, &::before{
						content: "";
						position: absolute;
						right: -7.5px;
						margin: auto;
						border-style: solid;
						width: 0;
						height: 0;
					}
					&::after{
						top: 0;
						border-color: #ff65a6 transparent #ff65a6 transparent ;
						border-width: 0 9px 13px 0;
					}
					&::before{
						bottom: 0;
						border-color: #ff65a6 transparent transparent #ff65a6;
						border-width: 13px 9px 0 0;
					}
				}
				p{
					margin-bottom: 20px;
				}
				.buttonTypeNext{
					position: absolute;
					right: 0;
					top: 0;
					display: block;
					border-bottom: 1px solid #ff65a6;
					text-decoration: none;
					color: #ff65a6;
					span{
						position: relative;
						display: block;
						text-align: left;
						&::after{
							content: "";
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							display: block;
							width: 7px;
							height: 11px;
							background-repeat: no-repeat;
							background-position: center center;
							background-image: url("../img/contents/arrow_02.png");
							background-size: 7px auto;
						}
					}
				}
			}
		}
		.listTypeButton{
			li{
				.borderBox{
					position: relative;
					display: block;
					border-style: solid;
					border-color: #f6f6f6;
					background-color: #fff;
					text-decoration: none;
					&.pink{
						border-color: #ffe0ed;
					}
					&::after{
						content: "";
						position: absolute;
						top: 50%;
						width: 26px;
						height: 26px;
						transform: translateY(-50%);
						display: block;
						border-radius: 50%;
						background-repeat: no-repeat;
						background-position: center center;
						background-image: url("../img/contents/arrow_02_w.png");
						background-size: 7px auto;
						background-color: #ff65a6;
					}
					span{
						display: flex;
						flex-wrap: nowrap;
						justify-content: flex-start;
						align-items: center;
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageMid-career {
		#Main{
			#ContBox01{
				padding-top: 119px;
				padding-bottom: 116px;
				.boxTypeBalloon{
					margin: 0 auto 10px;
					width: 653px;
					height: 84px;
					background-image: url("../img/contents/balloon_03_pink.png");
					background-size: 653px auto;
					span{
						min-height: 54px;
						letter-spacing: 0;
						font-size: 22px;
						font-weight: 400;
					}
				}
				.titleTypeBasic{
					letter-spacing: .1em;
					font-size: 44px;
					font-weight: 700;
				}
			}
			#ContBox02{
				padding-top: 135px;
				padding-right: 30px;
				padding-bottom: 120px;
				padding-left: 30px;
				background-size: 1px 386px, 8px auto;
				.titleTypeBasic{
					margin-bottom: 83px;
					letter-spacing: .01em;
					font-size: 36px;
					&::after{
						transform: translate(-52%, -71%) rotate(-10deg);
						font-size: 180px;
					}
				}
				.innerBasic{
					padding-top: 82px;
					padding-bottom: 120px;
					&::before{
						margin-left: -676px;
						width: 352px;
						height: 360px;
						background-size: 352px;
					}
					&::after{
						margin-right: -645px;
						width: 392px;
						height: 345px;
						background-size: 393px;
					}
				}
				.wrapBox{
					width: 1000px;
					.titleTypePoint{
						margin-top: 83px;
						margin-bottom: 19px;
						&+ p{
							margin-bottom: 51px;
							line-height: 38px;
							letter-spacing: .18em;
							font-size: 20px;
							&+ .titleTypePoint{
								margin-top: 71px;
							}
						}
						&.titleTypePoint01{
							&+ p{
								&::before{
									top: -341px;
									left: -139px;
									width: 286px;
									height: 870px;
									background-size: 286px;
								}
								&::after{
									top: -372px;
									right: -197px;
									width: 380px;
									height: 931px;
									background-size: 380px;
								}
							}
						}
					}
					.listTypeButton{
						text-align: center;
						font-size: 0;
						li{
							float: none;
							display: inline-block;
							vertical-align: top;
							margin-right: 10px;
							margin-left: 10px;
							width: 319px;
							&:nth-of-type(2n){
								margin-right: 10px;
							}
							&:nth-of-type(2n+1){
								clear: none;
							}
							&:nth-of-type(3n){
								margin-right: 0;
							}
							&:nth-of-type(3n+1){
								margin-left: 0;
							}
							&:nth-of-type(3){
								margin-top: 0;
							}
						}
						&.listTypeButton03{
							li{
								&:nth-of-type(1){
									display: block;
									margin-right: auto;
									margin-left: auto;
								}
								&:nth-of-type(2){
									margin-left: 0;
								}
								&:nth-of-type(3){
									margin-right: 10px;
								}
								&:nth-of-type(2), &:nth-of-type(3){
									margin-top: 20px;
								}
								&:nth-of-type(4){
									margin-right: 0;
									margin-left: 10px;
								}
							}
						}
					}
				}
			}
			#ContBox03{
				padding-top: 96px;
				padding-bottom: 107px;
				.innerBasic{
					padding-top: 29px;
					p{
						line-height: 32px;
						&+ .listTypeNumber{
							margin-top: 37px;
						}
					}
					.listTypeNumber{
						li{
							margin-bottom: 7px;
						}
						&+ .titleTypeSimple{
							margin-top: 48px;
						}
					}
					.titleTypeSimple{
						margin-bottom: 11px;
					}
				}
			}
			#ContBox04{
				padding-top: 96px;
				padding-bottom: 123px;
				.titleTypeBasic{
					margin-bottom: 53px;
				}
				.wrapBox{
					position: relative;
					margin-bottom: 0;
					width: 485px;
					&.wrapBox01{
						float: left;
					}
					&.wrapBox02{
						float: right;
					}
					.imgBox{
						width: 485px;
					}
					.textBox{
						padding: 35px 43px 120px;
						width: 485px;
						.titleTypeBasic{
							margin-bottom: 13px;
							line-height: 44px;
							font-size: 28px;
							&+ .txtTypeRight{
								margin-bottom: 20px;
							}
						}
						.titleTypeSimple{
							margin-bottom: 14px;
							line-height: 32px;
							font-size: 20px;
						}
						.titleTypeLabel{
							margin-bottom: 6px;
							width: 93px;
							&::after, &::before{
								right: -8.25px;
							}
						}
						.buttonTypeBasic{
							position: absolute;
							left: 40px;
							bottom: 59px;
							width: 400px;
						}
					}
				}
			}
			#ContBox05{
				padding-top: 120px;
				padding-bottom: 94px;
				.titleTypeBasic{
					.titleTypeLabel{
						margin: 0 auto 8px;
						width: 204px;
						height: 30px;
						line-height: 30px;
						font-size: 16px;
					}
				}
				.innerBasic{
					&.innerBasic01{
						padding-top: 5px;
						width: 460px;
						&::after{
							margin: 10px auto 23px;
						}
						.listTypeCircle{
							margin-left: 27px;
							li{
								margin-bottom: 0;
							}
						}
					}
					&.innerBasic02{
						padding-top: 10px;
						padding-bottom: 10px;
						iframe{
							width: 1000px;
							height: 440px;
						}
						.txtTypeRight{
							margin-top: 18px;
							letter-spacing: 0.05em;
							font-size: 14px;
						}
						.titleTypeSimple{
							margin-top: -31px;
							margin-bottom: 5px;
						}
						.listTypeCircle{
							li{
								margin-bottom: 0;
							}
						}
					}
				}
				&> .titleTypeSimple{
					line-height: 32px;
					&+ p{
						line-height: 32px;
					} 
				}
				.borderBox{
					margin: 50px 30px 50px;
					&::after{
						top: -386px;
						right: 50%;
						margin-right: -504px;
						width: 237px;
						height: 580px;
						background-size: 237px;
					}
					p{
						margin-bottom: 5px;
						line-height: 32px;
						letter-spacing: .05em;
						&.titleTypeBasic{
							line-height: 38px;
							letter-spacing: .1em;
							font-size: 24px;
						}
					}
				}
			}
		}
		.modaal-container{
			width: 740px;
		}
		.modaal-content-container{
			width: 740px;
			.wrapBox{
				margin-bottom: 0;
				width: 740px;
				.imgBox{
					width: 740px;
				}
				.textBox{
					padding: 42px 43px 17px;
					.titleTypeSimple{
						margin-bottom: 14px;
						line-height: 32px;
						font-size: 20px;
					}
					.titleTypeLabel{
						margin-bottom: 6px;
						&::after, &::before{
							right: -8.5px;
						}
					}
					.buttonTypeNext{
						&:hover{
							opacity: .7;
							transition: .5s;
						}
						span{
							padding-left: 9px;
							width: 150px;
							height: 56px;
							line-height: 56px;
							font-size: 16px;
							&::after{
								right: 11px;
							}
						}
					}
				}
			}
			.listTypeButton{
				&::after {
					content: "";
					display: block;
					clear: both;
				}
				li{
					float: left;
					margin-top: 20px;
					margin-right: 20px;
					width: 360px;
					&:nth-of-type(2n){
						margin-right: 0;
					}
					.borderBox{
						border-width: 6px;
						line-height: 32px;
						font-size: 20px;
						&::after{
							right: 25px;
						}
						&:hover{
							opacity: .7;
							transition: .5s;
						}
						span{
							padding: 0 50px 0 20px;
							min-height: 88px;
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageMid-career {
		#Main{
			#ContBox01{
				padding-top: 72px;
				padding-bottom: 43px;
				.boxTypeBalloon{
					margin: 0 auto 7px;
					width: 319px;
					height: 87px;
					background-image: url("../img/contents/balloon_07_pink.png");
					background-size: 319px auto;
					span{
						min-height: 67px;
						line-height: 22px;
						text-align: center;
						letter-spacing: 0;
						font-size: 16px;
						font-weight: 400;
					}
				}
				.titleTypeBasic{
					line-height: 36px;
					letter-spacing: .1em;
					font-size: 26px;
					font-weight: 700;
				}
			}
			#ContBox02{
				padding-top: 98px;
				padding-bottom: 50px;
				background-size: 1px 386px, 8px auto;
				.titleTypeBasic{
					margin-bottom: 33px;
					line-height: 36px;
					letter-spacing: .01em;
					font-size: 26px;
					&::after{
						transform: translate(-53%, -71%) rotate(-10deg);
						font-size: 90px;
					}
				}
				.innerBasic{
					padding-top: 55px;
					padding-bottom: 137px;
					&::before{
						margin-left: -116px;
						width: 106px;
						height: 109px;
						background-size: 106px;
					}
					&::after{
						margin-right: -119px;
						width: 118px;
						height: 103px;
						background-size: 118px;
					}
				}
				.wrapBox{
					.titleTypePoint{
						margin-top: 55px;
						margin-bottom: 14px;
						line-height: 30px;
						&+ p{
							margin-bottom: 21px;
							line-height: 30px;
							letter-spacing: .18em;
							font-size: 16px;
							&+ .titleTypePoint{
								margin-top: 71px;
							}
						}
						&.titleTypePoint01{
							&+ p{
								&::before{
									top: -184px;
									left: -12px;
									width: 72px;
									height: 235px;
									background-size: 72px;
								}
								&::after{
									top: -201px;
									right: -22px;
									width: 96px;
									height: 235px;
									background-size: 96px;
								}
							}
						}
					}
					.listTypeButton{
						li{
							a{
								line-height: 28px;
								&::after{
									right: 20px;
								}
								span{
									padding-left: 20px;
									min-height: 80px;
								}
							}
						}
					}
				}
			}
			#ContBox03{
				padding-top: 86px;
				padding-bottom: 50px;
				.innerBasic{
					padding-top: 0;
					p{
						line-height: 28px;
						&+ .listTypeNumber{
							margin-top: 17px;
						}
					}
					.listTypeNumber{
						li{
							margin-bottom: 16px;
						}
						&+ .titleTypeSimple{
							margin-top: 48px;
						}
					}
					.titleTypeSimple{
						margin-bottom: 13px;
					}
				}
			}
			#ContBox04{
				padding-top: 96px;
				padding-bottom: 75px;
				.titleTypeBasic{
					margin-bottom: 41px;
				}
				.wrapBox{
					&.wrapBox01{
						margin-bottom: 15px;
					}
					&.wrapBox02{
						margin-bottom: 0;
					}
					.imgBox{
					}
					.textBox{
						padding: 24px 25px 45px;
						.titleTypeBasic{
							margin-bottom: 19px;
							line-height: 28px;
							letter-spacing: 0;
							font-size: 18px;
							&+ .txtTypeRight{
								margin-bottom: 22px;
								line-height: 24px;
								font-size: 14px;
							}
						}
						.titleTypeSimple{
							margin-bottom: 14px;
							line-height: 28px;
							font-size: 18px;
						}
						.titleTypeLabel{
							margin-bottom: 11px;
							width: 93px;
							&::before{
								right: -8.5px;
								border-width: 13px 0 13px 9px;
								border-color: transparent transparent transparent #ff65a6;
							}
							&::after{
								display: none;
							}
						}
						.buttonTypeBasic{
							margin: -4px auto 0;
							width: auto;
							max-width: 295px;
						}
					}
				}
			}
			#ContBox05{
				padding-top: 75px;
				padding-bottom: 83px;
				.titleTypeBasic{
					.titleTypeLabel{
						margin: 0 auto 8px;
						width: 172px;
						height: 25px;
						line-height: 25px;
						font-size: 14px;
					}
				}
				.innerBasic{
					&.innerBasic01{
						padding: 0 48px 0;
						&::after{
							margin: -12px auto 6px;
						}
						.listTypeCircle{
							li{
								margin-bottom: 0;
								padding-left: 20px
							}
						}
					}
					&.innerBasic02{
						padding-top: 10px;
						padding-bottom: 10px;
						iframe{
							width: 100%;
							height: 220px;
						}
						.txtTypeRight{
							margin-top: 9px;
							font-size: 14px;
						}
						.titleTypeSimple{
							margin-top: 10px;
							margin-bottom: 5px;
							font-size: 16px;
						}
						.listTypeCircle{
							li{
								margin-bottom: 0;
							}
						}
					}
				}
				&> .titleTypeSimple{
					line-height: 32px;
					margin-bottom: -2px;
					&+ p{
						line-height: 28px;
					} 
				}
				.borderBox{
					margin: 37px 0 34px;
					padding-right: 5px;
					padding-left: 5px;
					&::after{
						top: -37px;
						right: 50%;
						margin-right: -163px;
						width: 62px;
						height: 154px;
						background-size: 62px;
					}
					p{
						margin-bottom: 2px;
						line-height: 24px;
						letter-spacing: .05em;
						font-size: 14px;
						&.titleTypeBasic{
							line-height: 28px;
							letter-spacing: .1em;
							font-size: 16px;
						}
					}
				}
			}
		}
		.modaal-content-container{
			.wrapBox{
				margin-bottom: 0;
				.textBox{
					padding: 24px 25px 8px;
					.titleTypeSimple{
						margin-bottom: 14px;
						line-height: 28px;
						font-size: 14px;
					}
					.titleTypeLabel{
						margin-bottom: 11px;
						&::before{
							right: -7.5px;
							border-width: 13px 0 13px 9px;
							border-color: transparent transparent transparent #ff65a6;
						}
						&::after{
							display: none;
						}
					}
					.buttonTypeNext{
						span{
							padding-left: 0;
							width: 115px;
							height: 42px;
							line-height: 42px;
							font-size: 14px;
							&::after{
								right: 11px;
							}
						}
					}
				}
			}
			.listTypeButton{
				li{
					margin-top: 15px;
					&:last-of-type{
						margin-bottom: 0;
					}
					.borderBox{
						border-width: 6px;
						line-height: 32px;
						font-size: 20px;
						&::after{
							right: 19px;
						}
						span{
							padding: 0 50px 0 20px;
							min-height: 80px;
						}
					}
				}
			}
		}
    }
}

/*
経験看護師フォローアップ体制
-------------------------------------*/
#PageEducation.pageEducation-halfway {
	#Main{
		#ContBox02{
			.listTypeButton{
				li{
					p{
						margin-bottom: 0;
					}
				}
			}
		}
		#ContBox04{
			.titleTypePoint{
				.titleTypeLabel{
					display: block;
					padding: 0;
					font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
					font-weight: 500;
					&::before, &::after{
						display: none;
					}
				}
			}
			.listTypeCircle{
				li{
					margin-bottom: 0;
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageEducation-halfway {
		#Main{
			#ContBox01{
				&::after{
					margin-left: -202px;
				}
			}
			#ContBox02{
				margin-top: -121px;
				.innerBasic{
					padding-bottom: 92px;
				}
				.listTypeButton{
					li{
						margin-top: 0;
						padding: 30px 0 33px;
						width: 320px;
						&:nth-of-type(3n){
							margin-right: 0;
						}
						&:nth-of-type(2n+1){
							clear: none;
						}
						&:nth-of-type(2n){
							margin-right: 20px;
						}
						.titleTypeBasic{
							line-height: 40px;
							font-size: 24px;
						}
					}
				}
			}
			#ContBox03{
				.innerBasic{
					padding-bottom: 92px;
				}
				.fillBox{
					margin-bottom: 90px;
					padding-top: 19px;
					padding-bottom: 19px;
				}
				.tableTypeBasic{
					thead{
						tr{
							th{
								&:nth-of-type(1){
									width: 272px;
									&+ th +th{
										width: 230px;
									}
								}
							}
						}
					}
				}
			}
			#ContBox04{
				.titleTypePoint{
					.titleTypeLabel{
						margin: 0 auto 8px;
						width: 204px;
						height: 30px;
						line-height: 30px;
						font-size: 16px;
					}
				}
				.fillBox{
					margin-bottom: 56px;
					padding-top: 19px;
					padding-bottom: 19px;
				}
				.innerBasic{
					padding-bottom: 114px;
				}
				.listTypeCircle{
					margin-bottom: 15px;
					padding-right: 297px;
					padding-left: 297px;
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageEducation-halfway {
		#Main{
			#ContBox01{
				padding-bottom: 105px;
				&::after{
					margin-left: -93px;
				}
			}
			#ContBox02{
				margin-top: -59px;
				.innerBasic{
					padding-bottom: 76px;
				}
				.listTypeButton{
					li{
						padding: 19px 0 35px;
						.titleTypeBasic{
							margin-bottom: 17px;
							font-size: 20px;
						}
						p{
							line-height: 28px;
							font-size: 16px;
						}
					}
				}
			}
			#ContBox03{
				.fillBox{
					margin-top: -12px;
				}
				.innerBasic{
					padding-bottom: 35px;
				}
				.titleTypePoint{
					
				}
				.tableTypeBasic{
					thead{
						tr{
							th{
								&:nth-of-type(1){
									width: 91px;
									&+ th +th{
										width: 91px;
									}
								}
							}
						}
					}
				}
			}
			#ContBox04{
				.titleTypePoint{
					.titleTypeLabel{
						margin: 0 auto 8px;
						width: 172px;
						height: 25px;
						line-height: 25px;
						font-size: 14px;
					}
				}
				.fillBox{
					margin-top: -12px;
					margin-bottom: 41px;
				}
				.innerBasic{
					padding-bottom: 70px;
				}
				.listTypeCircle{
					margin-bottom: 15px;
					padding-right: 48px;
					padding-left: 48px;
				}
			}
		}
    }
}

/*
経験看護師再就業支援
-------------------------------------*/
#PageEducation.pageEmployment {
	#Main{
		#ContBox02{
			.listTypeCircle{
				li{
					margin-bottom: 0;
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageEmployment {
		#Main{
			#ContBox01{
				&::after{
					margin-left: -365px;
				}
			}
			#ContBox02{
				.fillBox{
					margin-bottom: 57px;
					padding-top: 19px;
					padding-bottom: 19px;
				}
				.innerBasic{
					padding-bottom: 115px;
				}
				.listTypeCircle{
					margin-bottom: 32px;
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageEmployment {
		#Main{
			#ContBox01{
				&::after{
					margin-left: -144px;
				}
			}
			#ContBox02{
				.fillBox{
					margin-top: -12px;
					margin-bottom: 51px;
				}
				.innerBasic{
					padding-bottom: 115px;
				}
				.titleTypeBorder{
					margin-bottom: 16px;
				}
				.listTypeCircle{
					margin-bottom: 16px;
				}
			}
		}
    }
}

/*
認定看護師サポート体制
-------------------------------------*/
#PageEducation.pageSupport {
	#Main{
		#ContBox02{
			.stripeBox{
				border-radius: 8px;
				padding: 20px;
				.wrapBox{
					position: relative;
					background-color: #fff;
					&> p{
						position: absolute;
						padding-top: 13px;
						width: 71px;
						height: 78px;
						background-repeat: no-repeat;
						background-position: left top;
						background-image: url("../img/contents/balloon_04_pink.png");
						background-size: 71px auto;
						line-height: 20px;
						text-align: center;
						font-family: 'M PLUS 1p', sans-serif;
						font-weight: 400;
						font-size: 14px;
						color: #fff;
						b{
							font-family: 'Lato', sans-serif;
							font-weight: 700px;
							font-size: 24px;
						}
						span{
							font-size: 10px;
						}
					}
					.tableTypeBasic{
						margin-bottom: 0;
						border-top: none;
						tbody{
							tr{
								th, td{
									font-size: 16px;
								}
								th{
									border-bottom-color: #fff;
									background-color: #effcf8;
									color: #37a983;
								}
								td{
									border-top: 1px solid #eaeaea;
									border-color: #eaeaea;
									color: #37a983;
									&:nth-of-type(2){
										padding: 0 0;
										img{
											margin-top: 2px;
											margin-bottom: 2px;
											height: auto;
											&:last-of-type{
												margin-right: 0;
											}
										}
									}
									&:nth-of-type(3){
										padding: 0 0;
										text-align: center;
										color: #f54992;
										span{
											font-family: 'Lato', sans-serif;
											font-weight: 900;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		#ContBox03{
			.wrapBox{
				.textBox{
					.titleTypeBorder{
						margin-top: 0;
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageSupport {
		#Main{
			#ContBox01{
				&::after{
					margin-left: -139px;
				}
			}
			#ContBox02{
				.fillBox{
					margin-bottom: 60px;
					padding-top: 19px;
					padding-bottom: 19px;
				}
				.stripeBox{
					border-radius: 8px;
					padding: 20px;
					.wrapBox{
						border-radius: 8px;
						padding: 30px;
						&> p{
							top: -50px;
							right: 36px;
						}
						&::before{
							content: "";
							position: absolute;
							top: 0;
							left: 225px;
							width: 20px;
							height: 100%;
							background-color: #fff;
						}
						.tableTypeBasic{
							tbody{
								tr{
									th, td{
										line-height: 28px;
										font-size: 16px;
									}
									th{
										padding: 10px 5px;
										width: 195px;
										text-align: center;
									}
									td{
										&:nth-of-type(1){
											padding: 10.5px 15px 10.5px 22px;
											width: 476px;
										}
										&:nth-of-type(2){
											img{
												margin-right: 10px;
												width: 20px;
											}
										}
										&:nth-of-type(3){
											width: 80px;
											font-size: 14px;
											span{
												font-size: 24px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox03{
				padding-bottom: 150px;
				.wrapBox{
					.textBox{
						padding-top: 121px;
						.listTypeNumber{
							margin-bottom: 53px;
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageSupport {
		#Main{
			#ContBox02{
				.fillBox{
					margin-bottom: 60px;
				}
				.stripeBox{
					border-radius: 6px;
					padding: 10px;
					.wrapBox{
						border-radius: 6px;
						padding: 15px;
						&> p{
							top: -18px;
							right: 5px;
						}
						.tableTypeBasic{
							tbody{
								tr{
									th, td{
										line-height: 28px;
										font-size: 16px;
									}
									th{
										padding: 10px 10px;
									}
									td{
										line-height: 24px;
										&:nth-of-type(1){
											padding: 13px 15px 13px 11px;
											width: 61%;
										}
										&:nth-of-type(2){
											img{
												margin-right: 4px;
												width: 16px;
											}
										}
										&:nth-of-type(3){
											width: 13%;
											font-size: 10px;
											span{
												font-size: 22px;
											}
										}
									}
									&.border-bottom_none{
										td{
											border-bottom: none;
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox03{
				padding-bottom: 150px;
				.wrapBox{
					margin-top: -11px;
					.textBox{
						.listTypeNumber{
							margin-bottom: 23px;
						}
					}
				}
			}
		}
    }
}

/*
認定看護師会からメッセージ
-------------------------------------*/
#PageEducation.pageMessage {
	#Main{
		#ContBox01{
			padding-bottom: 0;
			.overviewImg{
				margin-bottom: 0;
			}
		}
		#ContBox02{
			.wrapBox{
				.textBox{
					.titleTypeBorder{
						margin-top: 0;
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageEducation.pageMessage {
		#Main{
 			#ContBox02{
				.wrapBox{
					.textBox{
						.titleTypeBorder{
							margin-bottom: 20px;
						}
						p{
							&:last-of-type{
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageEducation.pageMessage {
		#Main{
 			#ContBox02{
				.wrapBox{
					.textBox{
						.titleTypeBorder{
							margin-top: 30px;
							margin-bottom: 25px;
						}
					}
				}
			}
		}
    }
}

/*
病棟（部署）紹介　共通
-------------------------------------*/
#PageIntroduction {
}
@media print,screen and (min-width: 768px) {
	#PageIntroduction {
		#MainImg{
			background-image: url("../img/contents/introduction/mainimage.jpg");
			.innerBasic{
				transform: translate(-50%, 66%);
			}
			.titleTypeBasic{
				&::before{
					width: 684px;
					height: 388px;
					background-image: url("../img/contents/introduction/mainimage_wall.png");
					background-size: 684px auto;
					transform: translate(-50%, -43%);
				}
				&::after{
					transform: translate(-51%, -56%) rotate(-10deg);
				}
			}
		}
	}
}
@media print,screen and (max-width: 767px) {
	#PageIntroduction {
		#MainImg{
			background-image: url("../img/contents/introduction/mainimage_sp.jpg");
			.innerBasic{
				transform: translate(-50%, -52%);
			}
			.titleTypeBasic{
				&::before{
					width: 342px;
					height: 194px;
					background-image: url("../img/contents/introduction/mainimage_wall_sp.png");
					background-size: 342px auto;
					transform: translate(-50%, -45%);
				}
				&::after{
					transform: translate(-53%, -55%) rotate(-10deg);
				}
			}
		}
	}
}

/*
病棟（部署）紹介
-------------------------------------*/
#PageIntroduction.pageIndex {
}
@media print,screen and (min-width: 768px) {
    #PageIntroduction.pageIndex {
		#Main{
 			#ContBox01{
				.innerBasic{
					padding-bottom: 291px;
				}
				&::after{
					margin-left: -689px;
					width: 2000px;
				}
			}
 			#ContBox02{
				padding-top: 58px;
				padding-bottom: 90px;
				.wrapBox{
					margin-bottom: 60px;
					.textBox{
						.titleTypeBorder{
							margin-top: 60px;
						}
						.buttonTypeBasic{
							margin-top: 48px;
						}
					}
				}
			}
 			#ContBox03{
				.titleTypeBorder{
					margin-bottom: 30px;
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageIntroduction.pageIndex {
		#Main{
 			#ContBox01{
				margin-bottom: -20px;
				//padding-bottom: 94px;
				&::after{
					left: 0;
					margin-left: 16px;
					width: 500px;
				}
			}
			#ContBox02{
				padding-top: 0;
				padding-bottom: 20px;
				.wrapBox{
					margin-bottom: 60px;
					.textBox{
						.titleTypeBorder{
							margin-top: 31px;
						}
						.buttonTypeBasic{
							margin-top: -6px;
						}
					}
				}
			}
 			#ContBox03{
				.titleTypeBorder{
					margin-bottom: 20px;
				}
			}
		}
    }
}

/*
多職種チーム・横断活動部署
-------------------------------------*/
#PageIntroduction.pageCooperation,
#PageIntroduction.pageEfforts{
}
@media print,screen and (min-width: 768px) {
    #PageIntroduction.pageCooperation,
	#PageIntroduction.pageEfforts{
		#Main{
 			#ContBox01{
				padding-bottom: 77px;
				.innerBasic{
					padding-top: 10px;
				}
				.wrapBox{
					margin-bottom: -181px;
					&:not(:first-of-type){
						.imgBox.right{
							margin-top: 30px;
						}
					}
					&:last-of-type{
						margin-bottom: 0;
					}
					&.layoutTypeSideDeco{
						.imgBox{
							&.right{
								.imgWrapBox{
									&::after{
										left: 11px;
									}
								}
								.titleTypeBorder{
									margin-left: 60px;
								}
								dl{
									padding-left: 60px;
								}

							}
							.titleTypeBorder{
								margin-top: 87px;
								margin-bottom: 29px;
							}
							dl{
								dd{
									margin-bottom: 19px;
									p{
										line-height: 32px;
										text-align: left;
										letter-spacing: 0.045em;
										font-size: 16px;
									}
								}
							}
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageIntroduction.pageCooperation,
	#PageIntroduction.pageEfforts{
		#Main{
 			#ContBox01{
				.innerBasic{
					padding-top: 42px;
					padding-right: 0;
					padding-left: 0;
				}
				.wrapBox{
					//padding-right: 0;
					&.layoutTypeSideDeco{
						.imgBox{
							margin-bottom: 52px;
							padding-bottom: 0;
							.imgWrapBox{
								&::after{
									right: auto;
									left: 6px;
								}
							}
							.titleTypeBorder{
								margin-top: 50px;
								margin-bottom: 19px;
							}
							dl{
								dd{
									margin-bottom: 11px;
									p{
										line-height: 28px;
										text-align: left;
										letter-spacing: 0.045em;
										font-size: 16px;
									}
								}
							}
						}
					}
				}
			}
		}

    }
}

/*
多職種チーム
-------------------------------------*/
#PageIntroduction.pageCooperation {
}
@media print,screen and (min-width: 768px) {
    #PageIntroduction.pageCooperation {
		#Main{
 			/*#ContBox01{
				padding-bottom: 77px;
				.innerBasic{
					padding-top: 10px;
				}
				.wrapBox{
					margin-bottom: -181px;
					&:not(:first-of-type){
						.imgBox.right{
							margin-top: 30px;
						}
					}
					&:last-of-type{
						margin-bottom: 0;
					}
					&.layoutTypeSideDeco{
						.imgBox{
							&.right{
								.imgWrapBox{
									&::after{
										left: 11px;
									}
								}
								.titleTypeBorder{
									margin-left: 60px;
								}
								dl{
									padding-left: 60px;
								}

							}
							.titleTypeBorder{
								margin-top: 87px;
								margin-bottom: 29px;
							}
							dl{
								dd{
									margin-bottom: 19px;
									p{
										line-height: 32px;
										text-align: left;
										letter-spacing: 0.045em;
										font-size: 16px;
									}
								}
							}
						}
					}
				}
			}*/
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageIntroduction.pageCooperation {
		#Main{
 			/*#ContBox01{
				.innerBasic{
					padding-top: 42px;
					padding-right: 0;
					padding-left: 0;
				}
				.wrapBox{
					//padding-right: 0;
					&.layoutTypeSideDeco{
						.imgBox{
							margin-bottom: 52px;
							padding-bottom: 0;
							.imgWrapBox{
								&::after{
									right: auto;
									left: 6px;
								}
							}
							.titleTypeBorder{
								margin-top: 50px;
								margin-bottom: 19px;
							}
							dl{
								dd{
									margin-bottom: 11px;
									p{
										line-height: 28px;
										text-align: left;
										letter-spacing: 0.045em;
										font-size: 16px;
									}
								}
							}
						}
					}
				}
			}*/
		}
    }
}

/*
横断活動部署
-------------------------------------*/
#PageIntroduction.pageEfforts {
}
@media print,screen and (min-width: 768px) {
    #PageIntroduction.pageEfforts {
		/*#Main{
 			#ContBox01{
				&::after{
					width: 1000px;
				}
			}
		}*/
    }
}
@media print,screen and (max-width: 767px) {
    #PageIntroduction.pageEfforts {
		/*#Main{
 			#ContBox01{
				padding-bottom: 107px;
				&::after{
					left: 0;
					margin-left: 116px;
					width: 1000px;
				}
			}
		}*/
    }
}

/*
病棟・センター等紹介
-------------------------------------*/
#PageIntroduction.pageIndex {
}
@media print,screen and (min-width: 768px) {
    #PageIntroduction.pageIndex {

    }
}
@media print,screen and (max-width: 767px) {
    #PageIntroduction.pageIndex {
    
    }
}

/*
病棟・センター等紹介（詳細）
-------------------------------------*/
#PageIntroduction.pageEntry {
}
@media print,screen and (min-width: 768px) {
    #PageIntroduction.pageEntry {
		#Main{
			#ContBox01{
				padding-bottom: 10px;
			}
			#ContBox02{
				.titleTypeBubble{
					margin: -27px auto 19px;
					width: 155px;
				}
				.borderBox{
					padding-top: 23px;
					padding-bottom: 23px;
				}
				.titleTypeBorder{
					margin-top: 59px;
					&+ .textBox{
						line-height: 32px;
						font-size: 16px;
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageIntroduction.pageEntry {
		#Main{
			#ContBox01{
				padding-bottom: 8px;
			}
			#ContBox02{
				.titleTypeBubble{
					margin-top: -9px;
					margin-bottom: 19px;
					padding-top: 2px;
					padding-bottom: 2px;
				}
				.borderBox{
					margin-bottom: 30px;
					padding-top: 15px;
					padding-bottom: 15px;
				}
				.titleTypeBorder{
					margin-top: 34px;
					margin-bottom: 18px;
					&+ .textBox{
						line-height: 28px;
						font-size: 16px;
					}
				}
			}
		}
    }
}

/*
看護師募集要項・試験日程　共通
-------------------------------------*/
#PageInformation {
	#MainImg{
		.titleTypeBasic{
			&::before{
				display: none;
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageInformation {
		#MainImg{
			background-image: url("../img/contents/information/mainimage.jpg");
			#MainImgInner{
				top: 62%;
				.titleTypeBasic{
					&::after{
						top: 18%;
						left: 49%;
					}
				}
			}
		}
	}
}
@media print,screen and (max-width: 767px) {
    #PageInformation {
		#MainImg{
			background-image: url("../img/contents/information/mainimage_sp.jpg");
		}
	}
}

/*
看護師募集要項・試験日程
-------------------------------------*/
#PageInformation.pageIndex {
	#Main{
		#ContBox01{
			.stripeBox{
				.listTypeButton{
					&.listTypeButton01{
						padding-bottom: 0;
					}
					&.listTypeButton02{
						padding: 0;
					}
					&.listTypeButton03{
						padding: 0;
					}
					&.listTypeButton04{
						padding-top: 0;
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pageIndex {
		#Main{
			#ContBox01{
				.stripeBox{
					padding: 0;
					margin-top: 150px;
					.listTypeButton{
						&.listTypeButton02{
							li{
								margin-top: 0;
								width: 235px;
								&:nth-of-type(2n){
									margin-right: 20px;
								}
								&:nth-of-type(2n+1){
									clear: none;
								}
								&:nth-of-type(4n){
									margin-right: 0;
								}
								a{
									font-size: 16px;
									span{
										padding-left: 58px;
										min-height: 60px;
										&> b{
											display: inline;
											min-height: auto;
											font-size: 14px;
										}
									}
								}
							}
						}
						&.listTypeButton03{
							li{
								width: 100%;
								a{
									font-size: 16px;
									&::before{
										left: 50%;
										margin-left: -58px;
									}
									span{
										justify-content: center;
										padding-left: 35px;
										min-height: 60px;
									}
								}
							}
						}
						&.listTypeButton04{
							li{
								a{
									&::after{
										top: auto;
										bottom: 25px;
										transform: translateY(0);
									}
									.textBox{
										padding: 15px 0;
										text-align: center;
									}
								}
							}
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pageIndex {
		#Main{
			#ContBox01{
				.stripeBox{
					margin-top: 53px;
					.listTypeButton{
						&.listTypeButton01{
							margin-bottom: 15px;
							li{
								a{
									&::before{
										//display: none;
									}
									span{
										justify-content: center;
										//padding-left: 25px;
										text-align: center;
										font-size: 20px;
										b{
											position: relative;
											display: inline-block;
											&::before{
												content: "";
												position: absolute;
												top: 50%;
												display: block;
												background-repeat: no-repeat;
												background-position: center center;
												transform: translateY(-50%);
											}
										}
									}
								}
							}
						}
						&.listTypeButton02{
							display: flex;
							flex-wrap: wrap;
							&::after{
								content: "";
								display: block;
								clear: both;
							}
							li{
								margin-top: 0;
								margin-bottom: 15px;
								width: calc((100% - 15px)/2);
								&:nth-of-type(2n){
									//float: right;
								}
								&:nth-of-type(2n+1){
									//float: left;
									//clear: none;
									margin-right: 15px;
								}
								&:nth-of-type(4n){
									margin-right: 0;
								}
								a{
									padding: 10px 0 10px;
									min-height: 95px;
									font-size: 16px;
									&::before{
										position: static;
										display: block;
										transform: translateY(0);
										margin: 0 auto 6px;
									}
									span{
										display: block;
										padding-left: 0;
										min-height: auto;
										line-height: 20px;
										text-align: center;
										&> b{
											display: block;
											min-height: auto;
											font-size: 14px;
										}
									}
								}
								&.icon_05{
									a{
										padding-top: 27px;
									}
								}
								&.icon_06{
									a{
										padding-top: 14px;
									}
								}
								&.icon_07{
									a{
										padding-top: 27px;
									}
								}
								&.icon_08{
									a{
										padding-top: 14px;
									}
								}
							}
						}
						&.listTypeButton03{
							margin-bottom: 15px;
							li{
								a{
									span{
										min-height: 70px;
									}
								}
							}
						}
						&.listTypeButton04{
							li{
								a{
									&::after{
										top: auto;
										bottom: 25px;
										transform: translateY(0);
									}
									.textBox{
										padding: 15px 0;
										text-align: center;
										font-size: 20px;
									}
								}
							}
						}
					}
				}
			}
		}
    }
}

/*
看護師募集要項・試験日程
-------------------------------------*/
#PageInformation.pageExam {
	#Main{
		#ContBox01{
			padding-bottom: 0;
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pageExam {
		#Main{
			#ContBox02{
				.tableTypeBasic{
					tbody{
						tr{
							td{
								.tableTypeBasic{
									tbody{
										tr{
											th{
												width: 185px;
											}
										}
									}
								}
							}
							th{
								width: 200px;
							}
						}
					}
				}
			}
			#ContBox03{
				.borderBox{
					padding-top: 29px;
					padding-bottom: 36px;
					.titleTypeBasic{
						line-height: 38px;
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pageExam {
		#Main{
			#ContBox02{
				.titleTypePoint{
					margin-bottom: 43px;
				}
				.tableTypeBasic{
					td{
						line-height: 24px;
					}
					&.tableTypeOverlap{
						tbody{
							tr{
								td{
									.tableTypeBasic{
										tbody{
											tr{
												th{
													width: 85px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox03{
				.borderBox{
					margin-bottom: 45px;
				}
			}
		}
	}
}

/*
看護師正職員募集要項
-------------------------------------*/
#PageInformation.pageRegular {
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pageRegular {
		#Main{
			#ContBox01{
				padding-bottom: 40px;
			}
			#ContBox02{
				.txtTypeRight{
					margin-top: -30px;
				}
				.tableTypeBasic.tableTypeSubtitle{
					tbody{
						td{
							text-align: center;
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pageRegular {
		#Main{
			#ContBox01{
				padding-bottom: 40px;
			}
			#ContBox02{
				.tableTypeBasic{
					&.tableTypeSubtitle{
						tbody{
							tr{
								td{
									&:nth-of-type(2), &:nth-of-type(3){
										min-height: 66px;
										&::before{
											min-height: 65px;
										}
										.inner{
											min-height: 66px;
										}
									}
								}
							}
						}
					}
				}
				.txtTypeRight{
					margin-top: -30px;
				}
			}
		}
    }
}

/*
看護師正職員（日勤のみ）募集要項
看護師夜勤専従募集要項
看護師臨時職員（パート職員）
-------------------------------------*/
#PageInformation.pageDay-shift,
#PageInformation.pageNight-shift,
#PageInformation.pagePart {
	#Main{
		#ContBox02{
			margin-top: 0;
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pageDay-shift,
	#PageInformation.pageNight-shift,
	#PageInformation.pagePart {
		#Main{
			#ContBox01{
				padding-bottom: 40px;
			}
			#ContBox02{
				padding-bottom: 120px;
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pageDay-shift,
	#PageInformation.pageNight-shift,
	#PageInformation.pagePart {
		#Main{
			#ContBox01{
				padding-bottom: 40px;
			}
			#ContBox02{
				padding-bottom: 120px;
			}
		}
    }
}

/*
看護師正職員（日勤のみ）募集要項
-------------------------------------*/
#PageInformation.pageDay-shift {
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pageDay-shift {

    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pageDay-shift {
    
    }
}

/*
看護師夜勤専従募集要項
-------------------------------------*/
#PageInformation.pageNight-shift {
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pageNight-shift {

    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pageNight-shift {
    
    }
}

/*
看護師臨時職員（パート職員）
-------------------------------------*/
#PageInformation.pagePart {
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pagePart {

    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pagePart {
    
    }
}

/*
旭中央病院インターンシップ
-------------------------------------*/
#PageInformation.pageInternship {
	#Main{
		#ContBox01{
			.listTypecheck{
				li{
					dl{
						dd{
							margin-bottom: 0;
							border-radius: 5px;
							padding-right: 0;
							padding-left: 0;
							width: 98px;
							height: 26px;
							text-align: center;
							font-weight: bold;
							font-size: 16px;
							&::after{
								display: none;
							}
							&::before{
								right: auto;
								display: inline-block;
								border: none;
								background-repeat: no-repeat;
								background-position: left center;
								background-image: url("../img/contents/arrow_06_y.png");
								background-size: 27px;
							}
						}
					}
				}
			}
		}
		#ContBox03{
			.stripeBox{
				.wrapBox{
					background-color: #fff;
					&:last-of-type{
						margin-bottom: 0;
					}
					.textBox{
						.titleTypeBasic{
							position: relative;
							display: flex;
							flex-direction: column;
							justify-content: center;
							vertical-align: middle;
							margin-bottom: 0;
							text-align: left;
							font-size: 28px;
							b{
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								display: block;
								border-radius: 50%;
								padding-top: 12px;
								width: 67px;
								height: 67px;
								background-color: #ffeff6;
								line-height: 1;
								text-align: center;
								font-size: 12px;
								color: #ff7bae;
								span{
									display: block;
									margin-top: 2px;
									font-family: 'Lato', sans-serif;
									font-weight: 700;
									font-size: 28px;
								}
							}
						}
					}
					.tableTypeBasic{
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pageInternship {
		#Main{
			#ContBox01{
				.titleTypeBasic[data-subtitle]{
					margin-bottom: -1px;
					&+ p{
						margin-bottom: 43px;
						&+ .titleTypeBasic{
							margin-bottom: 23px;
							line-height: 40px;
							font-size: 24px;
						}
					}
				}
				.listTypecheck{
					margin: 10px auto 10px;
					width: 628px;
					li{
						padding-left: 33px;
						margin-bottom: 13px;
						&::before{
							top: 2px;
							left: -6px;
						}
						dl{
							dt, dd{
								display: inline-block;
								vertical-align: middle;
							}
							dt{
								width: 490px;
								font-size: 18px;
							}
							dd{
								&::before{
									left: -43px;
									width: 45px;
									height: 26px;
								}
							}
						}
					}
				}
			}
			#ContBox02{
				margin-top: -134px;
				.innerBasic{
					padding-bottom: 150px;
				}
				.tableTypeStripe{
					tbody{
						tr{
							th{
								//width: 200px;
							}
						}
					}
				}
			}
			#ContBox03{
				.stripeBox{
					margin-top: -28px;
					margin-bottom: 40px;
					border-radius: 8px;
					padding: 20px;
					.wrapBox{
						margin-bottom: 10px;
						border-radius: 8px;
						padding: 20px;
						&:last-of-type{
							.imgBox{
								margin-bottom: 20px;
							}
						}
						.textBox{
							width: 720px;
							.titleTypeBasic{
								padding-left: 83px;
								min-height: 120px;
								font-size: 28px;
								b{
									left: -6px;
								}
							}
						}
						.imgBox{
							width: 180px;
						}
						.tableTypeBasic{
							thead{
								th{
									&:first-of-type{
										width: 356px;
									}
								}
							}
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pageInternship {
		#Main{
			#ContBox01{
				.titleTypeBasic[data-subtitle]{
					margin-bottom: 14px;
					&+ p{
						margin-bottom: 29px;
						&+ .titleTypeBasic{
							margin-bottom: 22px;
							line-height: 28px;
							font-size: 18px;
						}
					}
				}
				.listTypecheck{
					margin: 0 auto 10px;
					li{
						padding-left: 40px;
						margin-bottom: 11px;
						&::before{
							top: 2px;
							left: 0;
						}
						dl{
							text-align: right;
							dt, dd{
								vertical-align: middle;
							}
							dt{
								line-height: 28px;
								text-align: left;
								font-size: 18px;
							}
							dd{
								display: inline-block;
								&::before{
									left: -33px;
									width: 45px;
									height: 26px;
								}
							}
						}
					}
				}
			}
			#ContBox02{
				margin-top: -64px;
				.innerBasic{
					padding-bottom: 32px;
				}
				.tableTypeStripe{
					tbody{
						tr{
							th{
								//width: 200px;
							}
						}
					}
				}
			}
			#ContBox03{
				.stripeBox{
					margin-top: -11px;
					margin-bottom: 40px;
					border-radius: 6px;
					padding: 10px;
					.wrapBox{
						margin-bottom: 5px;
						padding: 15px;
						border-radius: 6px;
						&:last-of-type{
							.imgBox{
								margin-bottom: 30px;
							}
						}
						.textBox{
							margin-bottom: 10px;
							.titleTypeBasic{
								padding-left: 75px;
								min-height: 67px;
								line-height: 28px;
								font-size: 18px;
								b{
									left: 0;
								}
							}
						}
						.tableTypeBasic{
							thead{
								th{
									&:first-of-type{
										width: 150px;
									}
								}
							}
						}
					}
				}
			}
		}
    }
}

/*
看護師募集についてのよくある質問
-------------------------------------*/
#PageInformation.pageFaq {
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pageFaq {
		#Main{
			#ContBox02{
				margin-top: -295px;
				.innerBasic{
					padding-bottom: 120px;
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pageFaq {
		#Main{
			#ContBox02{
				margin-top: -121px;
				.innerBasic{
					padding-bottom: 120px;
				}
			}
		}
    }
}

/*
福利厚生
-------------------------------------*/
#PageInformation.pageWelfare {
	#Main{
		#ContBox02, #ContBox03, #ContBox04, #ContBox05{
			.fillBox{
				.tableTypeBasic{
					border-top: none;
					border-bottom: none;
					tbody{
						tr{
							th, td{
								background-color: transparent;
								font-size: 16px;
							}
							th{
								padding-left: 0;
							}
							td{
								.listFigure{
									margin-bottom: 0;
									li{
										text-align: center;
										img{
											margin-bottom: 2px;
										}
									}
								}
							}
							&:last-of-type{
								th, td{
									border-bottom: none;
								}
							}
						}
					}
				}
			}
			.wrapBox{
				&.layoutTypeSideDeco{
					.textBox{
						.titleTypeSinple{
							margin-bottom: 0;
							font-size: 16px;
							color: #f54992;
						}
					}
				}
			}
			.listFigure{
				&::after {
					content: "";
					display: block;
					clear: both;
				}
				li{
					display: block;
					float: left;
					&:nth-of-type(3n){
						margin-right: 0;
					}
					img{
						width: 100%;
						height: auto;
					}
				}
			}
			.tableTypeBasic{
				margin-bottom: 0;
				tbody{
					tr{
						th, td{
							font-size: 14px;
						}
						th{
							vertical-align: top;
						}
					}
				}
			}
		}
		#ContBox04{
			.innerBasic{
				&.innerBasic01{
					padding-bottom: 41px;
				}
				&.innerBasic02{
					padding-top: 0;
					.wrapBox{
						.textBox{
							.titleTypeBorder{
								margin-top: 0;
								span{
									display: block;
								}
							}
						}
					}
				}
			}
		}
		#ContBox05{
			.listTypeButton{
				li{
					a{
						background-repeat: no-repeat;
						background-position: center center;
						background-image: url("../img/contents/information/welfare/logo_01.jpg");
						span{
							text-indent: -9999px;
						}
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pageWelfare {
		#Main{
			#ContBox01{
				padding-top: 164px;
				padding-bottom: 252px;
				background-repeat: no-repeat;
				background-position: center 106px;
				background-image: url("../img/contents/information/welfare/wall_01.png");
				background-size: 1380px auto;
				&::after{
					margin-left: -142px;
				}
			}
			#ContBox02, #ContBox03, #ContBox04, #ContBox05{
				.fillBox{
					margin-bottom: 148px;
					padding-top: 22px;
					padding-bottom: 22px;
					.listTypeTable{
						margin: 0 auto;
						width: 1000px;
					}
					.tableTypeBasic{
						margin: 0 auto;
						width: 1000px;
						tbody{
							tr{
								th, td{
									padding-top: 22px;
									padding-right: 0;
									padding-bottom: 22px;
									line-height: 32px;
								}
								th{
									width: 162px;
									padding-left: 0;
								}
								td{
									.listFigure{
										margin-top: -11px;
										margin-bottom: 0;
										li{
											margin-right: 38px;
											width: 248px;
											font-size: 14px;
											&:nth-of-type(3n){
												margin-right: 0;
											}
											&:nth-of-type(3n+1){
												clear: both;
											}
										}
									}
								}
							}
						}
					}
				}
				.wrapBox{
					&.layoutTypeSideDeco{
						.imgWrapBox{
							&::before{
								top: -120px;
								height: calc(100% + 300px);
							}
						}
						.textBox{
							margin-top: -114px;
							.titleTypeSinple{
								margin-top: 27px;
								line-height: 32px;
							}
						}
					}
				}
				.innerBasic{
					padding-top: 28px;
					width: 760px;
				}
				.listFigure{
					margin-bottom: 61px;
					li{
						margin-right: 35px;
						width: 230px;
					}
				}
				.tableTypeBasic{
					margin-bottom: 0;
					tbody{
						tr{
							th, td{
								padding-top: 16px;
								padding-bottom: 16px;
							}
							th{
								letter-spacing: .15em;
								width: 135px;
							}
						}
					}
				}
			}
			#ContBox04{
				.innerBasic{
					&.innerBasic01{
						padding-bottom: 90px;
					}
					&.innerBasic02{
						padding-top: 0;
						width: 1000px;
						.wrapBox{
							.textBox{
								float: left;
								margin-right: 50px;
								width: 300px;
								display: flex;
								flex-wrap: nowrap;
								flex-direction: column;
								&:nth-of-type(3n){
									margin-right: 0;
								}
								.titleTypeBorder{
									order: 1;
									margin-bottom: 28px;
									padding-top: 8px;
									padding-bottom: 3px;
									font-size: 24px;
									span{
										font-size: 16px;
									}
								}
								.imgWrapBox{
									order: 2;
									margin-bottom: 41px;
									width: 290px;
									&::after{
										left: 11px;
									}
								}
								.tableTypeBasic{
									order: 3;
									tbody{
										tr{
											th{
												width: 105px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox05{
				padding-bottom: 165px;
				.wrapBox{
					.titleTypeBorder{
						margin: 0 auto 0;
						width: 1000px;
					}
					&.layoutTypeSideDeco{
						.imgBox{
							float: none;
							display: block;
							margin: 0 auto;
							padding-bottom: 53px;
							min-width: 1200px;
							max-width: 1380px;
							width: 100%;
						}
						.imgWrapBox{
							&::before{
								top: -168px;
								width: calc(50vw - 87px);
								height: calc(100% + 168px + 121px);
							}
						}
						.textBox{
							float: none;
							margin: 0 auto 0;
							padding-top: 0;
							width: 760px;
						}
					}
				}
				.listTypeButton{
					margin-top: 51px;
					li{
						float: none;
						margin: 0 auto;
						width: 520px;
						a{
							background-size: 320px auto;
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pageWelfare {
		#Main{
			#ContBox01{
				padding-bottom: 118px;
				.titleTypeBasic{
					&+ p{
						&::after{
							content: "";
							display: block;
							margin: 6px auto 0;
							width: 291px;
							height: 141px;
							background-repeat: no-repeat;
							background-position: center center;
							background-size: 291px auto;
							background-image: url("../img/contents/information/welfare/wall_01_sp.png")
						}
					}
				}
			}
			#ContBox02, #ContBox03, #ContBox04, #ContBox05{
				.titleTypePoint{
					margin-bottom: 20px;
				}
				.fillBox{
					margin-bottom: 62px;
					line-height: 28px;
					font-size: 16px;
					.listTypeTable{
					}
					.tableTypeBasic{
						tbody{
							tr{
								th, td{
									line-height: 28px;
								}
								th{
									padding: 14px 0 0;
									width: 100%;
								}
								td{
									padding: 0 0 13px;
									.listFigure{
										margin-top: -23px;
										margin-bottom: 0;
										text-align: center;
										font-size: 0;
										li{
											float: none;
											display: inline-block;
											margin-bottom: 10px;
											vertical-align: top;
											width: calc((100% - 16px) / 2);
											line-height: 18px;
											font-size: 12px;
											&:nth-of-type(2n+1){
												margin-right: 7.5px;
											}
											&:nth-of-type(2n){
												margin-right: 0;
												margin-left: 7.5px;
											}
											img{
												margin-bottom: 8px;
											}
										}
									}
								}
							}
						}
					}
				}
				.wrapBox{
					&.layoutTypeSideDeco{
						.imgBox{
							margin-bottom: 30px;
						}
						.imgWrapBox{
							&::before{
							}
						}
						.textBox{
							.titleTypeBorder{
								margin-top: 0;
								margin-bottom: 20px;
							}
							.titleTypeSinple{
								margin-top: 27px;
								margin-bottom: -2px;
								line-height: 32px;
							}
						}
					}
				}
				.innerBasic{
					padding-bottom: 75px;
				}
				.listFigure{
					margin-bottom: 29px;
					li{
						margin-right: 7px;
						width: calc((100% - 15px) / 3);
					}
				}
				.tableTypeBasic{
					margin-bottom: 0;
					tbody{
						tr{
							th, td{
								padding-top: 15px;
								padding-bottom: 15px;
								line-height: 24px;
							}
							th{
								padding-right: 0;
								padding-left: 10px;
								letter-spacing: .15em;
								width: 75px;
							}
						}
					}
				}
			}
			#ContBox02{
				.fillBox{
					padding: 17px 8px 17px;
				}
			}
			#ContBox03{
				.fillBox{
					padding: 10px 20px;
				}
			}
			#ContBox04{
				.innerBasic{
					&.innerBasic01{
						padding-bottom: 45px;
					}
					&.innerBasic02{
						padding-top: 0;
						.wrapBox{
							.textBox{
								margin-bottom: 45px;
								&::after {
									content: "";
									display: block;
									clear: both;
								}
								&:last-of-type{
									margin-bottom: 0;
								}
								.titleTypeBorder{
									float: right;
									margin-top: 5px;
									margin-bottom: 19px;
									padding-top: 8px;
									padding-bottom: 8px;
									width: calc(100% - 164px);
									line-height: 24px;
									font-size: 18px;
									span{
										font-size: 14px;
									}
								}
								.imgWrapBox{
									float: left;
									margin-bottom: 19px;
									width: 145px;
									&::after{
										top: 5px;
										left: 5px;
									}
								}
								.tableTypeBasic{
									tbody{
										tr{
											th{
												width: 75px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#ContBox05{
				padding-bottom: 100px;
				.fillBox{
					padding: 10px 20px;
					margin-bottom: 40px;
				}
				.wrapBox{
					.titleTypeBorder{
						margin-bottom: 13px;
					}
					&.layoutTypeSideDeco{
						.imgBox{
							margin-bottom: 24px;
						}
					}
				}
				.listTypeButton{
					margin-top: -6px;
					li{
						a{
							background-size: 242px auto;
						}
					}
				}
			}
		}
    }
}

/*
奨学金制度
-------------------------------------*/
#PageInformation.pageScholarship {
	#Main{
		#ContBox01{
			padding-bottom: 0;
			.overviewImg{
				margin-bottom: 0;
				.wrapBox{
					.textBox{
						position: relative;
						left: 0;
						background-color: #fff;
						transform: translateY(0);
						width: 100%;
						p{
							text-align: left;
							letter-spacing: .05em;
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageInformation.pageScholarship {
		#Main{
			#ContBox01{
				.overviewImg{
					padding-bottom: 30px;
					.wrapBox{
						.textBox{
							top: -60px;
							padding: 52px 57px 81px;
							.titleTypePoint{
								margin-bottom: 51px;
								font-size: 24px;
								&::after{
									margin-top: 14px;
								}
							}
							p{
								line-height: 32px;
							}
							.listTypeNumber, .listTypeCircle{
								margin-bottom: 32px;
								li{
									margin-bottom: 8px;
								}
							}
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageInformation.pageScholarship {
		#Main{
			#ContBox01{
				.overviewImg{
					.wrapBox{
						.textBox{
							top: -30px;
							padding: 23px 19px 18px;
							.titleTypePoint{
								margin-bottom: 24px;
								line-height: 28px;
								font-size: 18px;
								&::after{
									margin-top: 9px;
								}
							}
							p{
							}
							.listTypeNumber, .listTypeCircle{
								margin-bottom: 32px;
								li{
									margin-bottom: 16px;
								}
								&+ p{
									margin-top: -17px;
								}
							}
						}
					}
				}
			}
		}
    }
}

/*
看護局だより　共通
-------------------------------------*/
#PageBlog {
}
@media print,screen and (min-width: 768px) {
    #PageBlog {
		#MainImg{
			background-image: url("../img/contents/blog/mainimage.jpg");
			.innerBasic{
				transform: translate(-50%, 66%);
			}
			.titleTypeBasic{
				&::before{
					width: 636px;
					height: 323px;
					background-image: url("../img/contents/blog/mainimage_wall.png");
					background-size: 636px auto;
					transform: translate(-50%, -55%);
				}
				&::after{
					transform: translate(-51%, -56%) rotate(-10deg);
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageBlog {
		#MainImg{
			background-image: url("../img/contents/blog/mainimage_sp.jpg");
			.innerBasic{
				transform: translate(-50%, -52%);
			}
			.titleTypeBasic{
				&::before{
					width: 318px;
					height: 176px;
					background-image: url("../img/contents/blog/mainimage_wall_sp.png");
					background-size: 318px auto;
					transform: translate(-50%, -40%);
				}
				&::after{
					transform: translate(-53%, -55%) rotate(-10deg);
				}
			}
		}
    }
}

/*
看護局だより
-------------------------------------*/
#PageBlog.pageIndex {
}
@media print,screen and (min-width: 768px) {
    #PageBlog.pageIndex {
		#Main{
			#ContBox01{
				padding-top: 90px;
				padding-bottom: 150px;
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageBlog.pageIndex {
    
    }
}

/*
看護局だより（カテゴリアーカイブ）
-------------------------------------*/
#PageBlog.pageCategory {
}
@media print,screen and (min-width: 768px) {
    #PageBlog.pageCategory {
		#Main{
			#ContBox01{
				padding-top: 132px;
				padding-bottom: 150px;
				.titleTypeBasic{
					margin-bottom: 51px;
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageBlog.pageCategory {
    
    }
}

/*
記事詳細共通
-------------------------------------*/
#PageBlog.pageEntry, #PageRecruit.pageEntry {
	#Main{
		#ContBox01{
			.date{
				display: block;
			}
			.titleTypeBasic{
				text-align: left;
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
	#PageBlog.pageEntry, #PageRecruit.pageEntry {
		#Main{
			#ContBox01{
				margin-top: 90px;
				.innerBasic{
					padding-top: 5px;
					width: 760px;
				}
				.date{
					margin-bottom: 13px;
				}
				.titleTypeBasic{
					margin-bottom: 49px;
					font-size: 28px;
				}
				.entryBox{
					padding: 56px 0 30px;
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
	#PageBlog.pageEntry, #PageRecruit.pageEntry {
		#Main{
			#ContBox01{
				margin-top: 90px;
				padding-bottom: 75px;
				.innerBasic{
					padding: 0 15px 10px;
				}
				.date{
					margin-bottom: -2px;
				}
				.titleTypeBasic{
					margin-bottom: 38px;
					line-height: 30px;
					font-size: 20px;
				}
				.entryBox{
					padding: 38px 0 30px;
				}
			}
		}
    }
}

/*
看護局だより（記事詳細）
-------------------------------------*/
#PageBlog.pageEntry {
}
@media print,screen and (min-width: 768px) {
    #PageBlog.pageEntry {

    }
}
@media print,screen and (max-width: 767px) {
    #PageBlog.pageEntry {
    
    }
}

/*
お問い合わせ
-------------------------------------*/
#PageContact.pageIndex {
}
@media print,screen and (min-width: 768px) {
    #PageContact.pageIndex {
		#MainImg{
			background-image: url("../img/contents/contact/mainimage.jpg");
			.innerBasic{
				transform: translate(-50%, 66%);
			}
			.titleTypeBasic{
				&::before{
					width: 636px;
					height: 323px;
					background-image: url("../img/contents/contact/mainimage_wall.png");
					background-size: 636px auto;
					transform: translate(-50%, -43%);
				}
				&::after{
					transform: translate(-51%, -56%) rotate(-10deg);
				}
			}
		}
		#Main{
			#ContBox01{
				padding-top: 50px;
				padding-bottom: 0;
			}
			#ContBox02{
				margin-top: 0;
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageContact.pageIndex {
		#MainImg{
			background-image: url("../img/contents/contact/mainimage_sp.jpg");
			.innerBasic{
				transform: translate(-50%, -52%);
			}
			.titleTypeBasic{
				&::before{
					width: 318px;
					height: 176px;
					background-image: url("../img/contents/contact/mainimage_wall_sp.png");
					background-size: 318px auto;
					transform: translate(-50%, -45%);
				}
				&::after{
					transform: translate(-53%, -55%) rotate(-10deg);
				}
			}
		}
		#Main{
			#ContBox01{
				padding-top: 50px;
				padding-bottom: 0;
			}
		}
    }
}

/*
プライバシーポリシー
-------------------------------------*/
#PagePolicy.pageIndex {
}
@media print,screen and (min-width: 768px) {
    #PagePolicy.pageIndex {
		#MainImg{
			background-image: url("../img/contents/policy/mainimage.jpg");
			.innerBasic{
				transform: translate(-50%, 66%);
			}
			.titleTypeBasic{
				&::before{
					width: 636px;
					height: 323px;
					background-image: url("../img/contents/policy/mainimage_wall.png");
					background-size: 636px auto;
					transform: translate(-50%, -43%);
				}
				&::after{
					transform: translate(-51%, -56%) rotate(-10deg);
				}
			}
		}
		#Main{
			#ContBox01{
				padding-top: 40px;
			}
			#ContBox02{
				ol{
					margin-bottom: 15px;
					line-height: 32px;
					font-size: 16px;
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PagePolicy.pageIndex {
		#MainImg{
			background-image: url("../img/contents/policy/mainimage_sp.jpg");
			.innerBasic{
				transform: translate(-50%, -52%);
			}
			.titleTypeBasic{
				&::before{
					width: 318px;
					height: 176px;
					background-image: url("../img/contents/policy/mainimage_wall_sp.png");
					background-size: 318px auto;
					transform: translate(-50%, -45%);
				}
				&::after{
					transform: translate(-53%, -55%) rotate(-10deg);
				}
			}
		}
		#Main{
			#ContBox01{
				padding-top: 40px;
			}
			#ContBox02{
				ol{
					margin-bottom: 15px;
					line-height: 28px;
					font-size: 16px;
				}
			}
		}
    }
}

/*
サイトマップ
-------------------------------------*/
#PageSitemap.pageIndex {
	#MainImg{
		.titleTypeBasic{
			&::before{
				display: none;
			}
		}
	}
	#Main{
		#ContBox01{
			.listTypeButton{
			&> li{
				.borderBox{
					color: #f54992;
					&::after{
						border-radius: 0;
						width: 7px;
						height: 11px;
						background-image: url("../img/contents/arrow_02.png");
						background-size: 7px auto;
						background-color: transparent;
					}
				}
				&+ li{
					.borderBox{
						border-top: none;
					}
				}
				&+ li.has_dl{
					.borderBox{
						border-top-color: #f6f6f6;
						border-top-style: solid;
					}
				}
				&.has_dl{
					dl{
						dd{
							&.has_b{
								b{
									display: block;
									border-bottom: 1px solid #dfdfdf;
									&.has_a{
										padding: 0;
										a{
											border-bottom: none;
											&::after{
												border-radius: 0;
												width: 7px;
												height: 11px;
												background-image: url("../img/contents/arrow_02.png");
												background-size: 7px auto;
												background-color: transparent;
											}
										}
									}
								}
								ul{
									border-bottom: 1px solid #dfdfdf;
									li{
										border-bottom: none;
										&:last-of-type{
											margin-bottom: 0;
										}
										a{
											line-height: 24px;
											font-size: 14px;
											&::after{
												right: 0;
												left: 0;
												border-radius: 0;
												width: 14px;
												height: 1px;
												background-image: none;
												background-color: #ff92c0;
											}
										}
									}
								}
							}
						}
					}
					&+ li{
						.borderBox{
							position: relative;
							z-index: 1;
							margin-top: -1px;
							border-top-color: #f6f6f6;
							border-top-style: solid;
						}
					}
				}
			}
		}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageSitemap.pageIndex {
		#MainImg{
			background-image: url("../img/contents/sitemap/mainimage.jpg");
			.innerBasic{
				transform: translate(-50%, 66%);
			}
			.titleTypeBasic{
				&::after{
					transform: translate(-51%, -56%) rotate(-10deg);
				}
			}
		}
		#Main{
			#ContBox01{
				padding-top: 100px;
				.listTypeButton{
					float: left;
					margin-right: 50px;
					width: 300px;
					&:last-of-type{
						margin-right: 0;
					}
					&> li{
						float: none;
						margin-top: 0;
						width: 300px;
						.borderBox{
							margin-bottom: 0;
							font-size: 20px;
							span{
								padding-left: 13px;
								min-height: 70px;
								line-height: 32px;
							}
							&::after{
								right: 16px;
							}
							&[href*="/internship.html"]{
								span{
									min-height: 100px;
								}
							}
						}
						&+ li.has_dl{
							margin-top: 50px !important;
							.borderBox{
								border-top-width: 6px;
							}
						}
						&.has_dl{
							dl{
								dd{
									&.has_b{
										b{
											padding: 15px 40px 15px 20px;
											line-height: 24px;
											&.has_a{
												a{
													padding: 17px 40px 17px 20px;
													font-size: 16px;
													&::after{
														right: 22px;
													}
												}
											}
										}
										ul{
											padding: 17px 0 17px 22px;
											li{
												margin-bottom: 5px;
												a{
													padding: 0 0 0 29px;
												}
											}
										}
									}
								}
							}
							&+ li{
								.borderBox{
									border-top-width: 6px;
								}
							}
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageSitemap.pageIndex {
		#MainImg{
			background-image: url("../img/contents/sitemap/mainimage_sp.jpg");
			.innerBasic{
				transform: translate(-50%, -52%);
			}
			.titleTypeBasic{
				&::after{
					transform: translate(-53%, -55%) rotate(-10deg);
				}
			}
		}
		#Main{
			#ContBox01{
				.innerBasic{
					padding-bottom: 50px;
				}
				.listTypeButton{
					margin-bottom: 30px;
					&> li{
						margin-top: 0;
						.borderBox{
							font-size: 20px;
							span{
								padding-left: 19px;
								min-height: 61px;
								line-height: 32px;
								font-size: 18px;
							}
							&::after{
								right: 16px;
							}
							&[href*="/internship.html"]{
								span{
									min-height: 90px;
								}
							}
						}
						&+ li.has_dl{
							margin-top: 28px !important;
							.borderBox{
								border-top-width: 4px;
							}
						}
						&.has_dl{
							dl{
								dd{
									&.has_b{
										b{
											padding: 11px 20px 11px 20px;
											line-height: 24px;
											&.has_a{
												a{
													padding: 11px 40px 11px 20px;
													font-size: 14px;
													&::after{
														right: 22px;
													}
												}
											}
										}
										ul{
											padding: 14px 0 16px 22px;
											li{
												margin-bottom: 5px;
												a{
													padding: 0 0 0 29px;
												}
											}
										}
									}
								}
							}
							&+ li{
								.borderBox{
									border-top-width: 4px;
								}
							}
						}
					}
				}
			}
		}
    }
}

/*
採用情報　共通
-------------------------------------*/
#PageRecruit {
}
@media print,screen and (min-width: 768px) {
    #PageRecruit {
		#MainImg{
			background-image: url("../img/contents/recruit/mainimage.jpg");
			.innerBasic{
				transform: translate(-50%, 66%);
			}
			.titleTypeBasic{
				&::before{
					width: 636px;
					height: 323px;
					background-image: url("../img/contents/recruit/mainimage_wall.png");
					background-size: 636px auto;
					transform: translate(-50%, -55%);
				}
				&::after{
					transform: translate(-51%, -56%) rotate(-10deg);
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageRecruit {
		#MainImg{
			background-image: url("../img/contents/recruit/mainimage_sp.jpg");
			.innerBasic{
				transform: translate(-50%, -52%);
			}
			.titleTypeBasic{
				&::before{
					width: 318px;
					height: 176px;
					background-image: url("../img/contents/recruit/mainimage_wall_sp.png");
					background-size: 318px auto;
					transform: translate(-50%, -45%);
				}
				&::after{
					transform: translate(-53%, -55%) rotate(-10deg);
				}
			}
		}
    }
}

/*
採用情報
-------------------------------------*/
#PageRecruit.pageIndex {
}
@media print,screen and (min-width: 768px) {
    #PageRecruit.pageIndex {
		#Main{
			#ContBox01{
				padding-top: 90px;
				padding-bottom: 150px;
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageRecruit.pageIndex {
    }
}

/*
採用情報（カテゴリアーカイブ）
-------------------------------------*/
#PageRecruit.pageCategory {
}
@media print,screen and (min-width: 768px) {
    #PageRecruit.pageCategory {
		#Main{
			#ContBox01{
				padding-top: 132px;
				padding-bottom: 150px;
				.titleTypeBasic{
					margin-bottom: 51px;
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageRecruit.pageCategory {
    
    }
}

/*
採用情報（記事詳細）
-------------------------------------*/
#PageRecruit.pageEntry {
	#Main{
		#ContBox01{
			.date{
				&.new{
					position: relative;
					display: inline-block;
					&::after{
						position: absolute;
					}
				}
			}
		}
	}
}
@media print,screen and (min-width: 768px) {
    #PageRecruit.pageEntry {
		#Main{
			#ContBox01{
				.date{
					&.new{
						&::after{
							top: -33px;
							right: -53px;
						}
					}
				}
			}
		}
    }
}
@media print,screen and (max-width: 767px) {
    #PageRecruit.pageEntry {
		#Main{
			#ContBox01{
				.date{
					&.new{
						&::after{
							top: -13px;
							right: -44px;
							letter-spacing: .01em;
						}
					}
				}
			}
		}
    }
}

